import Types from "../_actions/_types";

const initialState = {
  faq: {}
};

const faq = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_FAQ_DETAILS:
      return { ...state, faq: payload };

    default:
      return state;
  }
};

export default faq;
