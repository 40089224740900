import React from "react";
import { Button, Col, Input } from "antd";
import searchIcon from "../../../images/searchNew.svg";
const CreatorSearch = ({ setSearchText, searchText }) => {
  return (
    <>
      <Col className="hs-style creator_search">
        <Button className="hs-button-style">
            <img src={searchIcon} alt="icon" />
        </Button>
        <Input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
      </Col>
    </>
  );
};

export default CreatorSearch;
