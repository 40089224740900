import Types from "../_actions/_types";

const initialState = {
  quotes: {},
};

const quotes = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_QUOTES: {
      
      return { ...state, quotes: payload };
    }
    default:
      return state;
  }
};

export default quotes;
