import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import ListRaffles from "./list/ListCategories";
import { CollectionActions, RafflesActions } from "../../../redux/_actions";
//import { updateRafflesStatus } from "../../../redux/_actions/raffles.action";
import GlobalVariables from "../../../_constants/GlobalVariables";
import { apiCallPost } from "../../../components/Axios/Axios";
import { toast } from "react-toastify";

const Categories = () => {
  const dispatch = useDispatch();
  const [raffles, setRaffles] = useState({ count: 0, rows: [] });
  const [rafflesDetail, setRafflesDetail] = useState({});
  const [page, setPage] = useState(1);

  const getraffles = useCallback(
    async (data) => {
      const { getraffles } = RafflesActions;
      const res = await dispatch(getraffles(data));
      if (res) {
        let {
          data: {
            data: { count, rows },
          },
        } = res;
        setRafflesDetail(rows);
        setRaffles({ count, rows });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    getraffles({ page, limit: PAGE_LIMIT });
  }, [getraffles, page]);

  const changeRaffleStatus = (data) => {
    let payload = {
      isActive: data?.action,
      id: data?.data?.rows.raffleId,
    };
    const { updateRafflesStatus } = RafflesActions;
    dispatch(updateRafflesStatus(payload)).then((data) => {
      if (data?.status === 200) {
        const { PAGE_LIMIT } = GlobalVariables;
        getraffles({ page: 1, limit: PAGE_LIMIT });
      }
    });
  };
  const deleteCategory = (data) => {
    var payload = { id: data, isDeleted: 1, isActive: 0 };

    apiCallPost(`/api/v1/admin/category/update`, payload, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          console.log("TAG2", res);
          if (!res.error) {
            const { PAGE_LIMIT } = GlobalVariables;
            getraffles({ page: 1, limit: PAGE_LIMIT });
          }
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <MainLayout>
        <ListRaffles
          getraffles={getraffles}
          raffles={raffles}
          changeRafflesStatus={changeRaffleStatus}
          deleteCategory={deleteCategory}
          setPage={setPage}
          page={page}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(Categories);
