import "./Header.scss";

import { Dropdown, Menu } from "antd";
import { Link, NavLink } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { apiCallGet, apiCallPost } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PersistActions } from "../../redux/_actions";
import darkthemeIcon from "../../Assets/image/darkthemeIcon.svg";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import lightthemeIcon from "../../Assets/image/lightthemeIcon.svg";
import logo from "../../Assets/image/fv_logo_white.png";
import { withRouter } from "react-router";
import power_btn from '../../Assets/image/power-icon-svgrepo-com.svg'

const Header = ({ setToggleSidebar, toggleSidebar }) => {
  const dispatch = useDispatch();
  const logoutUser = () => {
    const { logout } = PersistActions;
    dispatch(logout());
    localStorage.clear();
  };

  const newData = {};
  const logout = () => {
    apiCallPost("api/v1/admin/auth/logout", newData, {}, "toastOn")
      .then(async (res) => {
        if (res.error) {
          logoutUser();
        } else {
          logoutUser();
        }
      })
      .catch((err) => {
        logoutUser();
      });
  };

     
  //Admin Withdraw Status
  // const getWithdrawStatus = () => {
  //   apiCallGet(
  //     `/api/v1/admin/wallet/getAssets/matic/1`,
  //     // {},
  //     {}
  //     // toastOn
  //   )     
  //     .then((results) => {
  //       if (results.error) {
  //         logoutUser()
  //       }
  //     })
  //     .catch((error) => {
  //       logoutUser()
  //     });
  // };

  // useEffect(() => {
  //   getWithdrawStatus();
  // }, []);  


  const menu = (
    <Menu
      className="dropMenu"
      items={[
        {
          key: "1",
          label: (
            <NavLink
              theme="dark"
              style={{ color: "white" }}
              to={"/panel/change_password"}
            >
              Change Password
            </NavLink>
          ),
        },
        {
          key: "2",
          label: (
            <div onClick={logout} style={{ color: "white" }}>
              Logout
            </div>
          ),
        },
      ]}
    />
  );

  localStorage.setItem("themeInfo", "dark");
  const themeInfo = localStorage.getItem("themeInfo");

  useEffect(() => {
    if (themeInfo === "dark") {
      document.body.classList.remove("lightTheme");
      return document.body.classList.add("darkTheme");
    }
    document.body.classList.remove("darkTheme");
    document.body.classList.add("lightTheme");
  }, []);
  async function toggleTheme() {
    const themeInfo = localStorage.getItem("themeInfo");
    if (themeInfo === "dark") {
      document.body.classList.remove("darkTheme");
      document.body.classList.add("lightTheme");
      localStorage.setItem("themeInfo", "light");
    } else {
      document.body.classList.remove("lightTheme");
      document.body.classList.add("darkTheme");
      localStorage.setItem("themeInfo", "dark");
    }
  }

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <React.Fragment>
      <div className={isActive ? "nav_toggled" : null}>
        <div className="headerTop">
          <div className="headerRight">
            <Link to="/nfts" className="headerLogo" title="Dashboard">
              <img src={logo} alt="logo" />
            </Link>
            <ul className="navRight">
              <li className="navR_item navR_logedinOut"></li>

              {/* <li className="colorChangetheme">
                <button onClick={toggleTheme} className={`themeButtonStyle`}>
                  <ThemeIcon />
                  <img
                    className="lightthemeIcon"
                    src={lightthemeIcon}
                    alt="light"
                  />
                  <img
                    className="darkthemeIcon"
                    src={darkthemeIcon}
                    alt="light"
                  />
                </button>
              </li> */}
              <li>
                <div className="DropLogout">
                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    trigger="click"
                  >
                    <button className="LogBtn">
                      {/* <FontAwesomeIcon icon={faPowerOff} /> */}
                      <img src={power_btn} alt="power"/>
                    </button>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Header);
