import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";

import { compose } from "redux";
import { FormField, TextField } from "../../../../components/FormField";
import { Field, reduxForm } from "redux-form";
import { required, numericality, date } from "redux-form-validators";
import { Button, Form, Image, Select, Dropdown } from "semantic-ui-react";
import { toast } from "../../../../components/Toast/Toast";
import { ValidateImage } from "../../../../Services/Validation";
import { NftActions } from "../../../../redux/_actions";
import { getRafflesNftList } from "../../../../redux/_actions/nft.action";

const optionsData = [
  {
    key: "active",
    lable: "active",
    lable: "active",
    value: 1,
  },
  {
    key: "inactive",
    lable: "inactive",
    value: 0,
  },
];

const nftSaleType = [
  { key: "1", value: "FREE", lable: "FREE" },
  { key: "2", value: "SALE", lable: "SALE" },
  { key: "3", value: "AUCTION", lable: "AUCTION" },
  { key: "4", value: "RAFFLE", lable: "RAFFLE" },
];
const CreateNftForm = ({
  collections,
  handleSubmit,
  isEdit,
  setAuctionDate,
  auctionDate,
  creatorsData,
  blockChainData,
  rfValue,
  rafflesId,
}) => {
  console.log("isEdit-->", isEdit);
  const [images, setImages] = useState({ logo: "", banner: "" });
  const [nftType, setNftType] = useState("");
  const [file_type, setFileType] = useState("");
  const [files, setFile] = useState("");
  const [InputKey, setInputKey] = useState();
  const [rfData, setRfData] = useState([]);

  const dispatch = useDispatch();

  const nftValue = useSelector((state) => state.nft?.nftData);
  const fileType = [
    { key: "1", value: "Image", lable: "Image" },
    { key: "2", value: "Video", lable: "Video" },
  ];

  getCurrentDateTime();
  useEffect(() => {
    if (nftValue && Object.keys(nftValue).length > 0) {
      setNftType(nftValue?.nftType);
    }
  }, [nftValue]);
  function getCurrentDateTime() {
    var curr = new Date();
    console.log("DATETIME", Date.now());

    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substr(0, 10);
    console.log("DATETIME", date);
    console.log("DATETIME", curr);
    //setDateTime(curr)
  }
  useEffect(() => {
    setImages({ ...images, logo: "" });
    resetsFileInput();
  }, [file_type]);

  function resetsFileInput() {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  }

  const onImageChange = async (event) => {
    setFile(event.target.files);
    const files = event.target.files;
    const name = event.target.name;
    let height;
    let width;
    let isImageValid = false;
    if (file_type === "") {
      resetsFileInput();
      return toast.error("Please choose your file type");
    }

    if (files.length === 0)
      return toast.error(
        "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
      );

    const file = files[0]; /** get file from files array */

    console.log("file_size------>", file.size);

    let ext = file.name.split(".").pop();
    ext = ext.toLowerCase();
    // let ext = file.name.split(".")[1]; /** get ext of image to validate */
    ext = ext.toLowerCase();
    if (file_type === "Image") {
      if (ext === "jpg" || ext === "gif" || ext === "jpeg" || ext === "png") {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 100) {
          resetsFileInput();
          return toast.error("Image should be less than or equal to 100MB");
        } else {
          const blob = URL.createObjectURL(file);
          if (name === "logo") {
            height = 330;
            width = 330;
            isImageValid = await ValidateImage({
              event: blob,
              wid: width,
              hei: height,
            });
            setImages({ ...images, logo: blob });
            // if (isImageValid) {
            //   setImages({ ...images, logo: blob });
            // } else {
            //   document.getElementById("logo").value = null;
            // }
          } else {
            height = 726;
            width = 1920;
            isImageValid = await ValidateImage({
              event: blob,
              wid: width,
              hei: height,
            });
            setImages({ ...images, banner: blob });
            // if (isImageValid) {
            //   setImages({ ...images, banner: blob });
            // } else {
            //   // document.getElementById("logo").value = null;
            // }
          }
          // if (!isImageValid) {
          //   return toast.error(
          //     `Please add a valid dimension ${name} image of width: ${width}px & Height: ${height}px`
          //   );
          // }
        }
      } else {
        resetsFileInput();
        return toast.error(
          "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
        );
      }
    } else {
      if (ext === "mp4" || ext === "mov") {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 100) {
          resetsFileInput();
          return toast.error("Video should be less than or equal to 100MB");
        } else {
          const blob = URL.createObjectURL(file);
          setImages({ ...images, logo: blob });
        }
      } else {
        resetsFileInput();
        return toast.error("Please upload a valid video format (.mp4, .mov)");
      }
    }
  };

  useEffect(() => {
    nftType === "RAFFLE" && getRaffleList();
  }, [nftType]);

  // const rfData = [];

  const getRaffleList = useCallback(async () => {
    const { getRafflesNftList } = NftActions;
    const res = await dispatch(getRafflesNftList());
    console.log("Raffles_Data--------->", typeof res, res.data.data);
    if (res) {
      let data = res.data.data.map((item) => ({
        key: item.raffleId,
        value: item.raffleId,
        text: item.name,
      }));
      setRfData(data);
    }
  }, [dispatch]);

  useEffect(() => {
    setImages({ logo: nftValue?.logo });
  }, [nftValue]);
  return (
    <Form
      autoComplete="off"
      autoFocus="off"
      onSubmit={(e, a) => {
        handleSubmit();
      }}
    >
      <div>
        <Form.Field>
          <label>Creator</label>
          <Field
            component={FormField}
            name="creator"
            type="selectWithOpt"
            data={creatorsData}
            placeholder="Select Creator"
            validate={[required()]}
            disabled={isEdit}
          />
        </Form.Field>

        {isEdit ? (
          <>
            <div style={{ marginBottom: "5px" }}>
              <b>NFT {nftValue?.fileType}</b>
            </div>

            {nftValue?.fileType === "video" ? (
              ((<br />),
              (
                <video width="80" height="80">
                  <source src={images?.logo} type="video/mp4" />
                </video>
              ))
            ) : (
              <Image src={images?.logo} width="80" height="80" />
            )}
            <br />
            <b>NFT Title</b>
            <p>{nftValue?.name}</p>
            <br />
            <b>Description</b>
            <p>{nftValue?.description}</p>
            <br />
            <b>Collection</b>
            <p>{nftValue?.collectionName}</p>
            <br />
          </>
        ) : (
          <>
            {images?.logo?.length > 0 &&
              (file_type === "Image" ? (
                <Image src={images?.logo} width="80" height="80" />
              ) : (
                <video width="80px" height="80px" autoplay>
                  <source src={images?.logo} type="video/mp4" />
                </video>
              ))}
          </>
        )}
      </div>
      {!isEdit && (
        <>
          <Form.Field>
            <label>File Type</label>
            <Field
              component={FormField}
              name="fileType"
              type="select"
              children={fileType}
              placeholder="Select File Type"
              validate={[required()]}
              onChange={(e, d) => {
                setFileType(d);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Upload NFT</label>
            <Field
              component={FormField}
              id="logo"
              name="logo"
              type="file"
              placeholder="upload a file"
              key={InputKey || ""}
              onImageChange={onImageChange}
              validate={[required()]}
            />
            <small>
              {/* Logo size (width:330px, height: 330px ) */}
              <p className="infoData">
                {" "}
                This NFT media will be saved to blockchain
              </p>
            </small>
          </Form.Field>

          <Form.Field>
            <label>NFT Title</label>
            <Field
              component={FormField}
              name="name"
              type="text"
              placeholder="NFT Title"
              validate={[required()]}
            />
            <small style={{ color: "blue" }}>
              <p className="infoData">
                {" "}
                This NFT title will be saved to blockchain
              </p>
            </small>
          </Form.Field>

          <Form.Field>
            <label>Description</label>
            <Field
              component={FormField}
              type="textarea"
              textarea={true}
              col={2}
              name="description"
              placeholder="Enter Description..."
              validate={[required()]}
            />
            <small style={{ color: "blue" }}>
              <p className="infoData">
                {" "}
                This NFT description will be saved to blockchain
              </p>
            </small>
          </Form.Field>

          <Form.Field>
            <label>Collection</label>
            <Field
              component={FormField}
              name="collectionId"
              type="select"
              children={collections}
              placeholder="Select Collection"
              // validate={[required()]}
            />
          </Form.Field>
        </>
      )}

      <Form.Field>
        <label>NFT Status</label>
        <Field
          component={FormField}
          name="isActive"
          type="select"
          children={optionsData}
          placeholder="Select NFT Status"
          validate={[required()]}
        />
      </Form.Field>

      <Form.Field>
        <label>NFT Sell Type</label>
        <Field
          component={FormField}
          name="nftType"
          type="select"
          children={nftSaleType}
          onChange={(e, d) => {
            setNftType(d);
          }}
          placeholder="Select NFT Sell Type"
          validate={[required()]}
        />
      </Form.Field>

      {nftType === "RAFFLE" && (
        <Form.Field
          component={FormField}
          control={Select}
          options={rfData}
          search
          label={{
            children: "Raffle Nft Type",
            htmlFor: "form-select-control-gender",
          }}
          validate={[required()]}
          searchInput={{
            id: "form-select-control-gender",
          }}
          onChange={(e) => {
            let value = e.target.innerText;
            let index = rfData.findIndex((item) => item.text === value);
            rafflesId(rfData.at(index).value);
          }}
        />
      )}

      {rfValue && (
        <div className="form__field-error-rf">This field is required</div>
      )}
      {(nftType === "AUCTION" || nftType === "SALE") && (
        <Form.Field>
          <label>Base Price</label>
          <Field
            component={FormField}
            name="price"
            type="number"
            placeholder="Base Price"
            validate={[
              required(),
              numericality({
                ">=": 1,
                msg: { ">=": "Price must be at least 1" },
              }),
            ]}
          />
        </Form.Field>
      )}
      {nftType === "AUCTION" && (
        <React.Fragment>
          <Form.Field>
            <label>Bid Price</label>
            <Field
              component={FormField}
              name="minBidCost"
              type="number"
              placeholder="Minimum Bid Price"
              validate={[
                required(),
                numericality({
                  ">=": 1,
                  msg: { ">=": "Bid Price must be at least 1" },
                }),
              ]}
            />
          </Form.Field>
          <Form.Field>
            <label>Auction End Time</label>
            <Field
              component={FormField}
              name="auctionExpireDate"
              type="datetime"
              min={1}
              placeholder="Select Auction End Time"
              validate={[required()]}
            />
          </Form.Field>
        </React.Fragment>
      )}

      <Form.Field>
        <label>Blockchain Name</label>
        <Field
          component={FormField}
          name="blockChainName"
          type="select"
          children={blockChainData}
          placeholder="BlockChain Name"
          disabled={isEdit}
          // validate={[required()]}
        />
      </Form.Field>
      <Form.Field className="loginBtn">
        <Button
          type="submit"
          primary={true}
          disabled={isEdit && nftValue.sold === 1 ? true : false}
        >
          {" "}
          Submit{" "}
        </Button>
      </Form.Field>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state?.nft?.nftData,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: "CreateNftForm", enableReinitialize: true })
)(CreateNftForm);
