import Types from "../_actions/_types";

const initialState = {
  typeDetails: {},
};

const addType = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_ADDTYPE_DETAILS:
      return { ...state, typeDetails: payload.typeDetails };

    default:
      return state;
  }
};

export default addType;
