import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";
import Types from "./_types";

export const saveFaqDetails = (res) => {
  return {
    type: Types.SAVE_FAQ_DETAILS,
    payload: res,
  }
};

/**
 * @function UpdateFaqData
 * @param {{ id }} data
 * @returns
 */

export const getFaqDataById = (id) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    const res = await ApiService.getFaqDetails({ id })
    // = await ApiService.getFaqDetails(data, headers);

    let finalData = res.data?.data;
    dispatch(saveFaqDetails(finalData));
    dispatch(stopLoading());
    return finalData;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};


/**
 * @function getNftById
 * @param {{ page, limit }} data
 * @returns /getNftById/limit/page
 */
export const emptyRafflesFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveFaqDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

