import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { withRouter } from "react-router";
import { Button, Form, Image } from "semantic-ui-react";
import MainLayout from "../../../../components/HOC/MainLayout";
//import CreateRafflesForm from "./CreateCategoriesForm";
import { BannerActions, RafflesActions } from "../../../../redux/_actions";
import { toast } from "../../../../components/Toast/Toast";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import TaxCreateForm from "./TaxCreateForm";

const TaxCreate = ({
  match: {
    params: { id },
  },
  history,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [updateRaffles, setUpdateRafffles] = useState({});

  const goBack = () => history.goBack();

  const submitForm = async (data) => {
    if (!isEdit) {
      const { createTax } = BannerActions;
      const res = await dispatch(createTax(data));
      if (res) {
        getRafflesData();
        toast.success(res?.data?.message);
        history.goBack();
      }
    }
     else {
      const { updateTaxStatus } = BannerActions;
      const res = await dispatch(updateTaxStatus(data)).then((_data) => {
        console.log(_data);
        if (!_data?.error) {
          toast.success(_data?.data?.message);
          console.log(_data, "sss")
          history.goBack();
        }
        console.log(_data?.message);
      });

      if (res) {
        toast.success(res?.data?.message);
        history.goBack();
      }
    }
  };

  const getRafflesData = () => {
    const { getTaxById } = BannerActions;
    if (id) {
      dispatch(getTaxById({ id })).then((mydata) => {
        setUpdateRafffles(mydata);
        console.log(mydata , 'datadata')
      });
      setIsEdit(true);
    }
  };

  useEffect(() => {
    const { emptyTaxFormReducer } = BannerActions;
    if (id) {
      getRafflesData();
    }
    return () => {
      dispatch(emptyTaxFormReducer());
    };
  }, []);

  const deleteCategory = (data) => {
    const { deleteCategoryById } = RafflesActions;
    dispatch(deleteCategoryById(data)).then((_data) => {
      if (_data) {
        const { PAGE_LIMIT } = GlobalVariables;
        // getArtist({ id: 3, page: 0, limit: PAGE_LIMIT });
      }
    });
  };

  return (
    <MainLayout>
      <div className="sub_title">
        <h3>{isEdit ? "Update" : "Add"} Tax Benefit</h3>
        <Button className="addButton" onClick={goBack}>
          Back
        </Button>
      </div>

      <div className="create-nft-form">
        <TaxCreateForm
          onSubmit={submitForm}
          isEdit={isEdit}
          deleteCategory={deleteCategory}
        />
      </div>
    </MainLayout>
  );
};

export default withRouter(TaxCreate);
