import axios from "axios";
import { toast } from "../components/Toast/Toast";
export { _fetch as fetch };

function handleError(error, reject) {
  if (!error) {
    toast.error("Something went wrong, Please login and try again.!!");
    // setTimeout(() => {
    //   window.localStorage.clear();
    //   window.location = "/";
    // }, 2000);
  } else if (
    error &&
    (error?.data?.statusCode === 401 || error?.data?.statusCode === 403)
  ) {
    // const {
    //   data: { message },
    // } = error;
    toast.error("Please re-login, last login session expired.");
    setTimeout(() => {
      window.location.reload();
      localStorage.clear();
    }, 1000);
  }
  reject(error);
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, params) {
  let config = {};
  if (options) config.headers = options;

  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  } else if (method === "post" || method === "put") {
    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, options, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, params)
      .then(function (response) {
        handleResponse(response, resolve);
        return;
      })
      .catch(function (error) {
        // return handleError(error);
        handleError(error.response, reject);
        return;
      });
  });
}
