import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {
  Breadcrumb,
  Button,
  Confirm,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import TableRows from "../../../../components/TableRows";
import { CollectionActions } from "../../../../redux/_actions";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import InfiniteScroll from "react-infinite-scroller";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import CollectionRow from "./collectionRow";
import arrayMove from "./arrayMove";
import { toast } from "../../../../components/Toast/Toast";
import CreatorSearch from "../../creator/CreatorSearch";
import ExploreFilters from "../../creator/ExploreFilters";

const MyTableWrapper = styled.div`
  padding: 10px;

  .fixed_header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      // display: block;
      width: 100%;
      overflow: auto;
      // height: 400px;
      cursor: grabbing;
      background: grey;

      tr {
        td {
          background: #fff;
        }
        &:nth-child(even) {
          td {
            background: #fbf8f8;
          }
        }
      }
    }

    & > thead {
      background: #efefef;
      color: black;
      width: 100%;
      & > tr {
        // display: block;
        //width: 793px;
      }
    }
    & > thead th {
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      text-align: left;
      padding-left: 10px;
    }
    & > tbody td {
      padding: 5px;
      text-align: left;

      border-bottom: 1px solid #ccc;
    }
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <CollectionRow {...props} />);

const CollectionList = ({ getCollections, collections, history }) => {
  const [items, setItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const dispatch = useDispatch();

  const actionCollection = async (data) => {
    const { collectionUser } = CollectionActions;
    const { PAGE_LIMIT } = GlobalVariables;

    const res = await dispatch(collectionUser(data));

    if (res) {
      getCollections({ page: activeIndex, limit: PAGE_LIMIT });
    }
    if (data.status.isActive) {
      toast.success("Collection enable success");
    } else {
      toast.info("Collection disable");
    }
  };

  useEffect(() => {
    if (Array.isArray(collections.rows) && collections.rows.length > 0) {
      setItems(collections.rows);
    }
  }, [collections.rows]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  const goToAddNftpage = () =>
    history.push("/panel/collection/create-collection");

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActiveIndex(page);
    getCollections({ page, limit: GlobalVariables.PAGE_LIMIT });
  };

  const [searchText, setSearchText] = useState("");
  const [sortby, setSortby] = useState("");
  // const [collectionData, setCollectionData] = useState({});
  const [yearFilter, setYearFilter] = useState("");

  return (
    <>
      <div className="pageHeading">
        <h3>Recently Listed Collections </h3>
        {/* <p>List of all photographers collection</p> */}
        {/* <Button className="addButton" onClick={goToAddNftpage}>
          Add New Collection
        </Button> */}
      </div>

      <div className="creator_search_filter d-md-flex mt-30">
        <CreatorSearch setSearchText={setSearchText} />
        <ExploreFilters
          noPicker={false}
          noTypeFilter={true}
          setSelectedSort={setSortby}
          setYearFilter={setYearFilter}
        />
      </div>

      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell colSpan="2">Serial no.</Table.HeaderCell> */}
            {/* <Table.HeaderCell colSpan="2">Image</Table.HeaderCell> */}
            {/*<Table.HeaderCell colSpan='2'>Cover Image</Table.HeaderCell>*/}
            <Table.HeaderCell colSpan="3">Collection</Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="3">Description</Table.HeaderCell> */}
            {/*<Table.HeaderCell colSpan='3'>Brand Name</Table.HeaderCell>*/}
            {/*<Table.HeaderCell colSpan='3'>Is Featured</Table.HeaderCell>*/}
            <Table.HeaderCell colSpan="3">Creator</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Items</Table.HeaderCell>
            <Table.HeaderCell textAlign="right" colSpan="3">
              Listing Time & Date
            </Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="3" textAlign="right">Total nft</Table.HeaderCell>
            <Table.HeaderCell colSpan="3" textAlign="right">Actions</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Array.isArray(items) && items.length > 0 ? (
            items.map((row, i) => (
              <TableRows
                isCollection={true}
                data={row}
                index={i}
                key={i}
                activePage={activeIndex}
                actionCollection={actionCollection}
              />
            ))
          ) : (
            <Table.Cell collapsing colSpan="18" textAlign="center">
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "15px",
                }}
              >
                No Record Found.
              </div>
            </Table.Cell>
          )}
        </Table.Body>
      </Table>

      {collections.rows && collections.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          className="customPagination"
          activePage={activeIndex}
          totalPages={
            Math.ceil(
              parseInt(collections.count) / GlobalVariables.PAGE_LIMIT
            ) || 1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(CollectionList);
