import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import collection from "../_reducers/collection.reducer";
import Types from "./_types";

export const saveCollection = (payload) => ({
  type: Types.SAVE_COLLECTION,
  payload,
});

export const getCreatorList = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCreatorList(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getCollections
 * @param {{ page, limit }} data
 * @returns /getCollections/limit/page
 */
export const getCollections = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollections(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getCollectionById
 * @param {{ id }} data
 * @returns get particular collection
 */
export const getCollectionById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollectionById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      console.log("data-------------- ", data);
      // data.creator = data.creator === "1" ? "ADMIN" : data.creator
      data.isFeatured = data.isFeatured === true ? 1 : 0;
      // data.subHeading = data.
      dispatch(saveCollection({ collection: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createCollection
 * @param {{ logo, banner, name, description }} data
 * @returns /createCollection
 */
export const createCollection = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subHeading,
      creator,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    logo = await dispatch(uploadFile(logo, "collection"));
    if (!logo) return toast.info("Error: Uploading failed");
    banner = await dispatch(uploadFile(banner, "collection"));
    if (!banner) return toast.info("Error: Uploading failed");

    data = {
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subTitle: subHeading,
      creator,
    };
    const res = await ApiService.createCollection(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const collectionUser = (data) => async (dispatch) => {
  console.log(data);
  const { startLoading, stopLoading } = LoadingActions;
  const { setHeaders, uploadFile } = PersistActions;

  const headers = await dispatch(setHeaders({ isFormdata: false }));

  try {
    console.log("hetee");
    dispatch(startLoading());
    let res = await ApiService.collection(data, headers);
    dispatch(stopLoading());

    return res;
  } catch (error) {
    dispatch(stopLoading());

    throw error;
  }
};

/**
 * @function updateCollection
 * @param {{ logo, banner, name, description }} data
 * @returns /createCollection
 */
export const updateCollection = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      id,
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subHeading,
      creator,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (typeof banner !== "string") {
      banner = await dispatch(uploadFile(banner, "collection"));
      if (!banner) return toast.info("Error: Uploading failed");
    }

    if (typeof logo !== "string") {
      logo = await dispatch(uploadFile(logo, "collection"));
      if (!logo) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subHeading,
      creator,
    };
    const res = await ApiService.updateCollection(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
