import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider, useDispatch } from "react-redux";
import configureStore from "./redux/_store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/_store/history";
import { PersistGate } from "redux-persist/lib/integration/react";
let { store, persistor } = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        {" "}
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
export const storeInstance = store;

serviceWorker.unregister();
