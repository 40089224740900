import React from "react";

export default function Auction() {
  return (
    <>
      <div className="fixed_price">
        <h3>Sale Price</h3>
        <div className="tab_input">
          <p>Amount</p>
          <h4>30 days</h4>
        </div>
      </div>
      <div className="fixed_price">
        <h3>Reserve Price</h3>
        <div className="tab_input">
          <p>Amount</p>
          <h4>15 days</h4>
        </div>
      </div>
    </>
  );
}
