import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Table, Confirm, Button } from "semantic-ui-react";
import ImageVideoModal from "../../../components/Modal/ImageVideoModal";
import { limitCharacters } from "../../../Assets/data/utils";

const TableRows = ({ index, data, deleteBanner, activePage }) => {
  const [open, setOpen] = useState(false);
  const [imageId, setImageId] = useState(null);
  console.log("data.imageUrl", data.imageUrl);
  return (
    <>
      <Confirm
        open={open}
        header="Delete Banner"
        content="Are you sure you want to delete this Banner?"
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          deleteBanner(imageId);
        }}
      />
      <Table.Row>
        <Table.Cell colSpan="3" collapsing textAlign="left">
          {activePage * 10 - 10 + index + 1}
        </Table.Cell>
        <Table.Cell collapsing colSpan="3" textAlign="left">
          <ImageVideoModal
            fileType={data?.fileType}
            logo={data.image ? data.image : "N/A"}
            isLogo={true}
          />
        </Table.Cell>
        <Table.Cell collapsing colSpan="3" textAlign="left">
          {data.title ? limitCharacters(data.title, 20) : "N/A"}
        </Table.Cell>

        <Table.Cell collapsing colSpan="3" textAlign="left">
          <Link to={`/panel/banner/edit-banner/${data.id}`}>
            <Icon name="edit" />
          </Link>
          <Icon
            style={{ marginLeft: "30px" }}
            name="delete"
            onClick={() => {
              setOpen(true);
              setImageId(data.id);
            }}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default TableRows;
