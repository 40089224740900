//import "../user.scss";
import "../NftCampaignList.scss";

import { BrandActions, UserActions } from "../../../../redux/_actions";
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import { Menu, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import ImageVideoModal from "../../../../components/Modal/ImageVideoModal";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";
import UserDetails from "../../../../components/UserDetails/UserDetails";
// import ViewCampaign from "../ViewCampaign";
import activeImg from "../../../../images/active.png";
import { apiCallPost } from "../../../../components/Axios/Axios";
import ethLogo from "../../../../Assets/image/ethereum.png";
import fanvLogo from "../../../../Assets/image/fanv.png";
import inactiveImg from "../../../../images/inactive.png";
import { limitCharacters } from "../../../../Assets/data/utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import verifyLogo from "../../../../Assets/image/verifyLogo.png";
import { withRouter } from "react-router";
import ViewCampaign from "../../Campaign/ViewCampaign";
import no_record from '../../../../Assets/image/no-record-found.png'

const NftCampaignList = ({
  getNftCampaign,
  campaign,
  search,
  handleSearch,
  page,
  setPage,
}) => {
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [artistId, setArtistId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeuserstatus, setActiveuserstatus] = useState(1);
  const [action, setAction] = useState({});

  const data = {
    // limit: 10,
    // page: 0,
    // fullname: "",
    // isType: "",
    // status: "",
    // email: "",
    // search: "",
    local_currency: "",
    campaign_type: "",
  };

  const [userFilter, setUserFilter] = useState(1);
  const [userCuFilter, setUserCuFilter] = useState(data);
  const [initialData, setInitialData] = useState({
    search: "",
    campaign_type: 1,
  });

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    // setPage(page);
    setActiveIndex(page);
    getNftCampaign({
      page: page,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
  };

  const campaignOpt = [
    { key: "campaign_type", text: "CROWD FUNDING", value: "" },
    { key: "campaign_type", text: "NFT CAMPAIGN", value: "" },
  ];
  const transactionOptions = [
    { key: "local_currency", text: "USD", value: 1 },
    { key: "local_currency", text: "GBP", value: 3 },
  ];
  const userFilters = async (search, typeSearch) => {
    const data = {
      limit: 10,
      page: 1,
      //fullname: "",
      //isType: initialData.isType,
      //status: initialData.isType,
      //email: userFilter.email,
      //search: userFilter.search,
      local_currency: initialData.local_currency,
      campaign_type: initialData.campaign_type,
    };

    // getNftCampaign(data);
  };

  useEffect(() => {
    const filterId = setTimeout(() => {
      userFilters();
    }, 500);
    return () => clearTimeout(filterId);
  }, [userFilter, initialData]);

  function updateUser(newData) {
    setOpen(false);

    apiCallPost(`/api/v1/admin/nft/update`, newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            getNftCampaign({
              page: 1,
              limit: GlobalVariables.PAGE_LIMIT,
              search: search,
            });
          }
        }
      })
      .catch((err) => { });
  }

  // let status = activeuserstatus == 0 ? "Block" : "Unblock";

  // let modelcontent = "Are You Sure You Want To " + status + " User?";
  // let payload = {
  //   userid: artistId,
  //   isActive: activeuserstatus,
  // };

  // function refreshPage() {
  //   window.location.reload(false);
  // }
  return (
    <>
      <Confirm
        content={`Are you sure? You want to ${action.remarks} this Campaign!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          updateUser(action);
        }}
      />
      <div className="pageHeading d-flex">
        <h3>NFT Campaigns</h3>
        <Form.Field className="search_field">
          <input
            placeholder="Search"
            className="search"
            value={search}
            onChange={handleSearch}
          />
        </Form.Field>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >


          <Dropdown
            className="select_dropdown"
            placeholder="CROWD FUNDING"
            selection
            options={campaignOpt}
            value={
              userFilter.filter === "campaign_type"
                ? userFilter.value
                : initialData.campaign_type
            }
            onChange={(e, data) => {
              //setUserFilter({ ...userFilter, campaign_type: data.value });
              setInitialData({ ...initialData, campaign_type: data.value });
            }}
          />
          <Dropdown
            className="select_dropdown"
            placeholder="Select Currency"
            selection
            options={transactionOptions}
            value={
              userCuFilter.filter === "local_currency"
                ? userCuFilter.value
                : initialData.local_currency
            }
            onChange={(e, data) => {
              //setUserCuFilter({ ...userCuFilter, local_currency: data.value });
              setInitialData({ ...initialData, local_currency: data.value });
            }}
          />
        </div> */}
      </div>

      <div className="pageHeading">
        {/* <p>List Of {`${initialData?.campaign_type === 1 ? "Crowd Funding" : "NFT Campaign"}`}</p> */}
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Serial No.</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Title</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Image</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Category</Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="2">Fundraiser Id</Table.HeaderCell> */}
            <Table.HeaderCell colSpan="2">Description</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaign && campaign.count > 0 && campaign?.rows?.length > 0 ? (
            campaign?.rows?.map((row, i) => (
              <Table.Row key={i}>
                {/* <Table.HeaderCell colSpan="2">Serial No.</Table.HeaderCell> */}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {10 - 10 + i + 1}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.title ? limitCharacters(row.title, 20) : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <ImageVideoModal
                    fileType={row?.fileType}
                    logo={row.image ? row.image : "N/A"}
                    isLogo={true}
                  />
                </Table.Cell>

                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                 {row?.wallet_id ? limitCharacters(row.wallet_id, 20) : "N/A"}
                </Table.Cell>*/}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.category ? limitCharacters(row.category, 20) : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.description
                    ? limitCharacters(row.description, 20)
                    : "N/A"}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  {moment(row?.date_birth).format("MMMM Do YYYY") || "N/A"}
                </Table.Cell> */}

                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <div
                    className="modalData"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* {row?.is_deleted == 0 && (
                      <Button.Group style={{ marginTop: 5 }}>
                        <Button
                          negative
                          onClick={() => {
                            setOpen(true);
                            setAction({
                              id: row.id,
                              fundraiser_id: row.fundraiser_id,
                              is_deleted: row.is_deleted == 1 ? 0 : 1,
                              remarks:
                                row.is_deleted == 1 ? "Publish" : "Delete",
                            });
                          }}
                        >
                          {row.is_deleted == 1 ? "Publish" : "  Delete"}
                        </Button>
                        <span style={{ marginLeft: "10px" }}></span>
                      </Button.Group>
                    )} */}

                    <img
                      title={row.isActive == 1 ? "Inactive" : "Active"}
                      style={{
                        marginLeft: "10px",
                        height: "35px",
                        width: "35px",
                        cursor: "pointer",
                      }}
                      primary
                      alt={"img"}
                      src={row.isActive == 1 ? activeImg : inactiveImg}
                      onClick={() => {
                        setOpen(true);
                        setAction({
                          id: row.id,
                          fundraiser_id: row.fundraiserId,
                          is_active: row.isActive == 1 ? 0 : 1,
                          remarks: row.isActive == 1 ? "Inactive" : "Active",
                        });
                      }}
                    />
                    <ViewCampaign
                      UserDetails={row}
                      myKycActions={getNftCampaign}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  <img src={no_record} alt="not-found-img" />

                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {/* {campaign?.count && campaign?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(campaign?.count) / GlobalVariables.PAGE_LIMIT) ||
            1
          }
        />
      ) : (
        ""
      )} */}
      {campaign?.count && campaign?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(campaign?.count) / GlobalVariables.PAGE_LIMIT) ||
            1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(NftCampaignList);
