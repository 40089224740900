import { Pagination } from 'antd';
import React, { useState } from 'react'
import iconEthereum from "../../../images/icon_ethereum.svg";

const Offer = () => {
    const dataSource = [
        {
            key: '1',
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            floordifference: "13% below",
            expiration: '30 days',
            from: 'F33F07',
        },
        {
            key: '2',
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            floordifference: '13% below',
            expiration: '30 days',
            from: 'F33F07',
        },
        {
            key: '3',
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            floordifference: '13% below',
            expiration: '30 days',
            from: 'F33F07',
        },
        {
            key: '4',
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            floordifference: '13% below',
            expiration: '30 days',
            from: 'F33F07',
        },
        {
            key: '5',
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            floordifference: '25% below',
            expiration: '30 days',
            from: 'F33F07',
        },
    ];

    const columnsHeading = [
        {
            id: '1',
            title: 'Price',
        },
        {
            id: '2',
            title: 'Quantity',
        },
        {
            id: '3',
            title: 'Expiration',
        },
        {
            id: '4',
            title: 'From',
        },
        // {
        //     id: '5',
        //     title: 'Action',
        // },
    ];

    return (
        <>
            <div className='commonTable'>
                {/* <Table dataSource={dataSource} columns={columns} />; */}
                <div className='listingTable'>
                    <div className='listingTableHeading d-md-flex'>
                        {columnsHeading.map((item) => {
                            return (
                                <h5>{item.title}</h5>
                            )
                        })}
                    </div>
                    <div className='listingTableData'>
                        {dataSource.map((item) => {
                            return (
                                <ul className='d-md-flex'>
                                    <li><span className='mobileTitle'>Price</span> <span>{item.price}</span></li>
                                    <li><span className='mobileTitle'>Floor Difference</span> <span>{item.floordifference}</span></li>
                                    <li><span className='mobileTitle'>Expiration</span> <span>{item.expiration}</span></li>
                                    <li><span className='mobileTitle'>From</span> <span>{item.from}</span></li>
                                    {/* <li><span className='mobileTitle'>Action</span> <span>{item.action}</span></li> */}
                                </ul>
                            )
                        })}
                    </div>
                    <Pagination defaultCurrent={1} total={50} />;
                </div>
            </div>
        </>
    )
}

export default Offer