import Types from "../_actions/_types";

const initialState = {
  collection: {}
};

const collection = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_COLLECTION:
      return { ...state, collection: payload.collection};

    default:
      return state;
  }
};

export default collection;
