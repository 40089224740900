import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CreatorInfo from "./CreatorInfo";
import HeaderCreator from "./HeaderCreator";
import banner from "../../../images/create_header.jpg";
import CreatorSearch from "./CreatorSearch";
// import CreatorFilters from "./CreatorFilters";
// import { data } from "../../../Assets/data/creators";
import CardMyCollection from "../../../components/CardMyCollection/CardMyCollection";
import ExploreFilters from "./ExploreFilters";
import "./MyCollection.scss";
import MainLayout from "../../../components/HOC/MainLayout";

const MyCollection = () => {
  const [searchText, setSearchText] = useState("");
  const [sortby, setSortby] = useState("");
  // const [collectionData, setCollectionData] = useState({});
  const [yearFilter, setYearFilter] = useState("");

  const collectionData = [
    {
      id: "1",
      collection_img: "1",
      name: "1",
      collectionname: "1",
      currType: "1",
      totalVolume: "1",
      minted: "1",
    },
  ];

  return (
    <>
      {collectionData && (
        <MainLayout className="myCollectionPage">
          <div className="creatorBg_page explorecollection_page">
            <HeaderCreator
              userData={collectionData}
              banner={collectionData?.banner || banner}
            />
            <CreatorInfo
              type="Collection"
              creatorImg={collectionData?.logo}
              creatorName={collectionData?.name || collectionData?.fullname || ""}
              creatorUserName={collectionData?.username}
              description={collectionData?.description}
              role_type={collectionData?.role_type}
              editButton={true}
            // editButtonOnClick={() => (`/edit-collection/${params.id}`)}
            />

            <Col className="container creatorWrap">
              <div className="creator_search_filter d-md-flex">
                <CreatorSearch setSearchText={setSearchText} />
                <ExploreFilters
                  noPicker={true}
                  noTypeFilter={false}
                  setSelectedSort={setSortby}
                  setYearFilter={setYearFilter}
                />
              </div>
              <Row gutter={20} className="featureSection remove_hover">
                {collectionData.map((item) => {
                  return (
                    <Col
                      className="featureSection__list__item"
                      xs={24}
                      sm={12}
                      lg={8}
                      xl={6}
                    >
                      <CardMyCollection
                        img={item.collection_img}
                        creatorName={item.name}
                        collectionName={item.collectionname}
                        currSymbol={item.currType}
                        totalVolume={item.totalVolume}
                        mintedText={item.minted}
                      />
                    </Col>
                  );
                })}
              </Row>
              {/* <Feed text="28 minutes ago" person_img={costa} name="vino_costa" sign_img={tick} like_img={likes}
                comment_img={CommentIcon} upload_img={uploadicon} threedot_img={threedotIcon}
                subtext="Leader in cryptocurrency, Bitcoin, Ethereum, XRP, blockchain, DeFi, digital finance and Web 3.0 news with analysis, video and live price updates"
              /> */}
            </Col>
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default MyCollection; 
