import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";

import {
  Button,
  Icon,
  Pagination,
  Table,
  Image,
  Confirm,
} from "semantic-ui-react";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import { Link } from "react-router-dom";
import ViewKyc from "./ViewKyc";

const KycList = ({
  getKyc,
  userKyc,
  brand,
  history,
  deleteArtist,
  kycActionsData,
}) => {
  const [open, setOpen] = useState(false);
  const [artistId, setArtistId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    console.log("dksjhfsdkjfhksjdf : ", userKyc);
  }, [JSON.stringify(userKyc)]);

  const AddNewArtist = () => {
    history.push("/panel/artist/create-artist");
  };
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActiveIndex(page);
    getKyc({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };

  const myKycActions = () => {
    kycActionsData();
  };
  return (
    <>
      {/* <Confirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          deleteArtist(artistId);
        }}
      /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>User KYC</h3>
      </div>

      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Serial no.</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Full Name</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Phone Number</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Kyc Status</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {userKyc?.rows && userKyc?.rows.length > 0 ? (
            userKyc?.rows.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {activeIndex * 10 - 10 + i + 1}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {row.fullName}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row.phoneNumber ? row.phoneNumber : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row.kycStatus ? row.kycStatus : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <div
                    className="modalData"
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ViewKyc kycDetail={row} myKycActions={myKycActions} />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Kyc Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {userKyc?.count && userKyc?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          count={
            Math.ceil(parseInt(userKyc?.count) / GlobalVariables.PAGE_LIMIT) ||
            1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(KycList);
