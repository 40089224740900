import React from 'react'
import { Button } from 'antd';
import './FvButtonStyle.scss'

const FvButton = (props) => {
  return (
    <Button {...props} type="primary">{props.text}</Button>
  )
}

export default FvButton

