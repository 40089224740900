import React, { useEffect } from "react";
import "./App.scss";
import Application from "./Routes/Application";
import { Provider, useDispatch } from "react-redux";
import configureStore from "./redux/_store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/_store/history";
import { PersistGate } from "redux-persist/lib/integration/react";
import "./index.scss";

let { store, persistor } = configureStore();
function App() {
  useEffect(() => {
    document.body.classList.add("darkTheme");
  }, []);
  return <Application />;
}

export default App;
