import React, { Component } from "react";

import AddArtist from "../../pages/private/user/Create/AddUser";
import AddFaq from "../../pages/private/Faq/Create/AddFaq";
import AddType from "../../pages/private/addType/AddType";
import AdminAddUser from "../../pages/private/AddUserAdmin/AdminAddUser";
import AdminEditUser from "../../pages/private/AddUserAdmin/AdminEditUser";
import AdminFee from "../../pages/private/AdminFee/AdminFee.jsx";
import AllReport from "../../pages/private/AllReport/AllReport.jsx";
import Artist from "../../pages/private/user/User";
import Banner from "../../pages/private/banner/Banner";
import Campaign from "../../pages/private/Campaign/Campaign";
import Category from "../../pages/private/Categories/Categories";
import ChangePassword from "../../pages/public/changepassword/ChangePassword";
import Collection from "../../pages/private/collection/Collection";
import CreateBanner from "../../pages/private/banner/create/createBanner";
import CreateCategory from "../../pages/private/Categories/create/CreateCategories";
import CreateCollection from "../../pages/private/collection/create/CreateCollection";
import CreateCollectionFrom from "../../pages/private/CreateCollectionFrom/CreateCollectionFrom";
import CreateMedia from "../../pages/private/Media/Create/CreateMedia";
import CreateNft from "../../pages/private/nft/create/CreateNft";
import CreateQuotes from "../../pages/private/quotes/create/CreateQuotes";
import CreateType from "../../pages/private/addType/create/CreateType";
import Dashboard from "../../pages/private/Dashboard/Dashboard.jsx";
import Faqs from "../../pages/private/Faq/faq";
import KycList from "../../pages/private/Kyc/UserKycList";
import LanguageSetting from "../../pages/private/LanguageSetting/LanguageSetting";
import ListforSale from "../../pages/private/ListforSale/ListforSale";
import Media from "../../pages/private/Media/Media";
import MyCollection from "../../pages/private/creator/MyCollection";
import MyNft from "../../pages/private/MyNft/MyNft";
import Nft from "../../pages/private/nft/Nft";
import NftWithdraw from "../../pages/private/NftWithdrawRequest/NftWithdraw";
import Quotes from "../../pages/private/quotes/Quotes";
import { Route } from "react-router-dom";
import SellReport from "../../pages/private/SellReport/SellReport";
import Setting from "../../pages/private/LanguageSetting/Setting";
import Tax from "../../pages/private/Tax Benefit/Tax";
import TaxCreate from "../../pages/private/Tax Benefit/Create/TaxCreate";
import TransactionFee from "../../pages/private/CampaignSetting/CampaignLimit";
import Transactions from "../../pages/private/Transactions/transaction";
import UpdateKyc from "../../pages/private/Kyc/Create/UpdateKyc";
import WithdrawTransaction from "../../pages/private/Transaction/WithdrawTransaction";
import { connect } from "react-redux";
import createNftFrom from "../../pages/private/createNftFrom/createNftFrom";
import packageJson from "../../../package.json";
import transaction from "../../pages/private/Transactions/transaction";
import { withRouter } from "react-router";
import NftCampaign from "../../pages/private/NftCampaign/NftCampaign";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

class PrivateRoutes extends Component {
  componentDidMount = async () => {
    const { loggedIn } = this.props;
    console.log(
      packageJson.version,
      "=========packageJson===================="
    );
    if (loggedIn) {
      if (
        localStorage.getItem("REACT_CACHE") === null ||
        localStorage.getItem("REACT_CACHE") === undefined
      ) {
        window.localStorage.clear();
        window.location.reload();
      } else {
        console.log(
          localStorage.getItem("REACT_CACHE"),
          "==================else=========="
        );
        if (packageJson.version !== localStorage.getItem("REACT_CACHE")) {
          console.log("==================if2==========");
          window.localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  render() {
    return (
      // Stump up Routes START
      <div className="PrivateArea__content">
        {/* <Route path="/panel/dashboard" component={Dashboard} exact={true} /> */}
        <Route path="/panel/user" component={Artist} exact={true} />
        <Route path="/panel/banner" component={Banner} exact={true} />
        <Route path="/panel/collection" component={Collection} exact />
        <Route path="/panel/change_password" component={ChangePassword} />
        {/* <Route path="/panel/faq"component={Faqs} exact={true}/>
        <Route path="/panel/faq/add-faq" component={AddFaq} exact={true}/> */}
        <Route path="/panel/transactions" exact={true} />
        <Route path="/panel/faq" component={Faqs} exact={true} />
        <Route path="/panel/faq/add-faq" component={AddFaq} exact={true} />
        <Route
          path="/panel/faq/update-faq/:id"
          component={AddFaq}
          exact={true}
        />
        {/* <Route path="/panel/change_password" component={ChangePassword} /> */}
        <Route path="/panel/fee" component={AdminFee} exact />
        <Route path="/panel/tax_benefit" component={Tax} exact />
        <Route path="/panel/tax_benefit/create_tax" component={TaxCreate} />
        <Route path="/panel/tax_benefit/edit_tax/:id" component={TaxCreate} />
        <Route path="/panel/Category" component={Category} exact={true} />
        <Route
          path="/panel/categories/create-categories"
          component={CreateCategory}
          exact={true}
        />
        <Route
          path="/panel/categories/edit-categories/:id"
          component={CreateCategory}
          exact={true}
        />
        <Route path="/panel/media_coverage" component={Media} exact={true} />
        <Route
          path="/panel/media_coverage/create_media"
          component={CreateMedia}
          exact={true}
        />
        <Route
          path="/panel/media_coverage/edit_media/:id"
          component={CreateMedia}
          exact={true}
        />
        <Route path="/panel/quotes" component={Quotes} exact={true} />
        <Route
          path="/panel/quotes/create_quotes"
          component={CreateQuotes}
          exact={true}
        />
        <Route
          path="/panel/quotes/edit_quotes/:id"
          component={CreateQuotes}
          exact={true}
        />
        <Route path="/panel/campaign" component={Campaign} exact={true} />
        <Route
          path="/panel/nft-campaign"
          component={NftCampaign}
          exact={true}
        />
        <Route path="/panel/setting" component={Setting} exact={true} />

        {/* Stump up */}

        <Route
          path="/panel/collection/create-collection"
          component={CreateCollection}
        />
        <Route
          path="/panel/collection/edit-collection/:id"
          component={CreateCollection}
        />

        <Route path="/panel/transactionFee" component={TransactionFee} exact />
        <Route path="/panel/nftWithdrawRequest" component={NftWithdraw} exact />

        <Route path="/panel/nfts" component={Nft} exact={true} />
        <Route path="/panel/nfts/:userId" component={Nft} exact={true} />
        <Route path="/panel/nft-reports" component={AllReport} exact={true} />
        <Route
          path="/panel/nfts/create-nft"
          component={CreateNft}
          exact={true}
        />
        <Route
          path="/panel/users/add-user"
          component={AdminAddUser}
          exact={true}
        />
        <Route
          path="/panel/users/edit-user/:id"
          component={AdminEditUser}
          exact={true}
        />

        <Route
          path="/panel/nfts/edit-nft/:id"
          component={CreateNft}
          exact={true}
        />

        <Route path="/panel/types" component={AddType} exact={true} />
        <Route
          path="/panel/types/create-type"
          component={CreateType}
          exact={true}
        />
        <Route
          path="/panel/types/edit-type/:id"
          component={CreateType}
          exact={true}
        />
        <Route
          path="/panel/banner/create-banner"
          component={CreateBanner}
          exact={true}
        />
        <Route
          path="/panel/banner/edit-banner/:id"
          component={CreateBanner}
          exact={true}
        />
        <Route path="/panel/categories" component={Category} exact={true} />
        <Route path="/panel/artist" component={Artist} exact={true} />
        <Route
          path="/panel/artist/create-artist"
          component={AddArtist}
          exact={true}
        />
        {/* <Route
          path="/panel/user/edit-user/:id"
          component={AddArtist}
          exact={true}
        /> */}

        <Route path="/panel/userKyc" component={KycList} exact={true} />
        <Route
          path="/panel/userKyc/edit-kyc/:id"
          component={UpdateKyc}
          exact={true}
        />
        <Route path="/panel/sell-report" component={SellReport} exact={true} />

        <Route
          path="/panel/withdraw-transaction"
          component={WithdrawTransaction}
          exact={true}
        />

        <Route
          path="/panel/language-setting"
          component={LanguageSetting}
          exact={true}
        />
        <Route
          path="/panel/createnftfrom"
          component={createNftFrom}
          exact={true}
        />
        <Route
          path="/panel/createcollectionfrom"
          component={CreateCollectionFrom}
          exact={true}
        />
        <Route path="/panel/listforsale" component={ListforSale} exact={true} />
        <Route path="/panel/mynft" component={MyNft} exact={true} />
        <Route
          path="/panel/mycollection"
          component={MyCollection}
          exact={true}
        />
        <Route path="*">
          <Redirect to="/panel/user" />
        </Route>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.persist.loggedIn,
  };
};

export default connect(mapStateToProps, null)(withRouter(PrivateRoutes));
