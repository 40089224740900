import React from "react";
import MainLayout from "../../../components/HOC/MainLayout";
import NftReports from "./nftReports/NftReports";
import { Tab } from "semantic-ui-react";
import PhotographerReport from "./photographerReport/PhotographerReport";
import { Tabs } from "antd";

function AllReport() {
  const { TabPane } = Tabs;
  // const panes = [
  //   {
  //     menuItem: "Photographer Report",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <PhotographerReport />
  //       </Tab.Pane>
  //     ),
  //   },
  //   {
  //     menuItem: "NFT Report",
  //     render: () => (
  //       <Tab.Pane attached={false}>
  //         <NftReports />
  //       </Tab.Pane>
  //     ),
  //   },
  // ];
  return (
    <MainLayout>
      {/* <Tab menu={{ pointing: true }} panes={panes} /> */}
      <div className="pageHeading">
        <h3>Report's</h3>
        {/* <p className="alignleft">List of all registered users</p> */}
      </div>
      <Tabs defaultActiveKey="1">
        {/* <TabPane tab="Photographer Report" key="1">
          <PhotographerReport />
        </TabPane> */}
        <TabPane tab="NFT Report" key="1">
          <NftReports />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
}

export default AllReport;
