import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import Types from "./_types";

export const saveTypeDetails = (payload) => ({
  type: Types.SAVE_ADDTYPE_DETAILS,
  payload,
});

/**
 * @function getBanners
 * @param {{ page, limit }} data
 * @returns /getCollections/limit/page
 */
export const getTypeList = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getExtraTypes(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getBannerById
 * @param {{ id }} data
 * @returns
 */
export const getTypeById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getExtraTypeById(data, headers);
    console.log("&&&", res);
    if (res) {
      const {
        data: { data },
      } = res;
      console.log("final", data);

      dispatch(saveTypeDetails({ typeDetails: data[0] }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

// /**
//  * @function createBanner
//  * @param {{ title, subtitle, imageUrl, startDate, endDate}} data
//  * @returns /createCollection
//  */
export const createType = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadBannerImages } = PersistActions;
    let { extras_image, extras_name, is_featured } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    extras_image = await dispatch(uploadBannerImages(extras_image, "banners"));
    if (!extras_image) return toast.info("Error: Uploading banner failed");

    // gifImage = await dispatch(uploadFile(gifImage,"banners"));
    // if (!gifImage) return toast.info("Error: Uploading gif failed");

    data = {
      extras_image,
      extras_name,
      is_featured,
    };
    const res = await ApiService.createExtraType(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updatebanner
 * @param {*} data
 * @returns
 */
export const updateType = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      id,
      extras_name,
      extras_image,
      status,
      is_deleted,
      is_featured,
      createdAt,
      updatedAt,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (typeof extras_image !== "string") {
      extras_image = await dispatch(uploadFile(extras_image, "banners"));
      if (!extras_image) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      extras_name,
      extras_image,
      status,
      is_deleted,
      is_featured,
      createdAt,
      updatedAt,
    };
    const res = await ApiService.updateExtraType(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const deleteType = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.deleteExtraType(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
