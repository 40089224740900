const PAGE_LIMIT = 10
const POLYGON_NETWORK = 'https://polygonscan.com/tx'
const KLAYTN_NETWORK = 'https://scope.klaytn.com/tx'

const GlobalVariables = {
  PAGE_LIMIT,
  POLYGON_NETWORK,
  KLAYTN_NETWORK,
}

export default GlobalVariables
