import React, {useState} from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Space } from "antd";
import "./PropertiesModal.scss";
// import TextArea from "antd/lib/input/TextArea";
// import NftfundModal from "../../../../components/NftfundModal/NftfundModal";

const PropertiesModal = ({ isModalVisible, handleOk, handleCancel }) => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinished = (values) => {
    console.log("Received values of form:", values);
  };

  return (
    <>
      <Modal
        className="CreateTicketModal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <div className="CreateTicket">
          <div className="sub_title modalhead">
            <h2>Add Properties</h2>
          </div>
          <p>
            properties show uo underneathyour item,are clickable and can be
            filtered in your collections sideber.
          </p>
         
          <Form
            name="dynamic_form_nest_item"
            onFinished={onFinished}
            autoComplete="off" className="mt-32"
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item label="Type"
                        {...restField}
                        name={[name, "first"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        
                        <Input placeholder="Character" />
                      </Form.Item>
                      <Form.Item label="Name"
                        {...restField}
                        name={[name, "last"]}
                        rules={[
                          { required: true, message: "Missing last name" },
                        ]}
                      >
                        <Input placeholder="Male" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} className="modal_minus" />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="modal_btn"
                      onClick={() => add()}
                      block
                    >
                      Add More
                    </Button>
                  </Form.Item>
                  <Button type="primary" className='grey_btn w-100 mt-32' htmlType="submit">Save</Button>
                  
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PropertiesModal;
