import React from 'react'
// import { IconAdd } from '../../../assets/images/svg/SvgImages'

const MessageFollow = ({followRequest,className}) => {
    
    const handleRequest = () => {
        followRequest();
    }
    return (
        <>
            <div className={className}>
                <div className="creatorDetails__buttons">
                    <ul>
                        <li>
                            <button className="creatorDetails__buttons__btnMessage">Message</button>
                        </li>
                        <li>
                            <button onClick={handleRequest} className="creatorDetails__buttons__btnFollow"> Follow</button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MessageFollow