import { ArtistActions, BrandActions } from "../../../redux/_actions";
import React, { useCallback, useEffect, useState } from "react";

import GlobalVariables from "../../../_constants/GlobalVariables";
import MainLayout from "../../../components/HOC/MainLayout";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import NftCampaignList from "./List/NftCampaignList";
//import ArtistList from "./List/UsertList";
//import "./user.scss";

const NftCampaign = () => {
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState({ count: 10, rows: [] });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const [requestFilter, setRequestFilter] = useState("ALL");

  const [activeTab, setActiveTab] = useState(1);
  const handleSearch = (e) => {
    // setSearch(e.target.value);
    getNftCampaign({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
  };

  const getNftCampaign = useCallback(
    async (data) => {
      const { getNftCampaign } = ArtistActions;
      const res = await dispatch(getNftCampaign(data));
      if (res) {
        setCampaign(res.data.data);
        setSearch(data.search);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    getNftCampaign({ id: 3, page: 1, limit: PAGE_LIMIT, search: search });
  }, [getNftCampaign, page]);

  //   const blockUser = (data) => {
  //     const { blockuserById } = ArtistActions;
  //     dispatch(blockuserById(data)).then((_data) => {
  //       if (_data) {
  //         const { PAGE_LIMIT } = GlobalVariables;
  //         getNftCampaign({ id: 3, page: 0, limit: PAGE_LIMIT, search: search , });
  //       }
  //     });
  //   };

  return (
    <>
      <MainLayout>
        <NftCampaignList
          getNftCampaign={getNftCampaign}
          campaign={campaign}
          //   blockUser={blockUser}
          setCampaign={setCampaign}
          setActiveTab={setActiveTab}
          handleSearch={handleSearch}
          page={page}
          setPage={setPage}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(NftCampaign);
