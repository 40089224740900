import "../user.scss";

import { BrandActions, UserActions } from "../../../../redux/_actions";
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import { Menu, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import ImageVideoModal from "../../../../components/Modal/ImageVideoModal";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";
import UserDetails from "../../../../components/UserDetails/UserDetails";
import ViewUser from "../ViewUser";
import activeImg from "../../../../images/active.png";
import { apiCallPost } from "../../../../components/Axios/Axios";
import ethLogo from "../../../../Assets/image/ethereum.png";
import fanvLogo from "../../../../Assets/image/fanv.png";
import inactiveImg from "../../../../images/inactive.png";
import { limitCharacters } from "../../../../Assets/data/utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import verifyLogo from "../../../../Assets/image/verifyLogo.png";
import { withRouter } from "react-router";
import "../user.scss";
import { withTheme } from "styled-components";

const UserList = ({
  getArtist,
  artist,
  handleSearch,
  search,
  getCreator,
  history,
  blockUser,
  name,
  title,
}) => {
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [artistId, setArtistId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeuserstatus, setActiveuserstatus] = useState(1);
  const [action, setAction] = useState({});

  const data = {
    user_type: "",
  };

  const [userRole, setUserRole] = useState("donor");
  const [initialData, setInitialData] = useState({
    search: "",
    role: "donor",
  });
  console.log("TAGLIST", artist);
  const dispatch = useDispatch();
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActiveIndex(page);
    getArtist({
      page: page,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
      role: userRole,
    });
  };
  const userOpt = [
    { key: "user_type", text: "Donor", value: "donor" },
    { key: "user_type", text: "Fundraiser", value: "fundraiser" },
  ];

  const userFilters = async (search, typeSearch) => {
    const data = {
      limit: 10,
      page: 0,
      user_type: initialData.user_type,
    };
    //getArtist(data);
  };

  // useEffect(() => {
  //   const filterId = setTimeout(() => {
  //     userFilters();
  //   }, 500);
  //   return () => clearTimeout(filterId);
  // }, [userRole, initialData]);

  useEffect(() => {
    getArtist({
      page: activeIndex,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
      role: userRole,
    });
    userFilters();
  }, [userRole]);

  function updateUser(newData) {
    setOpen(false);
    console.log("TAG", action);

    apiCallPost(
      `/api/v1/admin/user/updateUserById/${action.id}`,
      newData,
      {},
      "toastOn"
    )
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            getArtist({
              page: 1,
              limit: GlobalVariables.PAGE_LIMIT,
              search: search,
              role: userRole,
            });
          }
        }
      })
      .catch((err) => { });
  }

  let status = activeuserstatus == 0 ? "Block" : "Unblock";

  let modelcontent = "Are You Sure You Want To " + status + " User?";
  let payload = {
    userid: artistId,
    isActive: activeuserstatus,
  };
  // console.log("TAGGG", search);

  return (
    <>
      <Confirm
        content={`Are you sure? You want to ${action.remarks} this User!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          updateUser(action.update);
        }}
      />
      <div className="pageHeading d-sm-flex justify-content-between">
        <div>
          <h3>Users</h3>
        </div>
        <div
          className="admin_dropdown d-sm-flex"
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Dropdown
            className="select_dropdown"
            placeholder="Donor"
            selection
            options={userOpt}
            value={
              userRole.filter === "user_type"
                ? userRole.value
                : initialData.user_type
            }
            onChange={(e, role) => {
              setUserRole(role.value);
            }}
          />
          <Form.Field
            className="search_field"
          // style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            <input
              placeholder="Search"
              className="search"
              value={search}
              onChange={handleSearch}
            />
          </Form.Field>
        </div>
      </div>
      <div className="pageHeading">
        <p>List Of {`${userRole}`}</p>
      </div>
      <Table ui table style={{ overflow: 'auto', maxWidth: 'inherit', }}>
      
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell >Email</Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="2">Name</Table.HeaderCell> */}
            <Table.HeaderCell >Username</Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="2">DOB</Table.HeaderCell> */}
            {/* <Table.HeaderCell colSpan="2">KYC/KYB Status</Table.HeaderCell> */}
            <Table.HeaderCell >Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {artist && artist.count > 0 && artist?.rows?.length > 0 ? (
            artist?.rows?.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell collapsing textAlign="left">
                  {row?.email ? row.email : "N/A"}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row.first_name ? limitCharacters(row.first_name, 20) : "N/A"}
                </Table.Cell> */}

                <Table.Cell collapsing textAlign="left">
                  {row?.username ? limitCharacters(row.username, 20) : "N/A"}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.date_birth
                    ? moment(row?.date_birth).format("MMMM Do YYYY")
                    : "N/A"}
                </Table.Cell> */}
                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.data[0]?.status
                    ? limitCharacters(row?.data[0]?.status, 20)
                    : "N/A"}
                </Table.Cell> */}
                <Table.Cell collapsing textAlign="left">
                  <div
                    className="modaData"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button.Group style={{ marginTop: "5px" }}>
                      <Button
                        style={{
                          backgroundColor:
                            row?.is_suspended == 1 ? "lawngreen" : "red",
                          color: "#fff",
                        }}
                        onClick={() => {
                          setOpen(true);
                          setAction({
                            id: row.id,
                            update: {
                              is_suspended: row.is_suspended == 1 ? 0 : 1,
                            },
                            remarks:
                              row.is_suspended == 1 ? "Publish" : "Suspend",
                          });
                        }}
                      >
                        {row.is_suspended == 1 ? "Publish" : "Suspend"}
                      </Button>
                      <span style={{ marginLeft: "10px" }}></span>
                    </Button.Group>

                    {row?.is_deleted == 0 && (
                      <Button.Group style={{ marginTop: "5px" }}>
                        <Button
                          negative
                          onClick={() => {
                            setOpen(true);
                            setAction({
                              id: row.id,
                              update: {
                                is_deleted: row.is_deleted == 1 ? 0 : 1,
                              },
                              remarks:
                                row.is_deleted == 1 ? "Publish" : "Delete",
                            });
                          }}
                        >
                          {row.is_deleted == 1 ? "Publish" : "Delete"}
                        </Button>
                        <span style={{ marginLeft: "10px" }}></span>
                      </Button.Group>
                    )}
                    <img
                      title={row.is_active == 1 ? "Deactivate" : "Activate"}
                      style={{
                        marginLeft: "10px",
                        height: "35px",
                        width: "35px",
                        cursor: "pointer",
                      }}
                      primary
                      alt={"img"}
                      src={row.is_active == 1 ? activeImg : inactiveImg}
                      onClick={() => {
                        setOpen(true);
                        setAction({
                          id: row.id,
                          update: { is_active: row.is_active == 1 ? 0 : 1 },
                          remarks: row.is_active == 1 ? "Inactive" : "Active",
                        });
                      }}
                    />
                    {/* <ViewUser UserDetails={row} myKycActions={getArtist} /> */}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Record Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

      </Table>
      {artist?.count && artist?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          activePage={activeIndex}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(artist?.count) / GlobalVariables.PAGE_LIMIT) || 1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(UserList);
