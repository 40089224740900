import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";

/**
 * @function getWithdrawTransactions
 * @param {{ page, limit }} data
 * @returns /getWithdrawTransactions/limit/page
 */
export const getWithdrawTransactions = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    data.blockChainName =
      data.blockChainName === "polygon"
        ? "matic"
        : data.blockChainName === "klaytn"
        ? "klay"
        : "matic";
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getWithdrawTransactions(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function WithdrawTransactionsApprovalDecline
 * @returns /WithdrawTransactionsApprovalDecline
 */
export const withdrawTransApprovalDecline =
  (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      dispatch(startLoading());
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      const res = await ApiService.withdrawTransApprovalDecline(data, headers);
      dispatch(stopLoading());
      return res;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };

// export const getCoinList = (data) => async (dispatch, getState) => {
//   const { startLoading, stopLoading } = LoadingActions;

//   try {
//     dispatch(startLoading());
//     const { setHeaders } = PersistActions;
//     const headers = await dispatch(setHeaders({ isFormdata: false }));
//     const res = await ApiService.withdrawTransApprovalDecline(data, headers);
//     dispatch(stopLoading());
//     return res;
//   } catch (error) {
//     dispatch(stopLoading());
//     throw error;
//   }
// };
