import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    balance: "0",
    account:{},
    userDetails: {},
    profileData:{},
    userLoggedIn:false,
    themeInfo:"",
  }, 
  reducers: { 
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action.payload;
    },
    setThemeInfo: (state, action) => {
      state.themeInfo = action.payload;
    },
  },
});

export const { setUserDetails, setUserLoggedIn, setBalance, setAccount, setProfileData, setThemeInfo } = userSlice.actions;
export default userSlice.reducer;