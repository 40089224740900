import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class toster {
  success(massage) {
    toast.configure();
    toast.success(massage, { position: toast.POSITION.BOTTOM_RIGHT });

  }
  warn(massage) {
    toast.configure();
    toast.warn(massage, { position: toast.POSITION.BOTTOM_RIGHT });
  }
  error(massage) {
    toast.configure();
    toast.error(massage, { position: toast.POSITION.BOTTOM_RIGHT });
  }
}

export const toasts = new toster();
