import Enviroments from "../_constants/Enviroment";
import { fetch } from "./Fetch";
const { API_HOST } = Enviroments;

const nftFeeList = (data,headers) =>
  fetch(
    "get", 
    `${API_HOST}/api/v1/admin/fee/list/10/1`,
     {},
      headers);

const setNftFee = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/fee/add`, data, headers);

const updateNftFee = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/fee/update`, data, headers);

const adminFee = {
  nftFeeList,
  setNftFee,
  updateNftFee,
};

export default adminFee;
