import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { apiCallGet, apiCallPost } from "../../components/Axios/Axios";
//import { apiCallGet, apiCallPost } from "../../components/layout/Axios/Axios";

export const uploadImageVideoFile = async (fileName, fileObj) => {
  try {
    const formData = new FormData();
    formData.append(fileName, fileObj);
    const res = await apiCallPost(
      `users/api/v1/image`,
      formData,
      {},
      "toastOn"
    );
    if (res) {
      if (res.message.location.length > 0) {
        return res.message.location;
      } else {
        return false;
      }
    }
  } catch (error) {
    throw error;
  }
};
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const handleUpload = async (fileList, setLoader) => {
  setLoader !== undefined && setLoader(true);
  const d = fileList.map(
    (element, index) =>
      new Promise((resolve, reject) => {
        uploadImageVideoFile(`img${index}`, element.url)
          .then((res) => {
            if (res) {
              resolve({ type: element.type, url: res, key: element.key });
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      })
  );
  let data = await Promise.all(d);
  setLoader !== undefined && setLoader(false);
  return data;
};

export const getImageUrl = (res, key) => {
  let url = res
    .filter((element) => element.key === key)
    .map((elem) => elem.url);
  return url[0];
};

export const userProfile = async () => {
  const results = await apiCallGet("users/api/v1/profile");
  return results;
};

export function limitCharacters(text, count) {
  return text.length > count ? text.slice(0, count) + "..." : text;
}

const noExponents = function (num) {
  var data = String(num).split(/[eE]/);
  if (data.length == 1) return data[0];

  var z = "",
    sign = num < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^\-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};
export function calculateBal(bal) {
  let balll = bal > 0 ? (bal / 100000000).toFixed(8) : bal / 100000000;
  let ball = balll.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
  return noExponents(ball);
}
export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export function titleCase(str) {
  var splitStr = str?.toLowerCase()?.split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}
