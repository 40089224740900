import { addValidator } from "redux-form-validators";

export var validateUrls = addValidator({
  validator: function (options, value, allValues) {
    const reg = new RegExp("^(http|https)://", "i");
    if (!value.match(reg)) {
      return {
        defaultMessage: "Please include https."
      };
    }
  }
});