import { fetch } from "./Fetch";
import Enviroments from "../_constants/Enviroment";

const { API_HOST } = Enviroments;

const getSellReport = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/nft/sold/${data.limit}/${data.page}`,
    {},
    headers
  );

const SellReportService = {
  getSellReport,
};

export default SellReportService;
