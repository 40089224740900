import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  Button,
  Select,
  message,
  Divider,
  Space,
  Typography,
  Switch,
} from "antd";
// import { Swapicon, UploadIcon } from "../../../assets/images/svg/SvgImages";
import "./createNftFrom.scss";
// import { apiCallGet, apiCallPost } from "../../layout/Axios/Axios";
import { useSelector } from "react-redux";
// import CreateCollection from "../../layout/CreateCollection/CreateCollection";
import TextArea from "antd/lib/input/TextArea";
import globalicon from "../../../Assets/image/globalicon.svg";
import { PlusOutlined } from "@ant-design/icons";
// import { getImageUrl, handleUpload } from "../../../assets/data/utils";
import PropertiesModal from "./PropertiesModal/PropertiesModal";
import MainLayout from "../../../components/HOC/MainLayout";

const CreateNft = () => {
  /* -------------------------------------------------Hooks--------------------------------------------------*/
  const [form] = Form.useForm();
  const { Option } = Select;

  /* -------------------------------------------------States--------------------------------------------------*/
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collectionData, setCollectionData] = useState(false);
  const [location, setLocation] = useState("");
  const [locationData, setLocationData] = useState("");
  const [blockChainData, setBlockChainData] = useState([]);
  const [ownerCollectionData, setOwnerCollectionData] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState([]);

  /* -------------------------------------------------Redux States--------------------------------------------------*/

  const userId = useSelector(
    (state) => state?.user?.profileData?.userDetails?.userId
  );

  /* -------------------------------------------------Functions-------------------------------------------------*/

  const profile = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    fileList,
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const validateMessages = {
    required: "${name} is required!",
    types: {
      email: "${name} is not a valid email!",
      name: "${name} mmmm",
      publicEthAddress: "${name} publicEthAddress",
    },
  };
  const onFinish = async (values) => {
 
    console.log("Success:", values);
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const [isModalVisibled, setIsModalVisibled] = useState(false);

  const showedModal = () => {
    setIsModalVisibled(true);
  };

  const handleOkay = () => {
    setIsModalVisibled(false);
  };

  const handleCanceled = () => {
    setIsModalVisibled(false);
  };
  return (
    <>
      <MainLayout className="createNFTFrom">
        <div className="container explore_area createNft_area">
          <div className="new__creater">
            <div className="sub_title">
              <h2>Create NFT</h2>
              {/* <p>
                    Important: Your item will be automatically listed when
                    created.
                  </p> */}
            </div>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={validateMessages}
              className="fv-form"
            >
              <Row gutter={25}>
                <Col lg={12} sm={24} xs={24}>
                  <div className="new__creater__form">
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input placeholder="Item Name" />
                    </Form.Item>

                    <div className="FormGroup">
                      <h4>External link</h4>
                      <Form.Item
                        label="FanVerse will include a link to this URL on this item's detail page, so that users can click to learn more about it"
                        name="externalLink"
                        rules={[
                          { required: true, message: "Please input your url!" },
                        ]}
                      >
                        <div className="globalUrl">
                          <Input placeholder="www.website.com" />
                          <span>
                            <img src={globalicon} alt="icon" />
                          </span>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="FormGroup">
                      <h4>Description</h4>
                      <Form.Item
                        label="The description will be included on the item's detail page underneath its image. Markdown syntax is supported"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Description!",
                          },
                        ]}
                      >
                        <TextArea rows={4} placeholder="Insert description" />
                      </Form.Item>
                    </div>

                    <div className="FormGroup">
                      <h4>Collection</h4>

                      <Form.Item
                        label="Select a collection for your NFT"
                        name="collectionId"
                        rules={[
                          {
                            required: true,
                            message: "Please select a collection!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select collection"
                          optionFilterProp="children"
                        >
                          <Option value="1">Select category</Option>
                          <Option value="13">Closed</Option>
                          <Option value="14">Communicated</Option>
                          <Option value="4">Identified</Option>
                          <Option value="5">Resolved</Option>
                          <Option value="6">Cancelled</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="FormGroup">
                      <h4>Supply</h4>
                      <Form.Item
                        label="The number of items that can be minted. No gas cost to you!"
                        name="supply"
                        rules={[
                          {
                            required: true,
                            message: "Please input your supply!",
                          },
                        ]}
                      >
                        <Input placeholder="Insert supply" />
                      </Form.Item>
                    </div>

                    <div className="SwitchToggle d-flex align-items-center justify-content-between">
                      <div className="text">
                        <h4>Explicit & Sensitive Content</h4>
                        <p>Set this item as explicit and sensitive content</p>
                      </div>
                      <Form.Item valuePropName="checked" name="sensitiveContent">
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={12} sm={24} xs={24}>
                  <div className="new__creater__form rightSideProperties">
                    <Form.Item
                      name="upload"
                      label="Image, Video, Audio, or 3D Model"
                      className="upload_img"
                      valuePropName="fileList"
                      extra="JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV. Max: 100 MB"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        name="logo"
                        maxCount={1}
                        {...profile}
                        beforeUpload={(file) => {
                          setFileList([...fileList, { url: file, key: "logo" }]);
                          return false;
                        }}
                        listType="picture"
                      >
                        <Button>Upload picture</Button>
                      </Upload>
                    </Form.Item>
                    <div className="SwitchToggle d-flex align-items-center justify-content-between">
                      <div className="text">
                        <h4>Properties</h4>
                        <p>Textual traits that show up as rectangles</p>
                      </div>
                      <span onClick={showedModal} className="plusBtn">
                        <PlusOutlined />
                      </span>
                      <PropertiesModal isModalVisible={isModalVisibled} handleOk={handleOkay} handleCancel={handleCanceled} />
                    </div>

                  </div>
                </Col>
                {/* <Outlet /> */}
                <Col lg={24} sm={24} xs={24}>
                  <div className="cncl_buttons">
                    <div className="new__creater__form__submit__data">
                      <Button type="primary" htmlType="submit">
                        Create
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {/* {isModalVisible && (
        <CreateCollection
          isModalVisible={isModalVisible}
          setCollectionData={setCollectionData}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )} */}
      </MainLayout>
    </>
  );
};

export default CreateNft;
