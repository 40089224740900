import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ArtistService from "../../Services/artist.service";
import BrandService from "../../Services/brand.service";
// import collection from "../_reducers/brand.reducer";
import Types from "./_types";

export const saveArtist = (payload) => ({
  type: Types.SAVE_ARTIST,
  payload,
});

/**
 * @function getArtist
 * @param {{ page, limit }} data
 * @returns /getArtist/limit/page
 */
export const getArtist = (data) => async (dispatch, getState) => {
  console.log(data, "--data in getArtist");
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.getArtist(data, headers);
    console.log(res, headers, "---response in get artist action");
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

// Campaign action stump up
export const getCampaign = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.getCampaign(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getArtistById
 * @param {{ id }} data
 * @returns get particular collection
 */
export const getArtistById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.getArtistById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      data.isFeatured = data.isFeatured === 0 ? false : true;
      dispatch(saveArtist({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createArtist
 * @param {{ logo, banner, name, description }} data
 * @returns /createArtist
 */

export const createArtist = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    let {
      fullname,
      email,
      password,
      bio,
      role_type,
      profileImage,
      coverImage,
      username,
      isFeatured,
      brandId,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    let profileForm = new FormData();
    profileForm.append("file", profileImage);
    let profileImages = await BrandService.uploadBrandArtistImage(
      profileForm,
      headers
    );
    if (!profileImages) return toast.info("Error: Uploading failed");
    let coverForm = new FormData();
    coverForm.append("file", coverImage);
    let coverImages = await BrandService.uploadBrandArtistImage(
      coverForm,
      headers
    );
    if (!coverImages) return toast.info("Error: Uploading failed");

    let data2 = {
      fullname: fullname,
      email: email,
      password: password,
      bio: bio,
      role_type: role_type,
      profileImage: profileImages?.data?.message?.location,
      coverImage: coverImages?.data?.message?.location,
      username: username,
      isFeatured: isFeatured,
      brandId: brandId,
    };
    const res = await BrandService.createBrand(data2, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateArtist
 * @param {{ logo, banner, name, description }} data
 * @returns /createBrand
 */
export const updateArtist = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      id,
      profileImage,
      coverImage,
      isFeatured,
      email,
      bio,
      fullname,
      brandId,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (typeof profileImage !== "string") {
      profileImage = await dispatch(uploadFile(profileImage));
      if (!profileImage) return toast.info("Error: Uploading failed");
    }

    if (typeof coverImage !== "string") {
      coverImage = await dispatch(uploadFile(coverImage));
      if (!coverImage) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      profileImage,
      coverImage,
      isFeatured,
      email,
      bio,
      fullname,
      brandId,
    };
    const res = await ArtistService.updateArtist(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const deleteArtistById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.deleteArtistById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      // data.isFeatured = data?.isFeatured === 0 ? false : true;
      dispatch(saveArtist({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const blockuserById = (payload) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.blockuserById(payload, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      // data.isFeatured = data?.isFeatured === 0 ? false : true;
      let msg =
        payload.isActive === 0
          ? "User Successfully Blocked"
          : "User Successfully Unblocked";
      toast.success(msg);

      dispatch(saveArtist({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

// Nft Campain action

export const getNftCampaign = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.getNftCampaign(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getNfts = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ArtistService.getNfts(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
