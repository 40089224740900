import React, { Children, useState } from "react";
import "./mainLayout.scss";
import { useSelector } from "react-redux";
import LeftSideBlock from "../../components/LeftSide/LeftSideBlock";
import Header from "../../components/Header/Header";
import Mobileheader from "../../components/Mobileheader/Mobileheader";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import Sidebar from "../SideBar/Sidebar";

const MainLayout = ({ children, className }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const persist = useSelector((state) => state.persist);
  const { isLeftbar } = persist;

  return (
    <>
      <div className={`mainBlock `}>
        {/* ${toggleSidebar ? "" : "smallSidebarWrap"} */}
        {/* <LeftSideBlock isLeftbar={isLeftbar} setToggleSidebar={setToggleSidebar} toggleSidebar={toggleSidebar} /> */}
        <div className={`rightSection`}>
          <Header isLeftbar={isLeftbar} />
          {/* <div className="for_mobile">
            <Mobileheader />
          </div> */}
          <div className="MainComponent">
            <Sidebar />
            <Container fluid className={`mainContainerWrap hello ${className}`}>
              {children}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MainLayout);
