import { Row } from "antd";
import React from "react";

const HeaderCreator = ({ userData, banner }) => {
  return (
    <Row>
      <div className="creatorBanner">
        {userData && (
          <img
            style={{ width: "100%" }}
            src={userData?.coverImage || banner}
            alt="banner"
          />
        )}
      </div>
    </Row>
  );
};

export default HeaderCreator;
