import Enviroments from "../_constants/Enviroment";
import { fetch } from "./Fetch";
const { API_HOST } = Enviroments;

const transactionFeeList = (headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/campaign/campaignsSettings`,
    {},
    headers
  );

const setTransactionFee = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/admin/api/v1/nft/add-transactionfees`,
    data,
    headers
  );

const updateTransactionFee = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/campaign/updateCampaignsSettings`,
    data,
    headers
  );

const transactionFee = {
  transactionFeeList,
  setTransactionFee,
  updateTransactionFee,
};

export default transactionFee;
