import React, { useState, useEffect, useCallback } from "react";
import MainLayout from "../../../components/HOC/MainLayout";
import { withRouter } from "react-router";
import ListFaq from "./List/FaqList";
import { RafflesActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import GlobalVariables from "../../../_constants/GlobalVariables";

const Faqs = () => {
  const dispatch = useDispatch();
  const [raffles, setRaffles] = useState({ count: 0, rows: [] });
  const [page, setPage] = useState(1);

  return (
    <>
      <MainLayout>
        <ListFaq
          raffles={raffles}
          setPage={setPage}
          page={page}
          setRaffles={setRaffles}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(Faqs);
