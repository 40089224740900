import React, { useState } from "react";
import "./ListforSale.scss";
import {
  Row,
  Col,
  Select,
  InputNumber,
  Space,
  DatePicker,
  Form,
  // Menu, 
} from "antd";
import { Tabs } from "antd";
// import listsale_img from "../../../assets/images/listsale_img.png";
import vector from "../../../Assets/image/verifyLogo.png";
import list_dataicon from "../../../Assets/image/list_dataicon.png";
import list_quesicon from "../../../Assets/image/list_quesicon.png";
// import { FvButton } from "../../ui";
import { useParams } from "react-router-dom";
// import { apiCallGet, apiCallPost } from "../../layout/Axios/Axios";
import Fixed from "./Fixed";
import Auction from "./Auction";
import moment from "moment";
import FvButton from "../../../components/button/FvButton";
import MainLayout from "../../../components/HOC/MainLayout";
import nftDetailImg from "../../../images/listsale_img.png";

const ListforSale = () => {
  // <<<<<<< HEAD
  //   const { TabPane } = Tabs;
  // =======
  /* -------------------------------------------------Hooks--------------------------------------------------*/
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm();
  const params = useParams();
  // const navigate = useNavigate();
  /* -------------------------------------------------States--------------------------------------------------*/
  const [nftDetail, setNftDetail] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  /* -------------------------------------------------Functions--------------------------------------------------*/

  const onFinish = (values) => {
    // const data = {
    //   ...values,
    //   isListed: true,
    //   nftId: params.id,
    //   nftType: activeTab === "1" ? "SALE" : "AUCTION",
    //   fromDate: moment(values.duration[0]._d).format("yyyy-mm-DD"),
    //   toDate: moment(values.duration[1]._d).format("yyyy-mm-DD"),
    // };
    // delete data["duration"];
    // listItemApi(data);
  };
  const onFinishFailed = (errorValues) => {
    console.log(errorValues);
  };

  /* -------------------------------------------------APIs--------------------------------------------------*/
  // const getNftDetail = useCallback(async () => {
  //   try {
  //     const results = await apiCallGet(
  //       `users/api/v1/nft/get_nft_details/${params.id}`
  //     );
  //     if (!results) {
  //       navigate("/PageNotFound");
  //     }
  //     setNftDetail(results?.data[0]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // const listItemApi = async (data) => {
  //   try {
  //     const results = await apiCallPost(`users/api/v1/nft/listed_nft`, data);
  //     console.log({ results });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /* -------------------------------------------------Use Effects--------------------------------------------------*/
  // useEffect(() => {
  //   setTimeout(() => {
  //     getNftDetail();
  //   }, 100);
  // }, [getNftDetail]);

  return (
    <MainLayout className="ListforSale">
    <div className="listsale_page py-32">
      <div className="containerss">
        <Row gutter={{ xs: 25, md: 30, xl: 40 }}>
          <Col xl={12} lg={12} xs={24}>
            <div className="list_left_img">
              <img src={nftDetailImg} alt="img" />
            </div>
          </Col>
          <Col xl={12} lg={12} xs={24} className="d-flex">
            <div className="list_right_area">
              <div className="list_inner_text">
                <div className="list_heading">
                  <h3>
                    Azuki Teamverse{" "}
                    <span>
                      <img src={vector} alt="vector_img" />
                    </span>
                  </h3>
                  <h2>{nftDetail?.title}</h2>
                </div>
                <div className="input_data d-flex justify-content-between">
                  <div className="list_info">
                    <p>Current Price</p>
                    <h4>
                      0.4433 ETH{" "}
                      <span>
                        <img src={list_dataicon} alt="img" /> ETH
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="tab_data">
                  <h3>Sales Type</h3>
                  <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    // initialValues={}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validateMessages}
                    className="fv-form"
                  >
                    <Tabs
                      defaultActiveKey="1"
                      className="active"
                      onChange={(e) => setActiveTab(e)}
                    >
                      <TabPane tab="Fixed Price" key="1">
                        <Fixed />
                      </TabPane>
                      <TabPane tab="Auction" key="2">
                        <Auction />
                      </TabPane>
                    </Tabs>
                    <div className="fan_creator">
                      <div className="fan_innerdata">
                        <div className="tab_input">
                          <p>
                            Creators Royalties{" "}
                            <span>
                              <img src={list_quesicon} alt="img" /> 0.25%
                            </span>
                          </p>
                        </div>
                        <div className="tab_input">
                          <p>
                            FanVerse{" "}
                            <span>
                              <img src={list_quesicon} alt="img" /> 2%
                            </span>
                          </p>
                        </div>
                        <FvButton
                          className="w-100 mt-10"
                          text={
                            nftDetail?.isListed === "0"
                              ? "List Item"
                              : "Unlist Item"
                          }
                          type="primary"
                          htmlType="submit"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </MainLayout>
  );
};

export default ListforSale;
