import React from "react";
import { Button, Image, Modal, Table } from "semantic-ui-react";
import "./UserDetails.scss";

const UserDetails = ({ setOpen, open, userDetalisData, userCategoryDetalisData }) => {
  const {
    about_me,
    // comment,
    // createdAt,
    email,
    facebookId,
    //fullname,
    instagramId,
    linkedinId,
    mobile_no,
    dial_code,
    //status,
    twitterId,
    username,
    //coverImage,
    profileImage,
  } = userDetalisData;
  console.log("mcbshc", userDetalisData)
  const {
   // category_name,
  } = userCategoryDetalisData;
console.log(profileImage,"hardeep");
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>User Details</Modal.Header>
      <Modal.Content>
        <div className="modalImageWrap">
          {/* <div className="coverImage">
            <p>Cover Image</p>
            <Image
              src={
                coverImage ||
                "https://react.semantic-ui.com/images/wireframe/image.png"
              }
              wrapped
            />
          </div> */}
          <div className="profileImage">
            <p>Profile Image</p>
            <Image
              src={
                profileImage ||
                "https://react.semantic-ui.com/images/wireframe/image.png"
              }
              wrapped
              referrerPolicy="no-referrer"
            />
          </div>
        </div>
        <Modal.Description>
          <Table definition style={{ overflow: 'auto', maxWidth: 'inherit', }}>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>User Name : </Table.Cell>
                <Table.Cell>{username}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Email : </Table.Cell>
                <Table.Cell>
                  <a href={`mailto:+${email}`} target="email">
                    {email}
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Mobile Number : </Table.Cell>
                <Table.Cell>{dial_code}{" "}{mobile_no}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Category Name : </Table.Cell>
                <Table.Cell>
                  {userCategoryDetalisData.map((item) => (
                    <>{`${item.category_name}, `}</>
                  ))}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>About Me : </Table.Cell>
                <Table.Cell className="checkAbout">{about_me}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Twitter Link : </Table.Cell>
                <Table.Cell>
                  <a href={twitterId} target="_blank">
                    {twitterId}
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Facebook Link : </Table.Cell>
                <Table.Cell>
                  <a href={facebookId} target="_blank">
                    {facebookId}
                    {}
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Instagram Link : </Table.Cell>
                <Table.Cell>
                  <a href={instagramId} target="_blank">
                    {instagramId}
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>LinkedIn Link : </Table.Cell>
                <Table.Cell>
                  <a href={linkedinId} target="_blank">
                    {linkedinId}
                  </a>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} primary>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserDetails;
