import React, { useCallback, useEffect, useState } from "react";

import { AdminFees } from "../../../redux/_actions";
import AdminList from "./AdminList.jsx";
import GlobalVariables from "../../../_constants/GlobalVariables";
import MainLayout from "../../../components/HOC/MainLayout";
import { apiCallPost } from "../../../components/Axios/Axios";
import { useDispatch } from "react-redux";

function AdminFee() {
  const [feeData, setFeeData] = useState([]);

  const dispatch = useDispatch();

  const getNftFeeList = async () => {
    const { nftFeeList } = AdminFees;
    const nftFee = await dispatch(nftFeeList());
    const rowData = nftFee.data?.data?.data;
    setFeeData(rowData);
    console.log('first' , rowData)
  };
  
  useEffect(() => {
    getNftFeeList();
  }, []);
  return (
    <MainLayout>
      <AdminList
        feeData={feeData}
        getNftFeeList={getNftFeeList}
        //deleteFee={deleteFee}
      />
    </MainLayout>
  );
}

export default AdminFee;
