import Types from "../_actions/_types";

const initialState = {
  tax: {},
};

const tax = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_TAX_DETAILS: {
      
      return { ...state, tax: payload };
    }
    default:
      return state;
  }
};

export default tax;
