import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Pagination, Table, Icon, Confirm } from "semantic-ui-react";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import WithdrawTransaction from "../WithdrawTransaction";
import moment from "moment";
import Enviroments from "../../../../_constants/Enviroment";

const { BLOCKCHAIN_PASSWORD, KLAYTN_URL, MATIC_URL } = Enviroments;

const ListWithdrawTransaction = ({
  getWithdrawTransactions,
  withdrawTransaction,
  approvalDecline,
  coin,
  setActiveIndex,
  activeIndex,
  totalPage,
}) => {
  const [open, setOpen] = useState(false);
  const [approvalDeclineData, setApprovalDeclineData] = useState({});

  const dispatch = useDispatch();
  console.log("URLS", KLAYTN_URL, MATIC_URL);
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActiveIndex(page);
    getWithdrawTransactions();
    getWithdrawTransactions({ page, limit: GlobalVariables.PAGE_LIMIT });
  };
  return (
    <>
      {open && (
        <Confirm
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={() => {
            setOpen(false);
            approvalDecline(approvalDeclineData);
          }}
        />
      )}
      <div className="pageHeading">
        <h3>Transaction's</h3>
        <p>List of Transaction History</p>
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="1">Serial no.</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Amount</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">To</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">From</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Txid</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Status</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Date</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {withdrawTransaction && withdrawTransaction.length > 0 ? (
            withdrawTransaction.map((item, i) => (
              <Table.Row key={i}>
                <Table.Cell collapsing colSpan="1" textAlign="left">
                  {(activeIndex ? activeIndex : 1) * 10 - 10 + i + 1}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {item.amount ? 45 : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {item.from_address
                    ? `${item?.from_address.substr(0, 10)}...`
                    : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {item.to_address
                    ? `${item?.to_address.substr(0, 10)}...`
                    : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {item.tx_id ? (
                    <a
                      target="_new"
                      href={
                        coin === "KLAYTN"
                          ? `${KLAYTN_URL}${item.tx_id}`
                          : `${MATIC_URL}${item.tx_id}`
                      }
                    >
                      {item?.tx_id.substr(0, 10)}...
                    </a>
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {item.status === 0
                    ? "PENDING"
                    : item.status === 2
                    ? "CONFIRMED"
                    : item.status === 3
                    ? "UNCONFIRMED"
                    : "PROCESSING"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {item.created_at
                    ? moment(item.created_at).format("DD-MM-YYYY HH:MM:SS")
                    : "N/A"}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {item.status === 0 ? (
                    <>
                      <Button
                        primary
                        onClick={() => {
                          let data = {
                            withdraw_id: item.id,
                            isApproved: "approved",
                            password: BLOCKCHAIN_PASSWORD,
                            coin: coin,
                          };
                          setOpen(true);
                          setApprovalDeclineData(data);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        color="red"
                        onClick={() => {
                          let data = {
                            withdraw_id: item.id,
                            isApproved: "declined",
                            password: BLOCKCHAIN_PASSWORD,
                            coin: coin,
                          };
                          setOpen(true);
                          setApprovalDeclineData(data);
                        }}
                      >
                        Decline
                      </Button>
                    </>
                  ) : item.status === 2 ? (
                    <>Approved</>
                  ) : item.status === 3 ? (
                    <>Declined</>
                  ) : (
                    <>Processing</>
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Record Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {console.log(withdrawTransaction, "here")}
      {WithdrawTransaction && totalPage > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(totalPage) / GlobalVariables.PAGE_LIMIT) || 1
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ListWithdrawTransaction;
