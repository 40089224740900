import Enviroments from "../_constants/Enviroment";
import { fetch } from "./Fetch";

const { API_HOST } = Enviroments;

const login = (data, headers = {}) =>
  fetch("post", `${API_HOST}/api/v1/admin/auth/login`, data, headers);

const getCollections = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/collection/list/${data.limit}/${data.page}`,
    {},
    headers
  );
const createCollection = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/collection/add`, data, headers);
const getCollectionById = (data, headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/collection/${data.id}`, {}, headers);
const updateCollection = (data, headers) =>
  fetch("put", `${API_HOST}/admin/api/v1/collection/${data.id}`, data, headers);

const createNft = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/nft/add`, data, headers);


const getNftReports = (data, headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/reporting/listing/10/1`, {}, headers);

const getPhotoGrapherReports = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/reporting/photographer-list/10/1`,
    {},
    headers
  );

const getNftById = (data, headers) =>
  fetch("get", `${API_HOST}/nft/api/v1/nft/${data?.id}`, {}, headers);

const createBanner = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/banner/add`, data, headers);

const getBanner = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/banner/list/${data.limit}/${data.page}`,
    {},
    headers
  );
     // Tax Benefit
  const getTax = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/tax-benefits/list/${data.limit}/${data.page}`,
    {},
    headers
  );
    
  const createTax = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/tax-benefits/add`, data, headers);

  const updateTaxStatus = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/tax-benefits/update`,
    data,
    headers
  );

  const getTaxById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/tax-benefits/getById/${data?.id}`,
    {},
    headers
  );

  // end tax


  // Media Coverage
  
  const getMedia = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/mediaCoverages/list/${data.limit}/${data.page}`,
    {},
    headers
  );

  const createMedia = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/mediaCoverages/add`, data, headers);

  const getMediaById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/mediaCoverages/getById/${data?.id}`,
    {},
    headers
  );
  const updateMediaStatus = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/mediaCoverages/update`,
    data,
    headers
  );
 
  // end Media

  // Quotes api
  const getQuotes = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/quotes/list/${data.limit}/${data.page}`,
    {},
    headers
  );
  const createQuotes = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/quotes/add`, data, headers);

  const getQuotesById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/quotes/getById/${data?.id}`,
    {},
    headers
  );
   
  const updateQuotesStatus = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/quotes/update`,
    data,
    headers
  );
  //end
     
  // Disable enable withdraw 
  
  const getWithdrawStatus = (data, headers) =>
  fetch("get", `${API_HOST}/api/v1/admin/wallet/getAssets/1`, {}, headers);

  const updateWithdrawStatus = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/wallet/updateAssets`,
    data,
    headers
  );
  //end

const getExtraTypes = (data, headers) =>
  fetch("get", `${API_HOST}/nft/api/v1/extras/extra_list`, {}, headers);
const createExtraType = (data, headers) =>
  fetch("post", `${API_HOST}/nft/api/v1/extras/extras_add`, data, headers);

const deleteExtraType = (data, headers) =>
  fetch(
    "delete",
    `${API_HOST}/nft/api/v1/extras/delete/${data}`,
    data,
    headers
  );
const getExtraTypeById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/nft/api/v1/extras//extra_list_by_id/${data.id}`,
    {},
    headers
  );

const updateExtraType = (data, headers) =>
  fetch("put", `${API_HOST}/nft/api/v1/extras/${data.id}`, data, headers);

const getBannerById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/banner/getById/${data.id}`,
    {},
    headers
  );
const updatebanner = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/banner/update`, data, headers);
const deleteBanner = (data, headers) =>
  fetch(
    "delete",
    `${API_HOST}/admin/api/v1/banner/delete/${data}`,
    data,
    headers
  );
const uploadImage = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/category`, data, headers);

const uploadBanner = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/banner`, data, headers);

const updateNftStatus = (data, headers) =>
  fetch("put", `${API_HOST}/admin/api/v1/nft/${data?.id}`, data, headers);

const uploadCsv = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/bulkMinting`, data, headers);
const uploadBulkImages = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/images`, data, headers);

const getErrorLogData = (headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/upload/bulkMinting/error-logs/10/1`,
    {},
    headers
  );

const getMintStatus = (headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/upload/bulkMinting/status`,
    {},
    headers
  );

const getBrandArtistList = (headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/brand-artist/list`, {}, headers);

const getCreatorList = (headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/brand-artist/list`, {}, headers);

const getRaffles = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/category/list/${data.limit}/${data.page}`,
    {},
    headers
  );

const updateRaffleStatus = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/category/update`,
    data,
    headers
  );

const createRaffles = (data, headers) =>
  fetch("post", `${API_HOST}/api/v1/admin/category/add`, data, headers);

const getRafflesById = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/category/getById/${data?.id}`,
    {},
    headers
  );

const deleteCategoryById = (id, headers) =>
  fetch(
    "delete",
    `${API_HOST}/api/v1/admin/category/delete/${id}`,
    {},
    headers
  );

const getBlockChainList = (headers) =>
  fetch("get", `${API_HOST}/nft/api/v1/blockchain/list`, {}, headers);

const getActiveCoinList = (headers) =>
  fetch("get", `${API_HOST}/wallet/admin/active-all-coins`, {}, headers);

const getRafflesNftList = (headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/raffle/drop_down_list`, {}, headers);

const getWithdrawTransactions = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/wallet/${data.coins}/admin/get_withdraw_transactions/${data.page}`,
    {},
    headers
  );

//AD = A: Aproved ,D:Decline
const withdrawTransApprovalDecline = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/wallet/withdrawApproveDecline`,
    data,
    headers
  );

const getMysteryBoxList = (data, headers) =>
  fetch(
    "get",
    `http://10.1.5.205:3444/admin/api/v1/mysterybox/mysteryboxes/${data.limit}/${data.page}`,
    {},
    headers
  );

const searchBar = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/user/user_filter`, data, headers);

const collection = (data, headers) =>
  fetch(
    "put",
    `${API_HOST}/admin/api/v1/collection/${data.id}`,
    data.status,
    headers
  );

const nftUpdateUser = (data, headers) =>
  fetch(
    "put",
    `${API_HOST}/admin/api/v1/nft/${data.id}`,
    { isActive: data.status.isActive ? 1 : 4, creator: data.creator },
    headers
  );

const updateLanguageSetting = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/admin/api/v1/language/update_status`,
    data,
    headers
  );

const getLanguageSetting = (data, headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/language/status`, {}, headers);

const creatorUserCount = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/user/get_user_and_creator_count`,
    {},
    headers
  );
const getFaqDetails = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/api/v1/admin/faq/getFaq/${data?.id}`,
    {},
    headers
  );

const ApiService = {
  login,
  getCollections,
  uploadImage,
  createCollection,
  getCollectionById,
  updateCollection,
  createNft,
  getNftReports,
  getPhotoGrapherReports,
  createBanner,
  getBanner,
  getExtraTypes,
  getExtraTypeById,
  createExtraType,
  deleteExtraType,
  updateExtraType,
  getBannerById,
  updatebanner,
  updateNftStatus,
  uploadCsv,
  deleteBanner,
  getErrorLogData,
  uploadBulkImages,
  getMintStatus,
  getNftById,
  getBrandArtistList,
  getCreatorList,
  getRaffles,
  updateRaffleStatus,
  createRaffles,
  getRafflesById,
  getBlockChainList,
  getActiveCoinList,
  getWithdrawTransactions,
  withdrawTransApprovalDecline,
  getRafflesNftList,
  getMysteryBoxList,
  deleteCategoryById,
  uploadBanner,
  searchBar,
  collection,
  nftUpdateUser,
  updateLanguageSetting,
  getLanguageSetting,
  creatorUserCount,
  getTax,
  createTax,
  updateTaxStatus,
  getTaxById ,
  getMediaById,
  getMedia,
  createMedia,
  updateMediaStatus,
  getQuotes,
  createQuotes,
  getQuotesById,
  updateQuotesStatus,
  getFaqDetails,
  updateWithdrawStatus,
  getWithdrawStatus,
};

export default ApiService;
