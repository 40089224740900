import { combineReducers } from "redux";
import loading from "./loading.reducer";
import { createBrowserHistory } from "history";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import persist from "./persist.reducer";
import collection from "./collection.reducer";
import category from "./category.reducer";
import banner from "./banner.reducer";
import brand from "./brand.reducer";
import artist from "./artist.reducer";
import nft from "./nft.reducer";
import addType from "./addType.reducer";
import tax from "./tax.reducer";
import media from "./media.reducer";
import quotes from "./quotes.reducer";

import faq from "./faq.reducer"

export const history = createBrowserHistory();

const appReducer = combineReducers({
  loading,
  persist,
  form: formReducer,
  collection: collection,
  banner: banner,
  addType: addType,
  brand: brand,
  artist: artist,
  category: category,
  nft: nft,
  tax:tax,
  media:media,
  quotes:quotes,
  faq: faq,
  router: connectRouter(history),
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
