import { LoadingActions, PersistActions } from ".";

import adminFee from "../../Services/adminFee.service";
import { toast } from "../../components/Toast/Toast";

export const nftFeeList = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    console.log("dispatch");
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await adminFee.nftFeeList(headers);
    console.log(res, "res23")
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const setNftFee = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    console.log("dispatch");
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await adminFee.setNftFee(data, headers);
    console.log(res, "res");

    //toast.success(msg);

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const updateNftFee = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    console.log("dispatch");
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await adminFee.updateNftFee(data, headers);
    console.log(res, "res");
    toast.success(res.data.message);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};



