import { fetch } from "./Fetch";
import Enviroments from "../_constants/Enviroment";

const { API_HOST } = Enviroments;

const getKyc = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/kyc/list/${data.limit}/${data.page}`,
    {},
    headers
  );

const changeKycStatus = (data, headers) =>
  fetch("put", `${API_HOST}/admin/api/v1/kyc/${data.userId}`, data, headers);

const KycService = {
  getKyc,
  changeKycStatus,
};

export default KycService;
