import { Button, Confirm, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { apiCallGet, apiCallPost } from "../../../components/Axios/Axios";

import GlobalVariables from "../../../_constants/GlobalVariables";
import { calculateBal } from "../../../Assets/data/utils";
import moment from "moment";
// import { KycActions } from "../../../../redux/";
import { useDispatch } from "react-redux";

export default function ViewDeposit({ UserDeposit, myKycActions }) {
  const [open, setOpen] = useState(false);
  const [openMain, setOpenMain] = useState(false);
  const [action, setAction] = useState({});
  const [userData, setUserData] = useState({});

  //const dispatch = useDispatch();

  function updateUser(newData) {
    setOpen(false);
    console.log("TAG", action);

    apiCallPost(`/api/v1/admin/campaign/update`, newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            setOpenMain(false);
            myKycActions({ page: 0, limit: GlobalVariables.PAGE_LIMIT });
          }
        }
      })
      .catch((err) => {});
  }

  function getUser() {
    apiCallGet(
      `api/v1/admin/user/findUserById/${UserDeposit.id}`,

      {},
      "toastOn"
    )
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            setUserData(res.data);
          }
        }
      })
      .catch((err) => {});
  }
  useEffect(() => {
    console.log("TAGrr", openMain);
    // getUser()
  }, [openMain]);

  //console.log("data", getUser())

  const modalElements = (
    <div className="user_details">
      <ul>
        <li style={{ color: "white" }}>
          <div>
            <b>txid : </b>{" "}
          </div>
          {UserDeposit.tx_id ? UserDeposit.tx_id : "N/A"}
          {/* {UserDetails.last_name ? UserDetails.last_name : "N/A"} */}
        </li>
        <li style={{ color: "white" }}>
          <div>
            {" "}
            <b>From :</b>{" "}
          </div>
          {UserDeposit.address_from ? UserDeposit.address_from : "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>To :</b>
          </div>{" "}
          {UserDeposit?.address_to ? UserDeposit?.address_to : "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Amount :</b>
          </div>{" "}
          {UserDeposit?.amount ? calculateBal(UserDeposit.amount) : "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Status : </b>
          </div>{" "}
          {UserDeposit.status == 0
            ? "PENDING"
            : UserDeposit.status == 2
            ? "CONFIRMED"
            : UserDeposit.status == 1
            ? "UNCONFIRMED"
            : "PROCESSING"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Created At :</b>
          </div>{" "}
          {UserDeposit?.created_at ? moment(UserDeposit?.created_at).format("MMMM Do YYYY") : "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Updated At :</b>
          </div>{" "}
          {UserDeposit?.updated_at ? moment(UserDeposit?.updated_at).format("MMMM Do YYYY") : "N/A"}
        </li>
      </ul>
    </div>
  );

  return (
    <div style={{ marginLeft: "30px" }}>
      <Confirm
        content={`Are you sure? You want to ${action.remarks} this Campaign!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          updateUser(action);
        }}
      />
      <Modal
        onClose={() => setOpenMain(false)}
        onOpen={() => setOpenMain(true)}
        open={openMain}
        trigger={<Button>View</Button>}
        header="Deposit Detail"
        content={modalElements}
        actions={[{ key: "done", content: "Done", primary: true }]}
      />
    </div>
  );
}
