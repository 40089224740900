import { Card, Row, Col } from "antd";
import React from "react";
import "./CardMyCollection.scss";
import iconCreator from "../../images/icon_creator.svg";
// import { useNavigate } from "react-router-dom";
import { HeartOutlined } from '@ant-design/icons';

const CardMyCollection = ({ creatorName, CreatorImage, onClick }) => {
  // const navigate = useNavigate();
  return (
    <Card
      hoverable 
      className="cardCollectionStyle"
      bordered={false}
      onClick={onClick}
    >
      <div className="ant-card-cover cardCollectionStyle__img">
        <img src={CreatorImage} alt={creatorName} />
        <span className="likeIcon"><HeartOutlined /></span>
      </div>
      <div className="ant-card-meta-detail cardCollectionStyle__details">
        <h3 className="cardCollectionStyle__details__creatorName">
          {creatorName} <img src={iconCreator} alt="Creator" />
        </h3>
        <Row className="creator_volume">
          <Col xs={12} className="creator_volume__itemLeft">
            <h4>Total Volume</h4>
            {/* <img src={currSymbol} alt={currSymbol} /> */}
            <p> {"0.00"}</p>
          </Col>
          <Col xs={12} className="creator_volume__itemRight text-right">
            <h4>Minted</h4>
            <p>{"0.00"}</p>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default CardMyCollection


