//import "../user.scss";
// import "../NftCampaignList.scss";

import { BrandActions, UserActions } from "../../../../redux/_actions";
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";

import React, { useEffect, useState } from "react";

import GlobalVariables from "../../../../_constants/GlobalVariables";
import ImageVideoModal from "../../../../components/Modal/ImageVideoModal";

import { limitCharacters, titleCase } from "../../../../Assets/data/utils";

import { withRouter } from "react-router";
import ViewCampaign from "../../Campaign/ViewCampaign";
import activeImg from "../../../../images/active.png";
import inactiveImg from "../../../../images/inactive.png";
import { apiCallPost } from "../../../../components/Axios/Axios";
import no_record from '../../../../Assets/image/no-record-found.png'

const ListNft = ({
  getNfts,
  campaign,
  handleSearch,
  handleDropdownChange,
  setNftFilters,
  nftFilters,
}) => {
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [artistId, setArtistId] = useState(null);
  // const [activeIndex, setActiveIndex] = useState(1);
  const [activeuserstatus, setActiveuserstatus] = useState(1);
  const [action, setAction] = useState({});

  const data = {
    // limit: 10,
    // page: 0,
    // fullname: "",
    // isType: "",
    // status: "",
    // email: "",
    // search: "",
    local_currency: "",
    campaign_type: "",
  };

  const [userFilter, setUserFilter] = useState(1);
  const [userCuFilter, setUserCuFilter] = useState(data);
  const [initialData, setInitialData] = useState({
    search: "",
    campaign_type: 1,
  });

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setNftFilters({ ...nftFilters, page: page });
  };

  const campaignOpt = [
    { key: "campaign_type", text: "CROWD FUNDING", value: "" },
    { key: "campaign_type", text: "NFT CAMPAIGN", value: "" },
  ];
  const transactionOptions = [
    { key: "local_currency", text: "USD", value: 1 },
    { key: "local_currency", text: "GBP", value: 3 },
  ];

  const nftType = [
    { key: "nft_type", text: "All", value: "" },
    { key: "nft_type", text: "Fixed", value: "fixed" },
    { key: "nft_type", text: "Auction", value: "auction" },
  ];
  // const userFilters = async (search, typeSearch) => {
  //   const data = {
  //     limit: 10,
  //     page: 1,
  //     //fullname: "",
  //     //isType: initialData.isType,
  //     //status: initialData.isType,
  //     //email: userFilter.email,
  //     //search: userFilter.search,
  //     local_currency: initialData.local_currency,
  //     campaign_type: initialData.campaign_type,
  //   };

  //   getNfts(data);
  // };

  // useEffect(() => {
  //   const filterId = setTimeout(() => {
  //     userFilters();
  //   }, 500);
  //   return () => clearTimeout(filterId);
  // }, [userFilter, initialData, selectedType]);

  function updateUser(newData) {
    setOpen(false);

    apiCallPost(`/api/v1/admin/nft/update`, newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            getNfts({
              page: 1,
              limit: GlobalVariables.PAGE_LIMIT,
              search: nftFilters.search,
            });
          }
        }
      })
      .catch((err) => { });
  }

  // let status = activeuserstatus == 0 ? "Block" : "Unblock";

  // let modelcontent = "Are You Sure You Want To " + status + " User?";
  // let payload = {
  //   userid: artistId,
  //   isActive: activeuserstatus,
  // };

  // function refreshPage() {
  //   window.location.reload(false);
  // }
  return (
    <>
      <Confirm
        content={`Are you sure? You want to ${action.remarks} this Campaign!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          updateUser(action);
        }}
      />
      <div className="pageHeading d-flex">
        <h3>NFT</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Form.Field className="search_field">
            <input
              placeholder="Search"
              className="search"
              value={nftFilters?.search}
              onChange={(e) => handleSearch(e)}
            />
          </Form.Field>
          <Dropdown
            className="select_dropdown"
            style={{ marginLeft: "20px" }}
            placeholder={nftType[0]?.text}
            selection
            options={nftType}
            value={nftFilters?.saleType}
            onChange={(e, role) => {
              let selectedValue = role?.value;
              handleDropdownChange(selectedValue);
            }}
          />

          {/* <Dropdown
            className="select_dropdown"
            placeholder="CROWD FUNDING"
            selection
            options={campaignOpt}
            value={
              userFilter.filter === "campaign_type"
                ? userFilter.value
                : initialData.campaign_type
            }
            onChange={(e, data) => {
              //setUserFilter({ ...userFilter, campaign_type: data.value });
              setInitialData({ ...initialData, campaign_type: data.value });
            }}
          />
          <Dropdown
            className="select_dropdown"
            placeholder="Select Currency"
            selection
            options={transactionOptions}
            value={
              userCuFilter.filter === "local_currency"
                ? userCuFilter.value
                : initialData.local_currency
            }
            onChange={(e, data) => {
              //setUserCuFilter({ ...userCuFilter, local_currency: data.value });
              setInitialData({ ...initialData, local_currency: data.value });
            }}
          /> */}
        </div>
      </div>

      <div className="pageHeading">
        {/* <p>List Of {`${initialData?.campaign_type === 1 ? "Crowd Funding" : "NFT Campaign"}`}</p> */}
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Serial No.</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Title</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Image</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Collection</Table.HeaderCell>
            {/* <Table.HeaderCell colSpan="2">Fundraiser Id</Table.HeaderCell> */}
            <Table.HeaderCell colSpan="2">Description</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Sale Type</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaign && campaign.count > 0 && campaign?.rows?.length > 0 ? (
            campaign?.rows?.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {10 * nftFilters?.page + i - 9}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.title ? limitCharacters(row.title, 20) : ""}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <ImageVideoModal
                    fileType={row?.fileType}
                    logo={row.image ? row.image : ""}
                    isLogo={true}
                  />
                </Table.Cell>

                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                 {row?.wallet_id ? limitCharacters(row.wallet_id, 20) : "N/A"}
                </Table.Cell>*/}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.category ? limitCharacters(row.category, 20) : ""}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.description ? limitCharacters(row.description, 20) : ""}
                </Table.Cell>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.saleType ? titleCase(row.saleType) : ""}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  {moment(row?.date_birth).format("MMMM Do YYYY") || "N/A"}
                </Table.Cell> */}

                {/* <Table.Cell collapsing colSpan="2" textAlign="left">
                  <div
                    className="modalData"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row?.is_deleted == 0 && (
                      <Button.Group style={{ marginTop: 5 }}>
                        <Button
                          negative
                          onClick={() => {
                            setOpen(true);
                            setAction({
                              id: row.id,
                              fundraiser_id: row.fundraiser_id,
                              is_deleted: row.is_deleted == 1 ? 0 : 1,
                              remarks:
                                row.is_deleted == 1 ? "Publish" : "Delete",
                            });
                          }}
                        >
                          {row.is_deleted == 1 ? "Publish" : "  Delete"}
                        </Button>
                        <span style={{ marginLeft: "10px" }}></span>
                      </Button.Group>
                    )}

                    <img
                      style={{
                        marginLeft: "10px",
                        height: "35px",
                        width: "35px",
                        cursor: "pointer",
                      }}
                      primary
                      alt={"img"}
                      src={row.is_active == 1 ? activeImg : inactiveImg}
                      onClick={() => {
                        setOpen(true);
                        setAction({
                          id: row.id,
                          fundraiser_id: row.fundraiser_id,
                          is_active: row.is_active == 1 ? 0 : 1,
                          remarks: row.is_active == 1 ? "Inactive" : "Active",
                        });
                      }}
                    />
                  </div>
                </Table.Cell> */}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <div
                    className="modalData"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      title={row.isActive == 1 ? "Inactive" : "Active"}
                      style={{
                        marginLeft: "10px",
                        height: "35px",
                        width: "35px",
                        cursor: "pointer",
                      }}
                      primary
                      alt={"img"}
                      src={row.isActive == 1 ? activeImg : inactiveImg}
                      onClick={() => {
                        setOpen(true);
                        setAction({
                          type: 1,
                          id: row.nftId,
                          fundraiser_id: row.creator,
                          is_active: row.isActive == 1 ? 0 : 1,
                          remarks: row.isActive == 1 ? "Inactive" : "Active",
                        });
                      }}
                    />
                    <ViewCampaign UserDetails={row} myKycActions={getNfts} />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  <img src={no_record} alt="not-found-img" />

                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {/* {campaign?.count && campaign?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(campaign?.count) / GlobalVariables.PAGE_LIMIT) ||
            1
          }
        />
      ) : (
        ""
      )} */}
      {campaign?.count && campaign?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          activePage={nftFilters?.page}
          totalPages={
            Math.ceil(parseInt(campaign?.count) / GlobalVariables.PAGE_LIMIT) ||
            1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(ListNft);
