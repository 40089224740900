const API_HOST = process.env.REACT_APP_API_HOST
const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY
const BLOCKCHAIN_PASSWORD = process.env.REACT_APP_BLOCKCHAIN_PASSWORD
const KLAYTN_URL = process.env.REACT_APP_TXN_URL_KLAYTN
const MATIC_URL = process.env.REACT_APP_TXN_URL_MATIC

const Enviroments = {
  API_HOST,
  DATA_ENCRYPT_KEY,
  BLOCKCHAIN_PASSWORD,
  KLAYTN_URL,
  MATIC_URL,
}

export default Enviroments
