import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { formatUrl } from "../../../Assets/data/utils";
// import {
//   Twitter,
//   Tiktok,
//   InstaGram,
//   Facebook,
//   Fansonly,
//   VerifiedF,
//   IconMessage,
// } from "../../../assets/images/svg/SvgImages";
// import FollowersModal from "../../layout/followersModal/FollowersModal";
import MessageFollow from "./MessageFollow";

const CreatorInfo = ({
  creatorImg,
  creatorName,
  description,
  creatorFollowers,
  creatorFollowing,
  creatorUserName,
  role_type,
  instagramId,
  onlyfansId,
  tiktokId,
  twitterId,
  facebookId, 
  isInstagramToggled,
  isOnlyFansToggled,
  isTikTokToggled,
  isTwitterToggled,
  isFacebookToggled,
  type,
  editButton,
  editButtonOnClick,
}) => {
  const userName = useSelector(
    (state) => state?.user?.profileData?.userDetails?.username
  );
  const userLoggedIn = useSelector((state) => state?.user?.userLoggedIn);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const followRequest = async (values) => {
    // try {
    //   const results = await apiCallPost(
    //     "users/api/v1/follower/follow/35515c5c-5dee-45a3-ba03-21235a6c4dcd",
    //     values,
    //     {},
    //     "toastOn"
    //   );
    //   if (results) {
    //     navigate(`/${userName}`, { state: "5" });
    //     console.log(results, "results");
    //   }
    // } catch (error) {
    //   toasts.error();
    // }
  };

  const unfollowRequest = async (values) => {
    // try {
    //   const results = await apiCallPost(
    //     "users/api/v1/follower/unfollow/5d92c576-ff1a-427c-8136-dad835af83aa",
    //     values,
    //     {},
    //     "toastOn"
    //   );
    //   if (results) {
    //     navigate(`/${userName}`, { state: "5" });
    //     console.log(results, "results");
    //   }
    // } catch (error) {
    //   toasts.error();
    // }
  };
  return (
    <Row className="createInfoWrapStyle">
      <Col className="container">
        <Row className="creatorInfoStyle">
          <Col xs={24} lg={16} xl={14}>
            <div className="createprofileBtn d-flex align-items-center">
              <div className="creatorInfoStyle__creatorImg">
                <span className="creatorInfoStyle__creatorImg__img">
                  {creatorImg ? (
                    <img
                      src={creatorImg}
                      alt={creatorName}
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <p className="UserNameFirstLetter">
                      <span>{creatorName ? creatorName[0] : ""}</span>
                    </p>
                  )}
                </span>
              </div>
              {userLoggedIn && creatorUserName !== userName && (
                <MessageFollow className="d-block d-md-none" />
              )}
            </div>

            <div className="creatorDetails">
              <div className="creatorDetails__left">
                <div className="d-flex justify-content-between">
                  <h2>
                    {creatorName || "User Name"}{" "}
                    {role_type === 2}
                  </h2>
                  {type !== "Collection" &&
                    // userLoggedIn &&
                    // creatorUserName !== userName && 
                    (
                      <MessageFollow followRequest={followRequest} className="d-none d-md-block" />
                    )}
                  {/* {editButton && (
                    <Button onClick={editButtonOnClick}>Edit</Button>
                  )} */}
                </div>
                {creatorUserName && (
                  <h3>
                    <span>@{creatorUserName}</span>
                  </h3>
                )}
                {description && <p>{description}</p>}
                {/* {creatorFollowers && ( */}
                <div className="creatorDetails__right">
                  <ul>
                    <li>
                      <strong>{creatorFollowers}</strong>
                      <span>Followers</span>
                    </li>
                    <li>
                      <strong>{creatorFollowing}</strong>
                      <span>Following</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
                {/* <FollowersModal
                  isModalVisible={isModalVisible}
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                /> */}
              </div>
            </div>
          </Col>

          <Col xs={24} lg={8} xl={10}>
            <ul className="creator_Socialicon d-flex justify-content-xl-end">
              {onlyfansId && isOnlyFansToggled === 1 && (
                <li>
                  <a href="/" target="_blank">
                  Fansonly
                  </a>
                </li>
              )}
              {twitterId && isTwitterToggled === 1 && (
                <li>
                  <a href="/" target="_blank">
                  Twitter
                  </a>
                </li>
              )}
              {instagramId && isInstagramToggled === 1 && (
                <li>
                  <a
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    InstaGram
                  </a>
                </li>
              )}
              {facebookId && isFacebookToggled === 1 && (
                <li>
                  <a href="/" target="_blank">
                  Facebook
                  </a>
                </li>
              )}
              {tiktokId && isTikTokToggled === 1 && (
                <li>
                  <a href="/" target="_blank">
                  Tiktok
                  </a>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default CreatorInfo;
