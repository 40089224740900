import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import Types from "./_types";

export const saveNftDetails = (res) => ({
  type: Types.SAVE_NFT_DETAILS,
  payload: res,
});

/**
 * @function getCollections
 * @param {{ page, limit }} data
 * @returns /getCollections/limit/page
 */

export const searchNft = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.searchNft(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createCollection
 * @param {{ logo, banner, name, description }} data
 * @returns /createCollection
 */
export const createNft = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      logo,
      auctionExpireDate,
      //  banner,
      name,
      description,
      collectionId,
      supply,
      nftType,
      price,
      minBidCost,
      isActive,
      creator,
      fileType,
      raffleId,
      blockChainName,
    } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));

    // if (fileType == "IMAGE") {
    //   if (logo.type === "image/jpeg" || logo.type === "") {
    //     return toast.error("Please select correct file to upload.")
    //   }
    // }
    console.log("fileType", logo);
    logo = await dispatch(
      uploadFile(logo, fileType === "Image" ? "nft" : "video")
    );
    if (!logo) {
      dispatch(stopLoading());
      return toast.info("Error: Uploading failed");
    }
    // banner = await dispatch(uploadFile(banner));
    // if (!banner) {
    //   dispatch(stopLoading());
    //   return toast.info("Error: Uploading failed");
    // }

    data = {
      logo,
      auctionExpireDate,
      // banner,
      name,
      description,
      collectionId,
      supply,
      nftType,
      price,
      minBidCost,
      isActive,
      creator,
      fileType,
      raffleId,
      blockChainName,
    };
    if (nftType === "FREE" || nftType === "SALE") {
      delete data.minBidCost;
    }
    const res = await ApiService.createNft(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateNftStatus
 * @param {{ page, limit }} data
 * @returns /updateNftStatus/limit/page
 */
export const updateNftStatus = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.updateNftStatus(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getNftById
 * @param {{ page, limit }} data
 * @returns /getNftById/limit/page
 */
export const getNftById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getNftById(data, headers);
    let finalData = res?.data?.data?.detail[0];
    finalData.name = finalData.title;
    console.log("sdkjfhdksfhs ", res?.data?.data?.detail[0]);
    dispatch(saveNftDetails(finalData));
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getNftById
 * @param {{ page, limit }} data
 * @returns /getNftById/limit/page
 */
export const emptyNftFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveNftDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getBlockChainList
 * @returns /getBlockChainList
 */
export const getBlockChainList = () => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getBlockChainList(headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
export const getActiveCoinList = () => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getActiveCoinList(headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
/**
 * @function getRafflesNftList
 * @returns /getRafflesNftList
 */
export const getRafflesNftList = () => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getRafflesNftList(headers);
    dispatch(stopLoading());
    console.log("RafflesNftData", res);
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getNftReports = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getNftReports(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getPhotoGrapherReports = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getPhotoGrapherReports(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const nftUpdateUser = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.nftUpdateUser(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
