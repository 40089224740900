import { Button, Icon, Input, Table } from "semantic-ui-react";
import React, { useState } from "react";

import AddFee from "./AddFee";
import { AdminFees } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import no_record from '../../../Assets/image/no-record-found.png'

function AdminList({ feeData, getNftFeeList }) {
  const [feeInput, setFeeInput] = useState(false);
  const [feeValue, setFeeValue] = useState({ types: "", fees: "" });
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [feeId, setFeeId] = useState(null);
  const dispatch = useDispatch();

  const setNftFees = async (data, data2) => {
    let nftFee = null;
    if (isEdit === false) {
      const { setNftFee } = AdminFees;
      nftFee = await dispatch(setNftFee(data));
    } else {
      const { updateNftFee } = AdminFees;
      nftFee = await dispatch(updateNftFee(data2));
    }
    if (nftFee) {
      setFeeInput(false);
      getNftFeeList();
    }
  };


  return (
    <div>
      {!feeData && (
        <div style={{ display: "flex" }}>
          <Button
            style={{
              marginLeft: "auto",
            }}
            className="addButton"
            onClick={() => setOpen(true)}
          >
            Add Fees
          </Button>
        </div>
      )}
      {open && (
        <AddFee
          open={open}
          setOpen={() => setOpen(false)}
          setNftFees={setNftFees}
          data={feeValue}
          feeId={feeId}
        />
      )}
      <div className="pageHeading">
        <h4>Processing Fees for Campaign Withdraw</h4>
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Type</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Fees</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Edit Fees</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {" "}
          {/* {feeData && feeData?.length > 0  ? ( */}
          {/* feeData?.map(({ id, fee_amount, fee_type }) => ( */}
          <Table.Row>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {feeInput ? (
                <Input
                  value={feeValue}
                  onChange={(e) => setFeeValue(e.target.value)}
                  type="number"
                  autoFocus={true}
                ></Input>
              ) : (
                feeData?.fee_type

              )}
            </Table.Cell>{" "}
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {console.log('object4545', feeData?.fee_type)}
              {feeData?.fee_amount}
            </Table.Cell>{" "}
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {feeInput ? (
                <>
                  <Button
                    primary
                    disabled={!feeValue || feeValue === feeData?.fee_amount}
                    onClick={setNftFees}
                  >
                    Save
                  </Button>
                  <Button secondary onClick={() => setFeeInput(false)}>
                    Cancel
                  </Button>
                </>
              ) : (
                <Table.Cell collapsing colSpan="3" textAlign="right">
                  <Icon
                    style={{ color: "#0096FF" }}
                    onClick={() => {
                      setFeeValue({ types: feeData?.fee_type, fees: feeData?.fee_amount });
                      setOpen(true);
                      setIsEdit(true);
                      setFeeId(feeData?.id);
                    }}
                    name="edit"
                  />
                </Table.Cell>
              )}
            </Table.Cell>
          </Table.Row>
          {/* )) */}
          {/* ) : ( */}
          {/* <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  <img src={no_record} alt="no-record"/>
                </div>
              </Table.Cell>
            </Table.Row> */}
          {/* )} */}
        </Table.Body>
      </Table>
    </div>
  );
}

export default AdminList;
