import { ArtistActions, BrandActions } from "../../../redux/_actions";
import React, { useCallback, useEffect, useState } from "react";

import CampaignList from "./list/CampaignList";
import GlobalVariables from "../../../_constants/GlobalVariables";
import MainLayout from "../../../components/HOC/MainLayout";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
//import ArtistList from "./List/UsertList";
//import "./user.scss";

const Campaign = () => {
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState({ count: 10, rows: [] });
  const [search, setSearch] = useState("");

  const [requestFilter, setRequestFilter] = useState("ALL");

  const [activeTab, setActiveTab] = useState(1);
  const handleSearch = (e) => {
    // setSearch(e.target.value);
    getCampaign({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
  };

  const getCampaign = useCallback(
    async (data) => {
      const { getCampaign } = ArtistActions;
      const res = await dispatch(getCampaign(data));
      if (res) {
        setCampaign(res.data.data);
        setSearch(data.search);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    //getCampaign({ id: 3, page: 1, limit: PAGE_LIMIT , search: search });
  }, [getCampaign]);

  const blockUser = (data) => {
    const { blockuserById } = ArtistActions;
    dispatch(blockuserById(data)).then((_data) => {
      if (_data) {
        const { PAGE_LIMIT } = GlobalVariables;
        getCampaign({ id: 3, page: 0, limit: PAGE_LIMIT, search: search , });
      }
    });
  };

  return (
    <>
      <MainLayout>
        <CampaignList
          getCampaign={getCampaign}
          campaign={campaign}
          blockUser={blockUser}
          setCampaign={setCampaign}
          setActiveTab={setActiveTab}
          handleSearch={handleSearch}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(Campaign);
