import React, { useState, useEffect } from "react";
import { Button, Modal, Confirm } from "semantic-ui-react";
import { KycActions } from "../../../../redux/_actions";
import { useDispatch } from "react-redux";
import RejectKyc from "./RejectKyc";
import moment from "moment";

export default function ViewKyc({ kycDetail, myKycActions }) {
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [userId, setUserId] = useState("");
  const [remark, setRemark] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {}, [JSON.stringify(kycDetail)]);

  const modalElements = (
    <>
      <ul>
        {/* <li>
          <div ><b style={{ marginRight: 20 }}>User KycId:</b></div> <span>{kycDetail?.userKycId}</span>
        </li>
        <li>
        <div><b style={{ marginRight: 20 }}>User Id:</b> </div>{kycDetail?.userId}
        </li> */}
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Full Name:</b>{" "}
          </div>
          {kycDetail.fullName ? kycDetail.fullName : "N/A"}
        </li>
        <li>
          <div>
            {" "}
            <b style={{ marginRight: 20 }}>DOB:</b>{" "}
          </div>
          {moment(kycDetail?.dob).format("MMMM Do YYYY")}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Phone Number:</b>
          </div>{" "}
          {kycDetail?.phoneNumber}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Address:</b>
          </div>{" "}
          {kycDetail?.address}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>City:</b>{" "}
          </div>
          {kycDetail?.city}
        </li>
        <li>
          <div>
            {" "}
            <b style={{ marginRight: 20 }}>Country:</b>
          </div>{" "}
          {kycDetail?.country}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Postal Code:</b>
          </div>{" "}
          {kycDetail?.postalCode}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Document Type:</b>
          </div>{" "}
          {kycDetail?.documentType}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Document:</b>
          </div>{" "}
          {kycDetail.documentUrl && (
            <a href={kycDetail.documentUrl} target="_blank">
              <img className="documentImage" src={kycDetail.documentUrl} />
            </a>
          )}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>Date:</b>
          </div>{" "}
          {moment(kycDetail.updatedAt).format("MMMM Do YYYY, h:mm A")}
        </li>
        <li>
          <div>
            <b style={{ marginRight: 20 }}>:</b>
          </div>{" "}
          {kycDetail.kycStatus ? kycDetail.kycStatus : "N/A"}
        </li>
        {kycDetail && kycDetail.kycStatus === "FAILED" && (
          <li>
            <div>
              <b style={{ marginRight: 20 }}>Remark:</b>
            </div>{" "}
            {kycDetail.remark ? kycDetail.remark : "N/A"}
          </li>
        )}
        <li>
          <Button.Group style={{ marginTop: 20 }}>
            <Button
              positive
              onClick={() => {
                setOpen(true);
                // setImageId(data.bannerImageId);
                setUserId(kycDetail.userId);
              }}
            >
              Accept
            </Button>
            {/* <Button.Or /> */}
            <span style={{ marginLeft: "20px" }}></span>
            <RejectKyc kycDetail={kycDetail} myKycActions={myKycActions} />
          </Button.Group>
        </li>
      </ul>
    </>
  );
  return (
    <div style={{ marginLeft: "30px" }}>
      <Confirm
        content="Are you sure? You want to accept User's KYC."
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          const { changeKycStatus } = KycActions;
          const res = dispatch(
            changeKycStatus({
              userId: kycDetail.userId,
              kycStatus: "APPROVED",
              remark,
            })
          ).then((data) => {
            console.log("dsklfjdsklfjhdsklf ", data);
            if (data) {
              myKycActions();
            }
          });
        }}
      />

      <Modal
        trigger={<a>View Detail</a>}
        header="User's KYC Detail"
        content={modalElements}
        actions={[{ key: "done", content: "Done", primary: true }]}
      />
    </div>
  );
}
