import { DatePicker, Form, InputNumber, Space } from "antd";
import React from "react";

const { RangePicker } = DatePicker;

export default function Fixed() {
  return (
    <>
      {/* <div className="fixed_price">
      <h3>Sale Price</h3>
      <div className="tab_input">
        <p>amount</p>
        
        <h4>
          236,25 <img src={list_dataicon} alt="img" /> ETH
        </h4>
      </div>
    </div> */}
      <div className="fixed_price Quantity_price">
        <h3>Sale Price</h3>
        <div className="tab_input">
          {/* <p>amount</p> */}
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: "Please input price!",
              },
            ]}
          >
            <InputNumber
              min={1}
              max={10}
              controls={false}
              placeholder="Enter sale price"
            />
          </Form.Item>
        </div>
      </div>

      <div className="fixed_price">
        <h3>Duration</h3>
        <div className="tab_input fixed_select_price">
          {/* <p>amount</p> */}
          <Form.Item
            name="duration"
            rules={[
              {
                required: true,
                message: "Duration is required!",
              },
            ]}
          >
            <RangePicker />
          </Form.Item>
        </div>
      </div>
      <div className="fixed_price Quantity_price">
        <h3>Quantity</h3>
        <div className="tab_input">
          <Form.Item
            name="quantity"
            rules={[
              {
                required: true,
                message: "Quantity is required!",
              },
            ]}
          >
            <InputNumber
              min={1}
              max={10}
              controls={false}
              placeholder="Enter quantity"
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}
