import * as Yup from "yup";

import { Button, Dropdown, Form, Input, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { useFormik } from "formik";

function AddFee({ open, setOpen, setNftFees, data, feeId }) {
  
  const option = [
    { key: "Percentage", text: "Percentage", value: "percentage" },
    // { key: "fiat", text: "Fiat", value: "fiat" },
  ];

  const schema = Yup.object().shape({
    fees: Yup.number()
      .min(0)
      .required("Fees is requied")
      .typeError("Fees must be a number"),
  });

  console.log(data);
  const formik = useFormik({
    initialValues: { fees: data?.fees || "", types: data?.types || "fiat" },

    validationSchema: schema,
    onSubmit: (values) => {
      const data = {
        fee_type: values.types,
        fee_amount: values.fees,
      };
      const data2 = {
        id: feeId,
        fee_type: values.types,
        fee_amount: values.fees,
      };
      setNftFees(data, data2);
      formik.resetForm();
      setOpen();
    },
  });

  return (
    <Modal onClose={setOpen} onOpen={setOpen} open={open} className="common_modal">
      <Modal.Header>Add Fees</Modal.Header>

      <Modal.Content image>
        {" "}
        <Form onSubmit={formik.handleSubmit}>
          <Form.Field>
            <Dropdown 
            disabled={true}
              className="select_dropdown"
              placeholder="Select Fee"
              //fluid
              selection
              options={option}
              id="types"
              name="types"
              onChange={(e, data) => formik.setFieldValue("types", data.value)}
              value={formik.values.types}
            />
          </Form.Field>

          <Form.Field>
            <label style={{ color: "white" }}>Fees</label>
            <Input
             className="select_in"
             placeholder="Fees..."
              types="number"
              name="fees"
              id="fees"
              onChange={formik.handleChange}
              value={formik.values.fees}
              //error={formik.errors.fees}
            />
          </Form.Field>
          {formik.errors.fees && (
            <p style={{ color: "red" }}>{formik.errors.fees}</p>
          )}
          <Button secondary onClick={setOpen}>
            Cancel
          </Button>
          <Button type="submit" positive>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default AddFee;
