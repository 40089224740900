import React, { useState } from "react";
import MainLayout from "../../../components/HOC/MainLayout";
import { withRouter } from "react-router";
import TransactionList from "./List/transactionList";
import { apiCallGet } from "../../../components/Axios/Axios";
import GlobalVariables from "../../../_constants/GlobalVariables";
import { TransactionsActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";

const Transactions = () => {
  const [page, setPage] = useState(1);
  const [transactionList, setTransactionList] = useState({});
  const [withdrawList, setWithdrawList] = useState({});
  const dispatch = useDispatch();
     // Deposit Api
     const transactionData = (data) => {
      console.log("dataaaaa", data);
      apiCallGet(
        `/api/v1/admin/wallet/getDepositTransactions/matic/${data.page}`,
         {
        //   isDeleted: 0,
        //   isActive: 1,
         },
        {}
        // "toastOn"
      )
        .then((results) => {
          if (results) {
            setTransactionList(results);
          }
        })
        .catch((error) => {
          console.log("TAG", error);
        });
    };
  
    // Withdrawl Api
    const Withdraw = (data) => {
      apiCallGet(
        `/api/v1/admin/wallet/getWithdrawTransactions/matic/${data.page}`,
        // {},
        {},
        // toastOn
      ).then((results) => {
        if (results) {
          setWithdrawList(results);
        }
      })
        .catch((error) => {
          console.log("TAG44", error);
        });
    }

    const approvalDecline = (data) => {
      const { withdrawTransApprovalDecline } = TransactionsActions;
      dispatch(withdrawTransApprovalDecline(data)).then((_data) => {
        if (_data) {
          Withdraw({
            page: 1,
            limit: GlobalVariables.PAGE_LIMIT,
           //coins: selectedCoins,
          });
        }
      });
    }; 
    
  return (
    <>
      <MainLayout>
        <TransactionList
        transactionData={transactionData}
         transactionList={transactionList} 
         Withdraw={Withdraw}
         withdrawList={withdrawList}
         approvalDecline={approvalDecline}
         setPage={setPage}
         page={page} />
      </MainLayout>
    </>
  );
};

export default withRouter(Transactions);
