import { Col, Row } from 'antd';
import React from 'react'

const Properties = () => {
    const propertiesdata = [
        {
            id: "1",
            name: "Background",
            colorname: "Yellow",
            description: "13% have this trait",
        },
        {
            id: "2",
            name: "Clothes",
            colorname: "Navy Striped Tee",
            description: "3% have this trait",
        },
        {
            id: "3",
            name: "Earring",
            colorname: "Silver Stud",
            description: "8% have this trait",
        },
        {
            id: "4",
            name: "Eyes",
            colorname: "Crazy",
            description: "4% have this trait",
        },
        {
            id: "5",
            name: "FUR",
            colorname: "Dark Brown",
            description: "14% have this trait",
        },
        {
            id: "6",
            name: "Mouth",
            colorname: "Dumbfounded",
            description: "5% have this trait",
        },
    ];
    return (
        <>
            <div className='properties'>
                <Row gutter={20}>
                    {propertiesdata.map((item) => {
                        return (
                            <Col xs={12} md={8} lg={12} xl={8} className="d-flex">
                                <div className='properties_box text-center w-100'>
                                    <small>{item.name}</small>
                                    <h4>{item.colorname} </h4>
                                    <p>{item.description}</p>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </>
    )
}

export default Properties