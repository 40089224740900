import Types from "../_actions/_types";

const initialState = {
  media: {},
};

const media = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_MEDIA: {
      
      return { ...state, media: payload };
    }
    default:
      return state;
  }
};

export default media;
