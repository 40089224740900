import { Pagination } from 'antd';
import React from 'react'
import iconEthereum from "../../../images/icon_ethereum.svg";

const Activity = () => {
    const dataSource = [
        {
            key: '1',
            event:"Transfer",
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            quantity: "2",
            from: 'F33F07',
            to: '0D28E9',
            date: "19 hours ago"
        },
        {
            key: '2',
            event:"Transfer",
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            quantity: "2",
            from: 'F33F07',
            to: '0D28E9',
            date: "19 hours ago"
        },
        {
            key: '3',
            event:"Cancel",
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            quantity: "2",
            from: 'F33F07',
            to: '0D28E9',
            date: "19 hours ago"
        },
        {
            key: '4',
            event:"List",
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            quantity: "2",
            from: 'F33F07',
            to: '0D28E9',
            date: "19 hours ago"
        },
        {
            key: '45',
            event:"Minted",
            price: <span><img src={iconEthereum} alt="icon" /> 0.0001 ETH</span>,
            quantity: "2",
            from: 'F33F07',
            to: '0D28E9',
            date: "19 hours ago"
        },
    ];

    const columnsHeading = [
        {
            id: '1',
            title: 'Event',
        },
        {
            id: '2',
            title: 'Unit Price',
        },
        {
            id: '3',
            title: 'Quantity',
        },
        {
            id: '4',
            title: 'From',
        },
        {
            id: '5',
            title: 'To',
        },
        {
            id: '6',
            title: 'Date',
        },
    ];
    return (
        <>
            <div className='commonTable ActivityTable'>
                {/* <Table dataSource={dataSource} columns={columns} />; */}
                <div className='listingTable'>
                    <div className='listingTableHeading d-md-flex'>
                        {columnsHeading.map((item) => {
                            return (
                                <h5>{item.title}</h5>
                            )
                        })}
                    </div>
                    <div className='listingTableData'>
                        {dataSource.map((item) => {
                            return (
                                <ul className='d-md-flex'>
                                    <li><span className='mobileTitle'>Event</span> <span>{item.event}</span></li>
                                    <li><span className='mobileTitle'>Unit Price</span> <span>{item.price}</span></li>
                                    <li><span className='mobileTitle'>Quantity</span> <span>{item.quantity}</span></li>
                                    <li><span className='mobileTitle'>From</span> <span>{item.to}</span></li>
                                    <li><span className='mobileTitle'>To</span> <span>{item.from}</span></li>
                                    <li><span className='mobileTitle'>Date</span> <span>{item.date}</span></li>
                                </ul>
                            )
                        })}
                    </div>
                    <Pagination defaultCurrent={1} total={50} />;
                </div>
            </div>
        </>
    )
}

export default Activity