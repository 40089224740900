export const CreatorInfo = [
  {
    name: "name",
    label: "Name",
    type: "string",
    rules: [
      {
        type: "string",
      },
      {
        // required: true,
        message: "Please input creator Name!",
      },
    ],
    placeholder: "Enter Name",
  },
  {
    name: "publicEthAddress",
    label: "Public ETH Address",
    rules: [
      {
        type: "string",
      },
      {
        // required: true,
        message: "Please input Public ETH Address!",
      },
    ],
    disabled: true,
    placeholder: "Enter Public ETH Address",
  },
  {
    name: "email",
    label: "Email Address",
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail!",
      },
      {
        required: true,
        message: "Please input your E-mail!",
      },
    ],
    disabled: false,
    placeholder: "Enter Email Address",
  },
  {
    name: "bio",
    label: "Bio",
    rules: [
      {
        type: "string",
      },
    ],
    placeholder: "Enter your bio",
  },
  {
    name: "mintWebsite",
    label: "Website",
    rules: [
      {
        whitespace: true,
        message:
          "Please add URL Eg:-https://www.fan-verse.io/ or www.fan-verse.io",
        type: "url",
      },
    ],
    placeholder: "Website URL",
  },
  {
    name: "username",
    label: "Username",
    rules: [
      {
        type: "string",
      },
      {
        required: true,
        message: "Please input Creator Username",
      },
    ],
    disabled: false,
    placeholder: "Username",
  },
];

export const PersonalInfo = [
  {
    name: "address",
    label: "Address",
    rules: [{ type: "string" }],
    placeholder: "Address",
    column: 24,
    hasFeedback: true,
  },
  {
    name: "country",
    label: "Country",
    rules: [{ type: "string" }],
    placeholder: "Country",
    column: 24,
    hasFeedback: true,
  },
  {
    name: "city",
    label: "City",
    rules: [{ type: "string" }],
    placeholder: "City",
    column: 24,
    hasFeedback: true,
  },
  {
    name: "state",
    label: "State",
    rules: [{ type: "string" }],
    placeholder: "State",
    column: 24,
    hasFeedback: true,
  },
  {
    name: "postalCode",
    label: "Postal Code",
    rules: [{ type: "string" }],
    placeholder: "Postal Code",
    column: 24,
    maxLength: 10,
    hasFeedback: true,
  },
];
