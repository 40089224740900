import Types from "../_actions/_types";

const initialState = {
  nftData: {},
};

const nft = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_NFT_DETAILS:
      return { ...state, nftData: payload };

    default:
      return state;
  }
};

export default nft;
