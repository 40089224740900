import React, { useEffect, useState } from "react";

import CampaignLimitList from "./CampaignLimitList.jsx";
import { TransactionFeeAction } from "../../../redux/_actions";
import { useDispatch } from "react-redux";

;

function CampaignLimit(enableCampaign) {
  const [feeData, setFeeData] = useState([]);

  const dispatch = useDispatch();

  const getTransactionFeeList = async () => {
    const { transactionFeeList } = TransactionFeeAction;
    const transactionFee = await dispatch(transactionFeeList());
    const rowData = transactionFee.data.data;
    console.log("dsjsndj", rowData);
    setFeeData(rowData);
  };

  useEffect(() => {
    getTransactionFeeList();
  }, []);
  return (
   
      <CampaignLimitList
        feeData={feeData}
        getTransactionFeeList={getTransactionFeeList}
      />  );
}

export default CampaignLimit;
