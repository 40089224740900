import "./Setting.scss";

import { Button, Confirm, Icon, Input, Table } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { apiCallGet, apiCallPost } from "../../../components/Axios/Axios";

import CampaignLimit from "../CampaignSetting/CampaignLimit";
import GlobalVariables from "../../../_constants/GlobalVariables";
import MainLayout from "../../../components/HOC/MainLayout";
import activeImg from "../../../images/active.png";
import inactiveImg from "../../../images/inactive.png";
import { withRouter } from "react-router";

const Setting = () => {
  const [enableW, setEnableW] = useState("");
  const [enableCampaign, setEnableCampaign] = useState("");
  const [open, setOpen] = useState(false);
  const [openCampaign, setOpenCampaign] = useState(false);
  const [openFundraiser, setOpenFundraiser] = useState(false);
  const [action, setAction] = useState({});
  const [actionCampaign, setActionCampaign] = useState({});
  //Admin Campaign Status
  const getCampaignStatus = () => {
    apiCallGet(
      `/api/v1/admin/campaign/campaignsSettings`,
      // {},
      {},
      "toastOn"
    )
      .then((results) => {
        if (results) {
          setEnableCampaign(results?.data);
        }
      })
      .catch((error) => {
        console.log("TAG44", error);
      });
  };
  useEffect(() => {
    getCampaignStatus();
  }, []);

  function disableCampaign(newData) {
    setOpen(false);
    console.log("TAG44", actionCampaign);
    apiCallPost(
      `/api/v1/admin/campaign/updateCampaignsSettings`,
      newData,
      {},
      "toastOn"
    )
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            getCampaignStatus({
              page: 1,
              limit: GlobalVariables.PAGE_LIMIT,
              //search: search,
            });
          }
        }
      })
      .catch((err) => {});
  }

  //Admin Withdraw Status
  // const getWithdrawStatus = () => {
  //   apiCallGet(
  //     `/api/v1/admin/wallet/getAssets/matic/1`,
  //     // {},
  //     {}
  //     // toastOn
  //   )
  //     .then((results) => {
  //       if (results) {
  //         setEnableW(results?.data?.list);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("TAG44", error);
  //     });
  // };

  // useEffect(() => {
  //   getWithdrawStatus();
  // }, []);

  function disableWithdraw(newData) {
    setOpen(false);
    console.log("TAG44", action);
    apiCallPost(`/api/v1/admin/wallet/updateAssets`, newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            // getWithdrawStatus({
            //   page: 1,
            //   limit: GlobalVariables.PAGE_LIMIT,
            //   //search: search,
            // });
          }
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <MainLayout>
        <div>
          <div className="pageHeading">
            <h4>Settings</h4>
          </div>
          <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">Section</Table.HeaderCell>
                <Table.HeaderCell colSpan="4">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing colSpan="3">
                  <b>Limit :</b>{" "}
                </Table.Cell>{" "}
                <Table.Cell colSpan="4" textalign="left">
                  <CampaignLimit enableCampaign={enableCampaign} />
                </Table.Cell>{" "}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <Confirm
          closeIcon
          className="mainmodel"
          content={`Are you sure? You want to ${action.remarks} this withdraw!`}
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={() => {
            setOpen(false);
            disableWithdraw(action);
          }}
        />
        <Confirm
          closeIcon
          className="mainmodel"
          content={`Are you sure? You want to ${actionCampaign.remarks} this Campaign!`}
          open={openCampaign}
          onCancel={() => setOpenCampaign(false)}
          onConfirm={() => {
            setOpenCampaign(false);
            disableCampaign(actionCampaign);
          }}
        />
        <Confirm
          closeIcon
          className="mainmodel"
          content={`Are you sure? You want to ${actionCampaign.remarks} this Fundraiser!`}
          open={openFundraiser}
          onCancel={() => setOpenFundraiser(false)}
          onConfirm={() => {
            setOpenFundraiser(false);
            disableCampaign(actionCampaign);
          }}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(Setting);
