import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {
  Breadcrumb,
  Button,
  Confirm,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import TableRows from "../../../../components/TableRows";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import moment from "moment";
import { Link } from "react-router-dom";
import { limitCharacters } from "../../../../Assets/data/utils";
import ImageVideoModal from "../../../../components/Modal/ImageVideoModal";
import no_record from '../../../../Assets/image/no-record-found.png'

const ListCategories = ({
  getCategoryList,
  history,
  raffles,
  changeRafflesStatus,
  deleteCategory,
  page,
  setPage,
}) => {
  const [open, setOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const dispatch = useDispatch();

  const goToAddRafflepage = () => {
    history.push("/panel/categories/create-categories");
  };

  const handlePageClick = (e, pageInfo) => {
    let pages = pageInfo.activePage;
    setPage(pages);
    getCategoryList({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };

  const changeStatus = (action, values) => {
    let data = {
      action: action,
      data: values,
    };
    changeRafflesStatus(data);
  };

  return (
    <>
      <Confirm
        header="Delete Category"
        content="Are you sure you want to delete this category?"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          deleteCategory(categoryId);
        }}
      />
      <div className="pageHeading">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap:"0.8rem",
          }}
        >
          <h3>Categories</h3>
          <Button className="addButton" onClick={goToAddRafflepage}>
            Add New Categories
          </Button>
        </div>
        <p>List of categories</p>
      </div>
      <div className="editTable">
        <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Serial no.</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Category Name</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Category Image</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Description</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {raffles.rows && raffles.rows.length > 0 ? (
              raffles.rows.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell collapsing colSpan="2" textAlign="left">
                  {/* <Table.HeaderCell colSpan="2" className="responsive_heading">Serial no.</Table.HeaderCell> */}
                    {page * 10 - 10 + i + 1}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan="3" textAlign="left">
                  {/* <Table.HeaderCell colSpan="2" className="responsive_heading">Serial no.</Table.HeaderCell> */}
                    {row.name ? limitCharacters(row.name, 20) : "N/A"}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan="2" textAlign="left">
                  {/* <Table.HeaderCell colSpan="2" className="responsive_heading">Serial no.</Table.HeaderCell> */}
                    <ImageVideoModal
                      fileType={row?.fileType}
                      logo={row?.image ? row.image : ""}
                    />
                  </Table.Cell>

                  <Table.Cell collapsing colSpan="3" textAlign="left">
                  {/* <Table.HeaderCell colSpan="2" className="responsive_heading">Serial no.</Table.HeaderCell> */}
                    {row.description
                      ? limitCharacters(row.description, 20)
                      : "N/A"}
                  </Table.Cell>

                  <Table.Cell collapsing colSpan="3" textAlign="left">
                    <Link to={`/panel/categories/edit-categories/${row?.id}`}>
                      <Icon name="edit" />
                    </Link>
                    <span>
                      <Icon
                        style={{ marginLeft: "30px" }}
                        onClick={() => {
                          setCategoryId(row?.id);
                          setOpen(true);
                        }}
                        name="delete"
                      />
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell collapsing colSpan="18" textAlign="center">
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    }}
                  >
                  <img src={no_record} alt="not-found-img"/>
                  
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

        </Table>
      </div>

      {raffles.rows && raffles.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(raffles.count) / GlobalVariables.PAGE_LIMIT) || 1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(ListCategories);
