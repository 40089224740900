import React, { useCallback, useEffect, useState } from "react";
import "./MyNft.scss";
import { Row, Col, Button } from "antd";
import { Tabs } from "antd";
import nftDetailImg from "../../../images/listsale_img.png";
import vector from "../../../images/list_vector.png";
// import list_dataicon from "../../../assets/images/list_dataicon.png";
import FvButton from "../../../components/button/FvButton";
import NftData from "./NftData";
import Properties from "./Properties";
import { useParams } from "react-router-dom";
import Listings from "./Listings";
import Activity from "./Activity";
import { useSelector } from "react-redux";
import Offer from "./Offer";
import MainLayout from "../../../components/HOC/MainLayout";

const MyNft = () => {
  const params = useParams();
  // const navigate = useNavigate();
  const [nftDetail, setNftDetail] = useState({});
  const userId = useSelector(
    (state) => state?.user?.profileData?.userDetails?.userId
  );

  // const getNftDetail = useCallback(async () => {
  //   try {
  //     const results = await apiCallGet(
  //       `users/api/v1/nft/get_nft_details/${params.id}`
  //     );
  //     if (!results) {
  //       navigate("/PageNotFound");
  //     }
  //     setNftDetail(results?.data[0]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  // creator 

  // useEffect(() => {
  //   setTimeout(() => {
  //     getNftDetail();
  //   }, 100);
  // }, [getNftDetail]);

  const { TabPane } = Tabs;

  return (
    <MainLayout className="MyNftPage">
      <div className="listsale_page py-32">
        <div className="containersss">
          <Row gutter={{ xs: 25, md: 30, xl: 40 }}>
            <Col xl={12} lg={12} xs={24}>
              <div className="list_left_img">
                <img src={nftDetailImg} alt="nft_img" />
              </div>
            </Col>
            <Col xl={12} lg={12} xs={24} className="d-flex">
              <div className="list_right_area">
                <div className="list_inner_text">
                  <div className="list_heading">
                    <h3 className="d-flex justify-content-between align-items-center">
                      <span>Azuki Teamverse
                        <span>
                          <img src={vector} alt="vector_img" />
                        </span></span>
                      {/* <Button onClick={() => navigate(`/edit-nft/${params.id}`)}>
                      Edit
                    </Button> */}
                    </h3>
                    <h2>{nftDetail?.title}</h2>
                  </div>
                  <div className="input_data d-flex align-items-center justify-content-between">
                    <div className="nft_data">
                      <p>Current Price</p>
                      <h4>{nftDetail?.price} ETH</h4>
                    </div>
                    <FvButton
                      text={
                        nftDetail?.creator === userId
                          ? nftDetail?.isListed === "1"
                            ? "Unlist Item"
                            : "List Item"
                          : "Buy Now"
                      }
                    // onClick={(e) =>
                    //   nftDetail?.creator === userId
                    //     ? navigate(`/list-for-sale/${params.id}`)
                    //     : e.preventDefault()
                    // }
                    />
                  </div>
                  <Tabs defaultActiveKey="1" className="tab_info">
                    <TabPane tab="About" key="1">
                      <NftData nftDetail={nftDetail} />
                    </TabPane>
                    <TabPane tab="Listings" key="2">
                      <Listings />
                    </TabPane>
                    <TabPane tab="Activity" key="3">
                      <Activity />
                    </TabPane>
                    <TabPane tab="Properties" key="4">
                      <Properties />
                    </TabPane>
                    <TabPane tab="Offers" key="5">
                      <Offer />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  );
};

export default MyNft;
