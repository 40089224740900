import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import { Form, Input, Button, Switch, Upload } from "antd";
// import { UploadIcon } from "../../../Assets/image/svg/SvgImages";
import "./CreateCollectionFrom.scss";
import TextArea from "antd/lib/input/TextArea";
import globalicon from "../../../Assets/image/globalicon.svg";
import listdataicon from "../../../Assets/image/list_dataicon.png";
// import { getImageUrl, handleUpload } from "../../../Assets/data/utils";
// import { 
//   apiCallGet,
//   apiCallPost,
//   apiCallPut,
// } from "../../../components/Axios/Axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainLayout from "../../../components/HOC/MainLayout";
// import Loader from "../../../components/Loader/Loader";

function CreateCollectionFrom() {
  /* -------------------------------------------------Hooks--------------------------------------------------*/

  const params = useParams();
  const dispatch = useDispatch();

  /* -------------------------------------------------States--------------------------------------------------*/

  const [fileList, setFileList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [collectionDetails, setCollectionDetails] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [loader, setLoader] = useState(false);

  /* -------------------------------------------------Functions-------------------------------------------------*/

  ///Upload file Props ///
  const profile = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    fileList,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const { Option } = Select;

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  // const handleEditSubmission = async (values) => {
  //   const data = {
  //     ...values,
  //     logo: collectionDetails?.logo,
  //     banner: collectionDetails?.banner,
  //     featuredImage: collectionDetails?.featuredImage,
  //     blockChainId: "1",
  //   };
  //   if (fileList.length > 0) {
  //     await handleUpload(fileList, setLoader).then((res) => {
  //       res.map((elem) => {
  //         data[elem.key] = elem.url;
  //       });
  //     });
  //   }
  //   updateCollectionDetails(data);
  // };

  // Form Submition Function
  // const onFinish = async (values) => {
  //   if (isEdit) {
  //     handleEditSubmission(values);
  //   } else {
  //     await handleUpload(fileList, setLoader).then((res) => {
  //       const data = {
  //         ...values,
  //         logo: getImageUrl(res, "logo"),
  //         banner: getImageUrl(res, "banner"),
  //         featuredImage: getImageUrl(res, "featuredImage"),
  //         blockChainId: "1",
  //       };
  //       console.log({fileList});
  //       createCollectionApi(data);
  //     });
  //   }
  // };

  ///Form Submition Failed Function ///
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const getCategoryName = () => {
  //   let res = categoryList.filter(
  //     (elem) => elem.CategoryId === collectionDetails?.categoryId
  //   );
  //   return res[0]?.CategoryId;
  // };

  // const editCollectionProps = {
  //   name: collectionDetails?.name || "",
  //   collectionUrl: collectionDetails?.collectionUrl || "",
  //   externalLink: collectionDetails?.externalLink || "",
  //   description: collectionDetails?.description || "",
  //   categoryId: getCategoryName() || "",
  //   royalty: collectionDetails?.royalty || "",
  //   sensitiveContent: collectionDetails?.sensitiveContent || false,
  // };

  /* -------------------------------------------------APIS-------------------------------------------------*/

  // const updateCollectionDetails = async (data) => {
  //   try {
  //     const results = await apiCallPut(
  //       `users/api/v1/collection/${params.id}`,
  //       data,
  //       {},
  //       "toastOn"
  //     );
  //     console.log(results);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getCategoriesApi = useCallback(async () => {
  //   try {
  //     const results = await apiCallGet("/users/api/v1/category_list/10/1");
  //     setCategoryList(results.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // const createCollectionApi = async (values, isEdit) => {
  //   try {
  //     const results = await apiCallPost(
  //       "users/api/v1/collection/add",
  //       values,
  //       {},
  //       "toastOn"
  //     );
  //     console.log(results);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getCollectionDetailsById = useCallback(async () => {
  //   try {
  //     const results = await apiCallGet(`users/api/v1/collection/${params.id}`);
  //     if (results) {
  //       setCollectionDetails(results.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [dispatch]);

  /* -------------------------------------------------useEffects-------------------------------------------------*/

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (Object.keys(params).length > 0) {
  //       getCollectionDetailsById();
  //       setIsEdit(true);
  //     }
  //   }, 100);
  //   getCategoriesApi();
  // }, [getCollectionDetailsById]);

  /* -------------------------------------------------HTML-------------------------------------------------*/

  return (
    <>
      {(!isEdit || Object.keys(collectionDetails).length > 0) && (
        <>
          {/* <Loader active={loader} /> */}
          <MainLayout className="createcollectionFrom">
            <div className="container explore_area">
              <div className="new__creater">
                <div className="sub_title">
                  <h2>{isEdit ? "Edit" : "Create"} Collection</h2>
                </div>
                <Form
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  // initialValues={editCollectionProps}
                  // onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="fv-form"
                >
                  <Row gutter={25}>
                    <Col lg={12} sm={24} xs={24}>
                      <div className="new__creater__form">
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                          ]}
                        >
                          <Input placeholder="Collection Name" />
                        </Form.Item>

                        <div className="FormGroup urlLinks">
                          <h4>URL</h4>
                          <Form.Item
                            name="collectionUrl"
                            label="Customize your URL on FanVerse. Must only contain lowercase letters, numbers, and hyphens"
                            rules={[
                              {
                                required: true,
                                message: "Please input your url!",
                              },
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.fan-verse.io/collection",
                                type: "url",
                              },
                            ]}
                          >
                            <Input placeholder="www.fan-verse.io/collection" />
                          </Form.Item>
                          <div className="globalUrl">
                            <span>
                              <img src={globalicon} alt="icon" />
                            </span>
                          </div>
                        </div>

                        <div className="FormGroup urlLinks">
                          <h4>External link</h4>
                          <Form.Item
                            label="FanVerse will include a link to this URL on this collection's detail page, so that users can click to learn more about it"
                            name="externalLink"
                            rules={[
                              {
                                required: true,
                                message: "Please input your url!",
                              },
                              {
                                whitespace: true,
                                message: "Please add URL Eg:- www.website.com",
                                type: "url",
                              },
                            ]}
                          >
                            <Input placeholder="www.website.com" />
                          </Form.Item>
                          <div className="globalUrl">
                            <span>
                              <img src={globalicon} alt="icon" />
                            </span>
                          </div>
                        </div>

                        <div className="FormGroup">
                          <h4>Description</h4>
                          <Form.Item
                            label="Markdown syntax is supported. 0 of 1000 characters used"
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Description!",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              placeholder="Insert description"
                              maxLength={400}
                            />
                          </Form.Item>
                        </div>

                        <div className="FormGroup">
                          <h4>Category</h4>

                          <Form.Item
                            label="Select a category for your collection"
                            name="categoryId"
                            rules={[
                              {
                                required: true,
                                message: "Please select category!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select category"
                              optionFilterProp="children"
                            >
                              {categoryList.map((elem) => (
                                <Option
                                  key={elem.CategoryId}
                                  value={elem.CategoryId}
                                >
                                  {elem.category_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="FormGroup">
                          <h4>Creator Royalty</h4>
                          <Form.Item
                            label="It is a long established fact that a reader will be distracted"
                            name="royalty"
                          >
                            <Input placeholder="Insert royalty" />
                          </Form.Item>
                        </div>

                        <div className="FormGroup Blockchain">
                          <h4>Blockchain</h4>
                          <Form.Item
                            // label="Username"
                            name="blockChainId"
                          >
                            <div className="ethInput">
                              <Input placeholder="Etherium" disabled />
                              <span>
                                <img src={listdataicon} alt="icon" />
                              </span>
                            </div>
                          </Form.Item>
                        </div>

                        <div className="SwitchToggle d-flex align-items-center justify-content-between">
                          <div className="text">
                            <h4>Explicit & Sensitive Content</h4>
                            <p>Set this item as explicit and sensitive content</p>
                          </div>
                          <Form.Item
                            valuePropName="checked"
                            name="sensitiveContent"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} sm={24} xs={24}>
                      <div className="new__creater__form">
                        <Form.Item
                          name="logo"
                          label="Logo image"
                          className="upload_img"
                          valuePropName="fileList"
                          extra="Must be 500x500 & PNG, JPG format"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required:
                                Object.keys(collectionDetails).length > 0
                                  ? collectionDetails?.logo
                                    ? false
                                    : true
                                  : true,
                              message: "Please upload logo image!",
                            },
                          ]}
                        >
                          <Upload
                            name="logo"
                            maxCount={1}
                            defaultFileList={
                              Object.keys(collectionDetails).length > 0 && [
                                {
                                  type: "logo",
                                  thumbUrl: collectionDetails?.logo,
                                },
                              ]
                            }
                            {...profile}
                            beforeUpload={(file, name) => {
                              setFileList([
                                ...fileList,
                                { url: file, key: "logo" },
                              ]);
                              return false;
                            }}
                            listType="picture"
                          >
                            <Button>Upload picture</Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name="featuredImage"
                          label="Featured image"
                          className="upload_img back_upload_img"
                          valuePropName="fileList"
                          extra="Must be 1200x400 & PNG, JPG format"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required:
                                Object.keys(collectionDetails).length > 0
                                  ? collectionDetails?.featuredImage
                                    ? false
                                    : true
                                  : true,
                              message: "Please upload featured image!",
                            },
                          ]}
                        >
                          <Upload
                            name="featuredImage"
                            maxCount={1}
                            defaultFileList={
                              Object.keys(collectionDetails).length > 0 && [
                                {
                                  type: "logo",
                                  thumbUrl: collectionDetails?.featuredImage,
                                },
                              ]
                            }
                            {...profile}
                            beforeUpload={(file, name) => {
                              setFileList([
                                ...fileList,
                                { url: file, key: "featuredImage" },
                              ]);
                              return false;
                            }}
                            listType="picture"
                          >
                            <Button>Upload picture</Button>
                          </Upload>
                        </Form.Item>

                        <Form.Item
                          name="banner"
                          label="Banner image"
                          className="upload_img back_upload_img"
                          valuePropName="fileList"
                          extra="Must be 1680x296 & PNG, JPG format"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required:
                                Object.keys(collectionDetails).length > 0
                                  ? collectionDetails?.banner
                                    ? false
                                    : true
                                  : true,
                              message: "Please upload banner image!",
                            },
                          ]}
                        >
                          <Upload
                            name="banner"
                            maxCount={1}
                            defaultFileList={
                              Object.keys(collectionDetails).length > 0 && [
                                {
                                  type: "logo",
                                  thumbUrl: collectionDetails?.banner,
                                },
                              ]
                            }
                            {...profile}
                            beforeUpload={(file) => {
                              setFileList([
                                ...fileList,
                                { url: file, key: "banner" },
                              ]);
                              return false;
                            }}
                            listType="picture"
                          >
                            <Button>Upload picture</Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <div className="cncl_buttons">
                    <div className="new__creater__form__submit__data">
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </MainLayout>
        </>
      )}
    </>
  );
}

export default CreateCollectionFrom;
