import { ArtistActions, BrandActions } from "../../../redux/_actions";
import React, { useCallback, useEffect, useRef, useState } from "react";

import GlobalVariables from "../../../_constants/GlobalVariables";
import MainLayout from "../../../components/HOC/MainLayout";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

import ListNft from "./list/ListNft";
import { debounce } from "../../../Assets/data/utils";

const Nft = () => {
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState({ count: 10, rows: [] });
  // const [search, setSearch] = useState("");
  // const [page, setPage] = useState(1);
  // const [selectedType, setSelectedType] = useState(null);
  const [requestFilter, setRequestFilter] = useState("ALL");
  const [activeTab, setActiveTab] = useState(1);
  const [nftFilters, setNftFilters] = useState({
    page: 1,
    limit: 10,
    saleType: "",
    search: "",
  });

  const searchHit = useCallback(
    debounce((searchText) => {
      getNfts({
        ...nftFilters,
        search: searchText,
      });
    }),
    [nftFilters?.saleType]
  );
  const handleSearch = (e) => {
    let searchText = e?.target?.value;
    searchHit(searchText);
    setNftFilters({ ...nftFilters, search: searchText, page: 1 });
  };

  const getNfts = async (data) => {
    try {
      const { getNfts } = ArtistActions;
      let res = await dispatch(getNfts(data));
      if (res) {
        setCampaign(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   const { PAGE_LIMIT } = GlobalVariables;
  //   // getNfts({ id: 3, page: 1, limit: PAGE_LIMIT , search: search });
  // }, [getNfts]);

  const blockUser = (data) => {
    const { blockuserById } = ArtistActions;
    dispatch(blockuserById(data)).then((_data) => {
      if (_data) {
        const { PAGE_LIMIT } = GlobalVariables;
        // getNfts({ id: 3, page: 0, limit: PAGE_LIMIT, search: search , });
      }
    });
  };

  const handleDropdownChange = (selectedValue) => {
    setNftFilters({ ...nftFilters, saleType: selectedValue, page: 1 });
  };

  useEffect(() => {
    getNfts(nftFilters);
  }, [nftFilters?.page, nftFilters?.saleType]);

  return (
    <>
      <MainLayout>
        <ListNft
          getNfts={getNfts}
          campaign={campaign}
          blockUser={blockUser}
          setCampaign={setCampaign}
          setActiveTab={setActiveTab}
          handleSearch={handleSearch}
          nftFilters={nftFilters}
          setNftFilters={setNftFilters}
          handleDropdownChange={handleDropdownChange}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(Nft);
