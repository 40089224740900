import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import BrandService from "../../Services/brand.service";
import collection from "../_reducers/brand.reducer";
import Types from "./_types";

export const saveBrand = (payload) => ({
  type: Types.SAVE_BRAND,
  payload,
});

/**
 * @function getBrand
 * @param {{ page, limit }} data
 * @returns /getBrands/limit/page
 */
export const getBrands = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.getBrands(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getNftWithdrawRequest = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.getNftWithdraw(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const userList = (data) => async (dispatch, getState) => {
  console.log(data, "----");
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    // const { setHeaders } = PersistActions;
    // const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.listUser(data);

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getBrandById
 * @param {{ id }} data
 * @returns get particular collection
 */
export const getBrandById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.getBrandById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      data.isFeatured = data.isFeatured === 0 ? false : true;
      dispatch(saveBrand({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createBrand
 * @param {{ logo, banner, name, description }} data
 * @returns /createBrand
 */
export const createBrand = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      fullname,
      email,
      password,
      bio,
      role_type,
      isFeatured,
      profileImage,
      coverImage,
      showcaseImage,
      username,
    } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));

    let profileForm = new FormData();
    profileForm.append("file", profileImage);
    let profileImages = await BrandService.uploadBrandArtistImage(
      profileForm,
      headers
    );
    if (!profileImages) return toast.info("Error: Uploading failed");
    let coverForm = new FormData();
    coverForm.append("file", coverImage);
    let coverImages = await BrandService.uploadBrandArtistImage(
      coverForm,
      headers
    );
    if (!coverImages) return toast.info("Error: Uploading failed");

    let showcaseForm = new FormData();
    showcaseForm.append("file", showcaseImage);
    let showcaseImages = await BrandService.uploadBrandArtistImage(
      showcaseForm,
      headers
    );
    if (!showcaseImages) return toast.info("Error: Uploading failed");

    let data2 = {
      fullname: fullname,
      email: email,
      password: password,
      bio: bio,
      role_type: role_type,
      profileImage: profileImages?.data?.message?.location,
      coverImage: coverImages?.data?.message?.location,
      showcaseImage: showcaseImages?.data?.message?.location,
      username: username,
      brandId: 0,
      isFeatured: isFeatured,
    };
    const res = await BrandService.createBrand(data2, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateBrand
 * @param {{ logo, banner, name, description }} data
 * @returns /createBrand
 */
export const updateBrand = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      id,
      profileImage,
      showcaseImage,
      coverImage,
      isFeatured,
      email,
      bio,
      fullname,
      // brandId,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (typeof coverImage !== "string") {
      coverImage = await dispatch(uploadFile(coverImage));
      if (!coverImage) return toast.info("Error: Uploading failed");
    }

    if (typeof profileImage !== "string") {
      profileImage = await dispatch(uploadFile(profileImage));
      if (!profileImage) return toast.info("Error: Uploading failed");
    }
    if (typeof showcaseImage !== "string") {
      showcaseImage = await dispatch(uploadFile(showcaseImage));
      if (!showcaseImage) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      email,
      bio,
      profileImage,
      showcaseImage,
      coverImage,
      fullname,
      // brandId,
      isFeatured,
    };

    let updateId = data.id;
    delete data.id;
    const res = await BrandService.updateBrand(updateId, data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    console.log("err : ", error);
    // throw error;
  }
};

export const deleteBrandById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.deleteBrandById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      // data.isFeatured = data.isFeatured === 0 ? false : true;
      // dispatch(saveBrand({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const approveDeclineuser = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await BrandService.approveDeclineuser(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      toast.info(res.data.message);
      // data.isFeatured = data.isFeatured === 0 ? false : true;
      // dispatch(saveBrand({ brand: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const approveDeclineNftWithdraw =
  (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;

    try {
      dispatch(startLoading());
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      const res = await BrandService.approveDeclineWithdraw(data, headers);
      if (res) {
        const {
          data: { data },
        } = res;
        toast.info(res.data.message);
        // data.isFeatured = data.isFeatured === 0 ? false : true;
        // dispatch(saveBrand({ brand: data }));
      }

      dispatch(stopLoading());
      return res;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
