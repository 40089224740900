import React, { useState } from "react";
import {
  Pagination,
  Table,
  Icon,
  Confirm,
  Label,
  Dropdown,
  Select,
  Button,
  Form,
} from "semantic-ui-react";
import { apiCallGet, apiCallPost } from "../../../../components/Axios/Axios";
import { useEffect } from "react";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import { calculateBal, limitCharacters } from "../../../../Assets/data/utils";
import ViewDeposit from "../ViewDeposit";
import ViewWithdraw from "../ViewWithdraw";

const transactionOpt = [
  { key: "DEPOSIT", text: "DEPOSIT", value: "Deposit" },
  { key: "WITHDRAW", text: "WITHDRAW", value: "Withdraw" },
];

const TransactionList = ({
  history,
  page,
  setPage,
  transactionList,
  transactionData,
  Withdraw,
  withdrawList,
  approvalDecline,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [transactionType, setTransactionType] = useState(
    transactionOpt[0].value
  );
  const [approvalDeclineData, setApprovalDeclineData] = useState({});
  const [status, setStatus] = useState("");

  const tokenOptions = [
    {
      value: "Polygon",
      text: "POLYGON",
    },
  ];

  const handlePageClick = (e, pageInfo) => {
    let pages = pageInfo.activePage;
    setPage(pages);
    transactionData({
      page: pageInfo.activePage,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
  };

  const handlePageClicks = (e, pageInfo) => {
    let pages = pageInfo.activePage;
    setPage(pages);
    Withdraw({
      page: pageInfo.activePage,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
  };
  const handleChange = (e, data) => {
    setTransactionType(data.value);
  };

  useEffect(() => {
    Withdraw({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
    transactionData({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
  }, []);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    transactionData({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
    Withdraw({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
  };

  return (
    <>
      <Confirm
        header="Delete FAQ"
        content="Are you sure you want to delete this FAQ?"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
        }}
      />
      <div className="pageHeading">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Transaction's</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Form.Field
              className="search_field"
            >
              {/* <input
                placeholder="Search"
                className="search"
                value={search}
                onChange={handleSearch}
              /> */}
            </Form.Field>
            <Dropdown
              className="select_dropdown"
              placeholder="Tranction type"
              selection
              options={transactionOpt}
              onChange={handleChange}
              value={transactionType}
              def
            />
            <Dropdown
              className="select_dropdown"
              placeholder="Select currency"
              selection
              options={tokenOptions}
            />
          </div>
          {/* <Button className="addButton" onClick={goToAddFaqpage}>
                        Add More Faq's
                    </Button> */}
        </div>
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        {transactionType === "Deposit" && (
          <>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">txid</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">From</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">To</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Amount</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Status</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {transactionList?.data?.list &&
              transactionList?.data?.list?.length > 0 ? (
                transactionList?.data?.list?.map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.tx_id ? limitCharacters(item.tx_id, 20) : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.address_from
                        ? limitCharacters(item.address_from, 20)
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.address_to
                        ? limitCharacters(item.address_to, 20)
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.amount ? limitCharacters(calculateBal(item.amount), 20) : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      <Table.Cell collapsing colSpan="2" textAlign="left">
                        {item.status == 0
                          ? "PENDING"
                          : item.status == 2
                          ? "CONFIRMED"
                          : item.status == 1
                          ? "UNCONFIRMED"
                          : "PROCESSING"}
                      </Table.Cell>
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      <ViewDeposit
                        UserDeposit={item}
                        myKycActions={transactionData}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell collapsing colSpan="18" textAlign="center">
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "bolder",
                        fontSize: "15px",
                      }}
                    >
                      No Record Found.
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </>
        )}

        {transactionType === "Withdraw" && (
          <>
            <Confirm
              open={open}
              content={`Are you sure, you want to ${status.toLocaleUpperCase()} this transaction?`}
              onCancel={() => setOpen(false)}
              onConfirm={() => {
                setOpen(false);
                approvalDecline(approvalDeclineData);
              }}
            />
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">txid</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">From</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">To</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Amount</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Status</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {console.log("===", withdrawList.data?.list)}
            <Table.Body>
              {withdrawList?.data?.list &&
              withdrawList?.data?.list?.length > 0 ? (
                withdrawList?.data?.list?.map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.tx_id ? limitCharacters(item.tx_id, 20) : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.from_address
                        ? limitCharacters(item.from_address, 20)
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.to_address
                        ? limitCharacters(item.to_address, 20)
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item?.amount ? limitCharacters(calculateBal(item.amount), 20) : "N/A"}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      <Table.Cell collapsing colSpan="2" textAlign="left">
                        {item.status == 0
                          ? "PENDING"
                          : item.status == 2
                          ? "CONFIRMED"
                          : item.status == 1
                          ? "UNCONFIRMED"
                          : "PROCESSING"}
                      </Table.Cell>
                    </Table.Cell>
                    <Table.Cell collapsing colSpan="2" textAlign="left">
                      {item.status == 0 ? (
                        <>
                          <Button
                            positive
                            onClick={() => {
                              let data = {
                                amount: item.amount,
                                withdraw_id: item.id,
                                isApproved: "approved",
                                password: "2R:H8udp7{);-:+L5t6",
                                blockChainName: "matic",
                                symbol: "matic",
                              };
                              setStatus("approve");
                              setOpen(true);
                              setStatus("approve");
                              setApprovalDeclineData(data);
                            }}
                          >
                            Approve
                          </Button>

                          <Button
                            negative
                            onClick={() => {
                              let data = {
                                amount: item.amount,
                                withdraw_id: item.id,
                                isApproved: "declined",
                                password: "2R:H8udp7{);-:+L5t6",
                                blockChainName: "matic",
                                symbol: "matic",
                              };
                              setOpen(true);
                              setStatus("decline");
                              setApprovalDeclineData(data);
                            }}
                          >
                            Decline
                          </Button>
                        </>
                      ) : item.status == 2 ? (
                        <>Approved</>
                      ) : item.status == 4 ? (
                        <>Declined</>
                      ) : (
                        <>Processing</>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <ViewWithdraw
                        UserWithdraw={item}
                        myKycActions={Withdraw}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell collapsing colSpan="18" textAlign="center">
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "bolder",
                        fontSize: "15px",
                      }}
                    >
                      No Record Found.
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </>
        )}
      </Table>
      {transactionType === "Deposit" && (
        <>
          {transactionList?.data?.list &&
          transactionList?.data?.count > GlobalVariables.PAGE_LIMIT ? (
            <Pagination
              onPageChange={handlePageClick}
              defaultActivePage={0}
              totalPages={
                Math.ceil(
                  parseInt(transactionList?.data?.count) /
                    GlobalVariables.PAGE_LIMIT
                ) || 1
              }
            />
          ) : (
            ""
          )}
        </>
      )}

      {transactionType === "Withdraw" && (
        <>
          {withdrawList?.data?.list &&
          withdrawList?.data?.count > GlobalVariables.PAGE_LIMIT ? (
            <Pagination
              onPageChange={handlePageClicks}
              defaultActivePage={0}
              totalPages={
                Math.ceil(
                  parseInt(withdrawList?.data?.count) /
                    GlobalVariables.PAGE_LIMIT
                ) || 1
              }
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default TransactionList;
