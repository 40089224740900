import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";

/**
 * @function login
 * @param {email, password} data
 * @returns /login Api response
 */
export const login = (data) => async (dispatch) => {
  try {
    const { saveLoginDetails } = PersistActions;
    let res = await ApiService.login(data);
    if (res) {
      const {
        data: {
          data: { userId, accessToken },
          message,
        },
      } = res;
      /** Save login details */
      localStorage.setItem("authorizationToken",accessToken);
      toast.success(message);
      dispatch(saveLoginDetails({ user: userId, token: accessToken }));
    }
  } catch (error) {
    throw error;
  }
};

export const searchBar = async (data) => {
  try {
    let res = await ApiService.searchBar(data);
    //if (res.data.data[0]) toast.success(res.message);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateLanguageSetting = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.updateLanguageSetting(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getLanguageSetting = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getLanguageSetting(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const creatorUserCount = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.creatorUserCount(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
