import { fetch } from "./Fetch";
import Enviroments from "../_constants/Enviroment";

const { API_HOST } = Enviroments;

// user api stump up
const getArtist = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/user/getAllUsers/${data.limit}/${data.page}`,
    { search: data.search, role: data.role },
    headers
  );
//  Campaign api stump up
const getCampaign = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/campaign/getAllCampaigns/${data.limit}/${data.page}`,
    // { search: data.search },
    data,
    headers
  );

const getCreator = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/users/api/v1/allusers/2/${data.limit}/${data.page}`,
    {},
    headers
  );
const getDeclinedList = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/users/api/v1/allusers/3/${data.limit}/${data.page}`,
    {},
    headers
  );
const getSumsubDeclinedList = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/user/rejected_sumsub_kyc_list/${data.limit}/${data.page}`,
    {},
    headers
  );

const createArtist = (data, headers) =>
  fetch("post", `${API_HOST}/artists/api/v1/create`, data, headers);
const getArtistById = (data, headers) =>
  fetch("get", `${API_HOST}/artists/api/v1/${data.id}`, {}, headers);
const updateArtist = (data, headers) =>
  fetch("put", `${API_HOST}/artists/api/v1/${data.id}`, data, headers);

const createNft = (data, headers) =>
  fetch("post", `${API_HOST}/nft/api/v1/addNft`, data, headers);

const createBanner = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/banner/add`, data, headers);

const getBanner = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/banner/list/${data.limit}/${data.page}`,
    {},
    headers
  );
const getBannerById = (data, headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/banner/${data.id}`, {}, headers);
const updatebanner = (data, headers) =>
  fetch("put", `${API_HOST}/admin/api/v1/banner/${data.id}`, data, headers);

const uploadImage = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/banner`, data, headers);

const deleteArtistById = (data, headers) =>
  fetch("delete", `${API_HOST}/artists/api/v1/${data}`, {}, headers);

const blockuserById = (data, headers) =>
  fetch(
    "put",
    `${API_HOST}/admin/api/v1/user/user-edit/${data.userid}`,
    data,
    headers
  );

// Nft Campaign Service
const getNftCampaign = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/nft/getNftCampaigns/${data.limit}/${data.page}`,
    // { search: data.search },
    data,
    headers
  );

const getNfts = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/api/v1/admin/nft/getNftList/${data.limit}/${data.page}`,
    data,
    headers
  );

const ArtistService = {
  getArtist,
  getCreator,
  uploadImage,
  createArtist,
  getArtistById,
  updateArtist,
  createNft,
  createBanner,
  getBanner,
  getBannerById,
  updatebanner,
  deleteArtistById,
  blockuserById,
  getDeclinedList,
  getSumsubDeclinedList,
  getCampaign,
  getNftCampaign,
  getNfts,
};

export default ArtistService;
