import React from "react";

const NftData = ({ nftDetail }) => {
  const nftdata = [
    {
      id: "1",
      description:
        "Taking his origins from the technological hub of Tokyo,Shin Oni is the new god of innovation and technology",
      address: "Contract Address",
      token: "0x495f...7b5e",
      tokenidname: "Token ID",
      tokenid: "ERC-2055",
      tokenstandard: "Token Standard",
      tokenidstandard: "ERC-2055",
      blockchain: "Blockchain",
      blockchaintoken: "ERC-2055",
      metadata: "Metadata",
      metadatatoken: "ERC-2055",
      creatorroyalties: "Creator Royalties",
      creatorno: "50%",
    },
  ];

  return (
    <>
      {nftdata.map((item, index) => {
        return (
          <>
            <p>{nftDetail?.description}</p>
            <div className="fan_creator">
              <div className="fan_innerdata">
                <div className="fan_info">
                  <div className="tab_input">
                    <p>
                      {item.address}{" "}
                      <span className="add_clr">{item.token}</span>
                    </p>
                  </div>
                  <div className="tab_input">
                    <p>
                      {item.tokenidname} <span>{item.tokenid}</span>
                    </p>
                  </div>
                  <div className="tab_input">
                    <p>
                      {item.tokenstandard} <span>{item.tokenidstandard}</span>
                    </p>
                  </div>
                  <div className="tab_input">
                    <p>
                      {item.blockchain} <span>{item.blockchaintoken}</span>
                    </p>
                  </div>
                  <div className="tab_input">
                    <p>
                      {item.metadata} <span>{item.metadatatoken}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab_input mt-24">
                <p className="metadata">
                  {item.creatorroyalties} <span>{item.creatorno}</span>
                </p>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default NftData;
