import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { BannerActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import BannerList from "./list/BannerList";
import "./banner.scss";

const Banner = () => {
  const dispatch = useDispatch();
  const [banner, setBanner] = useState({ count: 0, rows: [] });

  const getBanner = useCallback(
    async (data) => {
      const { getBanners } = BannerActions;
      const res = await dispatch(getBanners(data));
      if (res) {
        const {
          data: { data },
        } = res;
        setBanner(data);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    getBanner({ page: 0, limit: PAGE_LIMIT });
  }, [getBanner]);

  return (
    <>
      <MainLayout>
        <BannerList getBanner={getBanner} banner={banner} />
      </MainLayout>
    </>
  );
};

export default withRouter(Banner);
