import {
  Button,
  Confirm,
  Form,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import React, { useState } from "react";

import GlobalVariables from "../../../../_constants/GlobalVariables";
import { Link } from "react-router-dom";
import { apiCallPost } from "../../../../components/Axios/Axios";
import { limitCharacters } from "../../../../Assets/data/utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ListFaq = ({ history, page, setPage }) => {
  const [open, setOpen] = useState(false);
  const [faqList, setFaqList] = useState({});
  const [deleteFaqId, setDeleteFaqId] = useState(null);
  const [search, setSearch] = useState("");

  const faqListData = (data) => {
    console.log(data, "faq data");
    apiCallPost(
      `/api/v1/admin/faq/list/${data.limit}/${data.page}`,
      {
        isDeleted: 0,
        isActive: 1,
        search: data.search,
      },
      {}
      // "toastOn"
    )
      .then((results) => {
        if (results) {
          setFaqList(results);
          setPage(data.page);
        }
      })
      .catch((error) => {
        console.log("TAG", error);
      });
  };

  const handlePageClick = (e, pageInfo) => {
    faqListData({
      page: pageInfo.activePage,
      limit: GlobalVariables.PAGE_LIMIT,
      search: search,
    });
  };

  // const goToAddFaqpage = () => {
  //     console.log(history, "pathhhhhhhhhhhhhhhhhhhhhhhh");
  //     history.push("/panel/faq/add-faq");
  // }
  const deleteFaq = (data) => {
    var payload = { id: data, isDeleted: 1, isActive: 0 };
    apiCallPost(`api/v1/admin/faq/update`, payload, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          console.log("TAG2 Delete", res);
          if (!res.error) {
            const { PAGE_LIMIT } = GlobalVariables;
            faqListData({
              page: 1,
              limit: GlobalVariables.PAGE_LIMIT,
              search: search
            });
          }
        }
      })
      .catch((err) => { });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    faqListData({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
  };

  useEffect(() => {
    faqListData({ page: 1, limit: GlobalVariables.PAGE_LIMIT, search: search });
  }, []);
  return (
    <>
      <Confirm
        header="Delete FAQ"
        content="Are you sure you want to delete this FAQ?"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          deleteFaq(deleteFaqId);
        }}
      />
      <div className="pageHeading">
        <div className="admin_dropdown d-sm-flex"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Faq's</h3>
          <div className="d-sm-flex"
            style={{
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap:"0.8rem",
            }}
          >
            <Form.Field
              className="search_field"
            >
              <input
                placeholder="Search"
                className="search"
                value={search}
                onChange={handleSearch}
              />
            </Form.Field>
            <Link to={"/panel/faq/add-faq"}>
              <Button className="addButton">Add More Faq's</Button>
            </Link>
          </div>
          {/* <Button className="addButton" onClick={goToAddFaqpage}>
                        Add More Faq's
                    </Button> */}
        </div>
        <p>List of Faq's</p>
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Serial no.</Table.HeaderCell>
            <Table.HeaderCell>Question</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {faqList?.data?.rows && faqList?.data?.count > 0 && faqList?.data?.rows.length > 0 ? (
            faqList?.data?.rows.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell>{page * 10 - 10 + i + 1}</Table.Cell>
                <Table.Cell>
                  {row.question ? limitCharacters(row?.question, 20) : "N/A"}
                </Table.Cell>
                <Table.Cell>
                  {row.answer ? limitCharacters(row?.answer, 20) : "N/A"}
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/panel/faq/update-faq/${row.id}`}>
                    <Icon name="edit" />
                  </Link>
                  {/* <Link to={`/panelsetOpen/banner/edit-banner`}>
                                        <Icon name="edit" />
                                    </Link> */}
                  <Icon
                    style={{ marginLeft: "30px" }}
                    name="delete"
                    onClick={() => {
                      setOpen(true);
                      setDeleteFaqId(row.id);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Record Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {faqList?.data?.count > GlobalVariables.PAGE_LIMIT &&
        faqList?.data?.rows ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(
              parseInt(faqList?.data?.count) / GlobalVariables.PAGE_LIMIT
            ) || 1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ListFaq;
