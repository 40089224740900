import Types from "../_actions/_types";

const initialState = {
  brand: {},
};

const brand = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_BRAND:
      return { ...state, brand: payload.brand };

    default:
      return state;
  }
};

export default brand;
