import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { KycActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import KycList from "./List/kycList";
import "./kyc.scss";
//import { getKyc } from "../../../redux/_actions/kyc.action";

const UserKycList = () => {
  const dispatch = useDispatch();
  const [userKyc, setUserKyc] = useState({ rows: [], count: 0 });

  useEffect(() => {}, [JSON.stringify(userKyc)]);
  const getKyc = useCallback(
    async (data) => {
      const { getKyc } = KycActions;
      const res = await dispatch(getKyc(data));
      if (res) {
        const {
          data: { data },
        } = res;
        setUserKyc(data);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    getKyc({ id: 3, page: 0, limit: PAGE_LIMIT });
  }, [getKyc]);

  const kycActionsData = () => {
    const { PAGE_LIMIT } = GlobalVariables;
    getKyc({ id: 3, page: 0, limit: PAGE_LIMIT });
  };
  return (
    <>
      <MainLayout>
        <KycList
          getKyc={getKyc}
          userKyc={userKyc}
          kycActionsData={kycActionsData}
          // deleteArtist={deleteArtist}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(UserKycList);
