import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BrandActions } from "../../../redux/_actions";
import AddUserAdmin from "./AddUserAdmin";

const AdminEditUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState("");

  const dispatch = useDispatch();
  const userDetails = async (id) => {
    const { userList } = BrandActions;
    const data = await dispatch(userList(id));
    const details = data?.data?.data?.userDetails[0];
    setUserData(data?.data?.data?.userDetails[0]);

    console.log("details", details);
  };

  useEffect(() => {
    userDetails(id);
  }, [id]);

  return (
    <>{userData != "" && <AddUserAdmin isEdit={true} userData={userData} />}</>
  );
};

export default AdminEditUser;
