import { Breadcrumb, Button, Icon, Pagination, Table } from "semantic-ui-react";
import React, { useState } from "react";

import { BannerActions } from "../../../../redux/_actions";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import TableRows from "../TableRows";
import { toast } from "../../../../components/Toast/Toast";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import '../banner.scss';
import no_record from '../../../../Assets/image/no-record-found.png';

const BannerList = ({ getBanner, banner, history }) => {
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const goToAddNftpage = () => history.push("/panel/banner/create-banner");

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActivePage(page);
    getBanner({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };

  const deleteBanner = async (data) => {
    let { deleteBanner } = BannerActions;
    await dispatch(deleteBanner(data)).then((response) => {
      console.log(response, "aaa");
      if (response) {
        getBanner({ page: activePage, limit: GlobalVariables.PAGE_LIMIT });
        toast.success(response?.data?.message);
      }
    });
    // dataRes.then((data) => {

    // });
  };
  return (
    <>
      <div className="pageHeading">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Banner's</h3>

          <Button className="addButton" onClick={goToAddNftpage}>
            Add New Banner
          </Button>
        </div>
        <p>List of banner's which are shown in user homepage</p>
      </div>

      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Serial no.</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Image</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Title</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {banner.rows && banner.rows.length > 0 ? (
            banner.rows.map((row, i) => (
              <TableRows
                key={i}
                data={row}
                index={i}
                deleteBanner={deleteBanner}
                activePage={activePage}
              />
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  <img src={no_record} alt="not-found-img" />

                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {banner.rows && banner.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={activePage}
          totalPages={
            Math.ceil(parseInt(banner.count) / GlobalVariables.PAGE_LIMIT) || 1
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(BannerList);
