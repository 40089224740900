import { LoadingActions, PersistActions } from ".";

import ApiService from "../../Services/Api.service";
import Types from "./_types";
import { toast } from "../../components/Toast/Toast";

export const saveBannerDetails = (payload) => ({
  type: Types.SAVE_BANNER_DETAILS,
  payload,
});

export const saveRafflesDetails = (res) => ({
  type: Types.SAVE_TAX_DETAILS,
  payload: res,
});

export const saveMediaDetails = (res) => ({
  type: Types.SAVE_MEDIA,
  payload: res,
});

export const saveQuotesDetails = (res) => ({
  type: Types.SAVE_QUOTES,
  payload: res,
});

/**
 * @function getBanners
 * @param {{ page, limit }} data
 * @returns /getCollections/limit/page
 */
export const getBanners = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getBanner(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getBannerById
 * @param {{ id }} data
 * @returns
 */
export const getBannerById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getBannerById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;

      if (data.socialInfo && Object.keys(data.socialInfo).length > 0) {
        for (const key in data.socialInfo) {
          if (Object.hasOwnProperty.call(data.socialInfo, key)) {
            data[key] = data.socialInfo[key];
          }
        }
      }

      dispatch(saveBannerDetails({ bannerDetails: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createBanner
 * @param {{ title, subtitle, imageUrl, startDate, endDate}} data
 * @returns /createCollection
 */
export const createBanner = (data) => async (dispatch, getState) => {
  const { startLoading } = LoadingActions;
  try {
    // dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let { title, social, subTitle, image, startDate, endDate } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    image = await dispatch(uploadFile(image, "banners"));

    if (!image) return toast.info("Error: Uploading banner failed");

    // gifImage = await dispatch(uploadFile(gifImage,"banners"));
    // if (!gifImage) return toast.info("Error: Uploading gif failed");

    data = {
      title,
      subTitle,
      image,
      startDate,
      endDate,
      socialInfo: social,
    };
    const res = await ApiService.createBanner(data, headers);
    dispatch(startLoading());
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * @function updatebanner
 * @param {*} data
 * @returns
 */
export const updatebanner = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    
    //dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let { id, gifImage, social, title, subTitle, image, startDate, endDate } =
      data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (image !== undefined && typeof image !== "string") {
      
      image = await dispatch(uploadFile(image, "banners"));
      if (!image) return toast.info("Error: Uploading failed");
    }

    if (gifImage !== undefined && typeof gifImage !== "string") {
      
      gifImage = await dispatch(uploadFile(gifImage, "banners"));
      if (!gifImage) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      socialInfo: social,
      gifImage,
      title,
      subTitle,
      image,
      startDate,
      endDate,
    };
    const res = await ApiService.updatebanner(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const deleteBanner = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  var payload = { id: data, isDeleted: 1, isActive: 0 };
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    // const res = await ApiService.deleteBanner(data, headers);
    const res = await ApiService.updatebanner(payload, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getBrandArtistList = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getBrandArtistList(data, headers);
    dispatch(stopLoading());
    return res?.data?.data;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};




// Tax Benefit

export const getTax = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getTax(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const createTax = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, description } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    image = await dispatch(uploadFile(image, "catagory"));
    if (!image) {
      dispatch(stopLoading());
      return toast.info("Error: Uploading failed");
    }
    
    data = {
      image,
      title,
      description,
    };
    
    const res = await ApiService.createTax(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const updateTaxStatus = (data) => async (dispatch, getState) => {
  console.log(data);
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, description, is_deleted, status, id } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    if (typeof image !== "string") {
      image = await dispatch(uploadFile(image, "catagory"));
      if (!image) {
        dispatch(stopLoading());
        return toast.info("Error: Uploading failed");
      }
    }

    data = {
      id,
      image,
      title,
      description,
      is_deleted,
      status,
    };
    
    console.log("TAG", data);
    const res = await ApiService.updateTaxStatus(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getTaxById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getTaxById(data, headers);
    
    let finalData = res.data?.data;
    console.log("TAG ", finalData);
    dispatch(saveRafflesDetails(finalData));
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const emptyTaxFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveRafflesDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

//Media Coverage

export const getMedia = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getMedia(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const createMedia = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, url } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    image = await dispatch(uploadFile(image, "catagory"));
    if (!image) {
      dispatch(stopLoading());
      return toast.info("Error: Uploading failed");
    }
    
    data = {
      image,
      title,
      url,
    };
    
    const res = await ApiService.createMedia(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getMediaById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getMediaById(data, headers);
    
    let finalData = res.data?.data;
    console.log("TAG ", finalData);
    dispatch(saveMediaDetails(finalData));
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const updateMediaStatus = (data) => async (dispatch, getState) => {
  console.log(data);
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, description, is_deleted, status, id ,url } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    if (typeof image !== "string") {
      image = await dispatch(uploadFile(image, "catagory"));
      if (!image) {
        dispatch(stopLoading());
        return toast.info("Error: Uploading failed");
      }
    }

    data = {
      id,
      image,
      title,
      url ,
      description,
      is_deleted,
      status,
    };
    
    console.log("TAG", data);
    const res = await ApiService.updateMediaStatus(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
export const emptyMediaFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveMediaDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

// Quotes

export const getQuotes = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getQuotes(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const createQuotes = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, description } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    image = await dispatch(uploadFile(image, "catagory"));
    if (!image) {
      dispatch(stopLoading());
      return toast.info("Error: Uploading failed");
    }
    
    data = {
      image,
      title,
      description,
    };
    
    const res = await ApiService.createQuotes(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const getQuotesById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getQuotesById(data, headers);
    
    let finalData = res.data?.data;
    console.log("TAG ", finalData);
    dispatch(saveQuotesDetails(finalData));
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const updateQuotesStatus = (data) => async (dispatch, getState) => {
  console.log(data);
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, title, description, is_deleted, status, id } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    if (typeof image !== "string") {
      image = await dispatch(uploadFile(image, "catagory"));
      if (!image) {
        dispatch(stopLoading());
        return toast.info("Error: Uploading failed");
      }
    }

    data = {
      id,
      image,
      title,
      description,
      is_deleted,
      status,
    };
    
    console.log("TAG", data);
    const res = await ApiService.updateQuotesStatus(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const emptyQuotesFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveQuotesDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};