import * as Yup from "yup";

import { Button, Dropdown, Form, Input, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { useFormik } from "formik";

function AddCampaignLimit({ open, setOpen, setTransactionFees, data }) {
  const schema = Yup.object().shape({
    fees: Yup.number()
        .min(0)
        .required("limit is requied")
       .typeError("Limit must be a number"),
  });

  console.log(data);
  const formik = useFormik({
    initialValues: { fees: data?.fees || "", types: data?.types || "" , limit: data?.limit || "" ,auto_withdraw : data?.auto_withdraw|| "" , minTarget : data?.minTarget || "" , maxTarget : data?.maxTarget ||""},
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {
        autoWithdrawAmount : values.auto_withdraw,
        minimumWithdrawAmount : values.limit,
        campaignMaximumTargetLimit : values.maxTarget,
        campaignMinimumTargetLimit : values?.minTarget,
        oneDayLimit: values.types,
        totalLimit: values.fees,
      };
      setTransactionFees(data);
      formik.resetForm();
      setOpen();
    },
  });

  return (
    <Modal onClose={setOpen} onOpen={setOpen} open={open} className="common_modal">
      <Modal.Header>Limit</Modal.Header>

      <Modal.Content image>
        {" "}
        <Form onSubmit={formik.handleSubmit}>

        {/* <Form.Field>
             <label style={{color:'white'}}>Auto Withdraw Amount </label>
            <Input
              placeholder="Amount"
              types="number"
              name="auto_withdraw"
              id="auto_withdraw"
              onChange={formik.handleChange}
              value={formik.values.auto_withdraw}
            />
          </Form.Field> */}

       
        {/* <Form.Field>
             <label style={{color:'white'}}>Minimum Withdraw Amount </label>
            <Input
              placeholder="Amount"
              types="number"
              name="limit"
              id="limit"
              onChange={formik.handleChange}
              value={formik.values.limit}
            />
          </Form.Field> */}

          {/* <Form.Field>
             <label style={{color:'white'}}>Minimum Campaign Target Limit </label>
            <Input
              placeholder="Limit..."
              types="number"
              name="minTarget"
              id="minTarget"
              onChange={formik.handleChange}
              value={formik.values.minTarget}
            />
          </Form.Field> */}

          {/* <Form.Field>
             <label style={{color:'white'}}>Maximum Campaign Target Limit </label>
            <Input
              placeholder="Limit..."
              types="number"
              name="maxTarget"
              id="maxTarget"
              onChange={formik.handleChange}
              value={formik.values.maxTarget}
            />
          </Form.Field> */}

          <Form.Field>
             <label style={{color:'white'}}>Campaign One Day Limit </label>
            <Input
              placeholder="Limit..."
              types="number"
              name="types"
              id="types"
              onChange={formik.handleChange}
              value={formik.values.types}
            />
          </Form.Field>

          <Form.Field>
            <label style={{color:'white'}}>Campaign Total Limit </label>
            <Input
              placeholder="Limit..."
              types="number"
              name="fees"
              id="fees"
              onChange={formik.handleChange}
              value={formik.values.fees}
            />
          </Form.Field>
          {formik.errors.fees && (
            <p style={{ color: "red" }}>{formik.errors.feesgetTransactionFeeList}</p>
          )}
          <Button secondary onClick={setOpen}>
            Cancel
          </Button>
          <Button type="submit" positive>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default AddCampaignLimit;
