import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";

import MainLayout from "../../../components/HOC/MainLayout";
import { NftActions, TransactionsActions } from "../../../redux/_actions";
import ListWithdrawTransaction from "./List_Transactions/ListWithdrawTransaction";
import GlobalVariables from "../../../_constants/GlobalVariables";

const WithdrawTransaction = () => {
  const dispatch = useDispatch();
  const [withdrawTransaction, setWithDrawTransactions] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState("");
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  useEffect(() => {
    getCoinList();
  }, []);

  useEffect(() => {
    if (selectedCoins) getWithdrawTransactionsList();
  }, [selectedCoins]);

  const getWithdrawTransactionsList = useCallback(async () => {
    const { getWithdrawTransactions } = TransactionsActions;
    const res = await dispatch(
      getWithdrawTransactions({
        page: page || 1,
        limit: GlobalVariables.PAGE_LIMIT,
        coins: selectedCoins.toLowerCase(),
      })
    );
    if (res) {
      setTotalPage(res.data.totalRecords);
      console.log("Transaction----------->", res.data);
      let tx_data = res.data.data.map((item) => ({
        account_id: item.account_id,
        amount: item.amount,
        from_address: item.from_address,
        is_admin_approval: item.is_admin_approval,
        member_id: item.member_id,
        to_address: item.to_address,
        tx_id: item.tx_id,
        id: item.id,
        status: item.status,
        created_at: item.created_at,
      }));
      tx_data = await Promise.all(tx_data);
      setWithDrawTransactions(tx_data);
    }
  });

  const getCoinList = useCallback(async () => {
    const { getActiveCoinList } = NftActions;
    const res = await dispatch(getActiveCoinList());
    const coinName = res.data.coins[0];
    setSelectedCoins(coinName);
    setCoinList(res.data.coins);
  }, [dispatch]);

  const approvalDecline = (data) => {
    const { withdrawTransApprovalDecline } = TransactionsActions;
    dispatch(withdrawTransApprovalDecline(data)).then((_data) => {
      if (_data) {
        getWithdrawTransactionsList({
          page: 1,
          limit: GlobalVariables.PAGE_LIMIT,
          coins: selectedCoins,
        });
      }
    });
  };

  useEffect(() => {
    if (page) getWithdrawTransactionsList();
  }, [page]);

  return (
    <>
      <MainLayout>
        {/* <div
          style={{
            justifyContent: "end",
            display: "flex",
            width: "100%",
          }}
        >
          <select
            class="ui dropdown"
            onChange={(e) => {
              setSelectedCoins(e.target.value);
            }}
          >
            {coinList &&
              coinList.map((item) => {
                return <option value={item}>{item}</option>;
              })}
          </select>
        </div> */}

        <ListWithdrawTransaction
          getWithdrawTransactions={getWithdrawTransactionsList}
          withdrawTransaction={withdrawTransaction}
          approvalDecline={approvalDecline}
          coin={selectedCoins}
          setActiveIndex={setPage}
          activeIndex={page}
          totalPage={totalPage}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(WithdrawTransaction);
