import React, { useEffect, useState } from "react";
import {
  Icon,
  Image,
  Table,
  Dropdown,
  Button,
  Confirm,
} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import GlobalVariables from "../_constants/GlobalVariables";
import { Link } from "react-router-dom";
import ImageVideoModal from "./Modal/ImageVideoModal";
import Enviroments from "../_constants/Enviroment";
import { CollectionActions } from "../redux/_actions";
import axios from "axios";
import creatorIcon from "../Assets/image/feed_img.svg"

const optionsData = [
  {
    key: "inactive",
    text: "inactive",
    value: false,
  },
  {
    key: "active",
    text: "active",
    value: true,
  },
];
const { KLAYTN_URL, MATIC_URL } = Enviroments;

const TableRows = ({
  data,
  isCollection,
  index,
  changeStatus,
  activePage,
  actionCollection,
  setUserModalOpen,
  setUserModalData,
}) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [action, setAction] = useState("");

  return (
    <>
      <Confirm
        header={action + " Action"}
        content={
          modalData?.status?.isActive
            ? ` Are You Sure You Want To Enable ${action}?`
            : ` Are You Sure You Want To Disable ${action}?`
        }
        open={open}
        confirmButton="Yes"
        cancelButton="No"
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          actionCollection(modalData);
        }}
      />
      <Table.Row style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        {isCollection ? (
          <>
            {/*<Table.Cell collapsing colSpan="2" textAlign="left">
              <ImageVideoModal fileType={"image"} logo={data?.banner} />
            </Table.Cell>*/}
            <Table.Cell collapsing colSpan="2" textAlign="left">
              <div className="collectionName">
                {data?.nftLogo ? (
                  <ImageVideoModal
                    fileType={data?.fileType}
                    logo={data?.nftLogo}
                  />
                ) : (
                  <div className="imageTaskWrap"></div>
                )}
                <p>{data.name}</p>
              </div>
            </Table.Cell>
            {/* <Table.Cell collapsing colSpan="3" textAlign="left">
              <p data-tip="" data-for={`${index}`}>
                {data.description.substr(0, 60)}
                {data?.description.length > 60 && "..."}
              </p>
              <ReactTooltip multiline={true} id={`${index}`}>
                <span style={{ width: "60px", whiteSpace: "pre-wrap" }}>
                  {data?.description}
                </span>
              </ReactTooltip>
            </Table.Cell> */}

            {/*<Table.Cell collapsing colSpan="3" textAlign="left">
              {data.brandName}
            </Table.Cell>*/}

            {/*<Table.Cell collapsing colSpan="3" textAlign="left">
              {data.isFeatured ? "Yes" : "No"}
            </Table.Cell>*/}
            <Table.Cell collapsing colSpan="3" textAlign="left">
              <span className="creatorIcon"><img src={creatorIcon} alt="img" /></span>
              {data?.creator || "Vino Costa"}
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {data?.nft_count}
            </Table.Cell>
            {/* <Table.Cell collapsing colSpan="3" textAlign="right">
              {data.nft_count}
            </Table.Cell> */}
            <Table.Cell collapsing colSpan="3" textAlign="right">
              {data?.created_by ? data?.created_by : "second ago"}
            </Table.Cell>
            {/* <Table.Cell collapsing colSpan="3" textAlign="right">
              <Link
                to={`/panel/collection/edit-collection/${data.collectionId}`}
              >
                <Icon name="edit" />
              </Link>
              <Button
                className={data.isActive ? "defaultColor" : "blackColor"}
                onClick={() => {
                  setAction("Collection");
                  setModalData({
                    id: data.collectionId,
                    status: { isActive: data.isActive ? false : true },
                  });
                  setOpen(true);
                }}
              >
                {data.isActive ? "Disable" : "Enable"}
              </Button>
            </Table.Cell> */}
          </>
        ) : (
          <>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {/* {data.collectionId ? data.collectionId : data.nftId} */}
              {activePage * 10 - 10 + index + 1}
            </Table.Cell>
            <Table.Cell collapsing colSpan="2" textAlign="left">
              <ImageVideoModal
                fileType={data?.fileType}
                logo={data?.nftLogo || data?.logo}
              />
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {data.name}
            </Table.Cell>
            {/* <Table.Cell collapsing colSpan="3" textAlign="left">
              {data.nftType}
            </Table.Cell> */}
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {data?.price}
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              <p data-tip="" data-for={`${index}`}>
                {" "}
                {data.description.substr(0, 60)}
                {data?.description.length > 60 && "..."}
              </p>
              <ReactTooltip multiline={true} id={`${index}`}>
                <span style={{ width: "60px", whiteSpace: "pre-wrap" }}>
                  {data?.description}
                </span>
              </ReactTooltip>
            </Table.Cell>

            {data.collection && (
              <>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {data.collection.name}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan="3" textAlign="left">
                  {console.log("data.blockName--->", data.blockName)}
                  {data.txId && (
                    <a
                      target="_new"
                      href={
                        data.blockName === "KLAYTN"
                          ? `${KLAYTN_URL}${data.txId}`
                          : `${MATIC_URL}${data.txId}`
                      }
                    >
                      {data?.txId.substr(0, 10)}...
                    </a>
                  )}
                </Table.Cell> */}
              </>
            )}
            <Table.Cell collapsing colSpan="2" textAlign="left">
              {data.blockName}
            </Table.Cell>
            <Table.Cell collapsing colSpan="3" textAlign="left">
              {/*<Link to={`/panel/nfts/edit-nft/${data?.nftId}`}>
                <Icon name="edit" />
              </Link>*/}
              <Button
                primary
                onClick={() => {
                  setUserModalData(data);
                  setUserModalOpen(true);
                }}
              >
                Show All Data
              </Button>
              <Button
                color={
                  data.activeNft === 1
                    ? "red"
                    : data.activeNft === 4
                      ? "blue"
                      : "grey"
                  // "disableColor" : "enableColor"
                }
                disabled={
                  data.activeNft === 2 ||
                  data.activeNft === 3 ||
                  data.activeNft === 0
                }
                onClick={() => {
                  setOpen(true);
                  setAction("Nft");

                  setModalData({
                    id: data.nftId,
                    status: {
                      isActive: data.activeNft === 1 ? false : true,
                    },
                    creator: data.creator,
                  });
                }}
              >
                {/*{data.isActive === 1 ? "Disable" : "Enable"}    changed by karan  */}
                {data.activeNft === 1
                  ? "Disable"
                  : data.activeNft === 3
                    ? "Deleted"
                    : data.activeNft === 4
                      ? "Enable"
                      : "Proccessing"}
              </Button>
            </Table.Cell>
          </>
        )}
      </Table.Row>
    </>
  );
};

export default TableRows;
