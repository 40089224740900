import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import Types from "./_types";

export const saveRafflesDetails = (res) => ({
  type: Types.SAVE_CATEGORY_DETAILS,
  payload: res,
});

/**
 * @function getraffles
 * @param {{ page, limit }} data
 * @returns /getraffles/limit/page
 */
export const getraffles = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getRaffles(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createRaffles
 * @param {{ image, name, description}} data
 * @returns /create raffles
 */
export const createRaffles = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, name, description } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    image = await dispatch(uploadFile(image, "catagory"));
    if (!image) {
      dispatch(stopLoading());
      return toast.info("Error: Uploading failed");
    }

    data = {
      image,
      name,
      description,
    };

    const res = await ApiService.createRaffles(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateRafflesStatus
 * @param {{ page, limit , image, name, description}} data
 * @returns /updateRafflesStatus
 */
export const updateRafflesStatus = (data) => async (dispatch, getState) => {
  console.log(data);
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;

    let { image, name, description, is_deleted, status, id } = data;

    const headers = await dispatch(setHeaders({ isFormdata: false }));
    if (typeof image !== "string") {
      image = await dispatch(uploadFile(image, "catagory"));
      if (!image) {
        dispatch(stopLoading());
        return toast.info("Error: Uploading failed");
      }
    }

    data = {
      id,
      image,
      name,
      description,
      is_deleted,
      status,
    };

    console.log("TAG", data);
    const res = await ApiService.updateRaffleStatus(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getRafflesById
 * @param {{ page, limit }} data
 * @returns /getRafflesById/limit/page
 */
export const getRafflesById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getRafflesById(data, headers);

    let finalData = res.data?.data;
    console.log("TAG ", finalData);
    dispatch(saveRafflesDetails(finalData));
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function getNftById
 * @param {{ page, limit }} data
 * @returns /getNftById/limit/page
 */
export const emptyRafflesFormReducer = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(saveRafflesDetails({}));
    dispatch(stopLoading());
    return {};
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const deleteCategoryById = (id) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.deleteCategoryById(id, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      console.log(res);
      toast.success(res?.data?.message);
      // data.isFeatured = data?.isFeatured === 0 ? false : true;
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
