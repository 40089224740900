import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { Button, Form, Icon, Image, Grid } from "semantic-ui-react";
import { FormField } from "../../../../components/FormField";
import MainLayout from "../../../../components/HOC/MainLayout";
import { toast } from "../../../../components/Toast/Toast";
import { BannerActions } from "../../../../redux/_actions";
import { validateUrls } from "../../../../_validators/customValidators";
import { ValidateImage } from "../../../../Services/Validation";
import { Row } from "antd";
import Col from "antd/es/grid/col";

const CreateBanner = ({
  match: {
    params: { id },
  },
  history,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState({ image: "" });
  const [isEdit, setIsEdit] = useState(false);
  const banner = useSelector((state) => state.banner.bannerDetails);
  const goBack = () => history.goBack();
  const onImageChange = async (event) => {
    const files = event.target.files;
    const name = event.target.name;
    let height;
    let width;
    let isImageValid = false;

    if (files.length === 0)
      return toast.error(
        "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
      );

    const file = files[0]; /** get file from files array */
    let ext = file.name.split(".").pop();
    ext = ext.toLowerCase();
    // let ext = file.name.split(".")[1]; /** get ext of image to validate */
    ext = ext.toLowerCase();

    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif") {
      const fileSize = file.size / 1024 / 1024 / 1024;
      if (fileSize > 3000)
        return toast.error("Image should be less than or equal to 3MB");
      const blob = URL.createObjectURL(file);
      if (name === "image") {
        height = 661;
        width = 1640;
        isImageValid = await ValidateImage({
          event: blob,
          wid: width,
          hei: height,
        });
        // setImages({ ...images, image: blob });
        //  if (isImageValid) {
        // setImages({ ...images, image: blob });
        setImages({ ...images, image: blob });
        // } else {
        //   document.getElementById("image").value = null;
        // }
      } else {
        if (ext !== "gif") {
          document.getElementById("gifImage").value = "";
          return toast.error("Image must be a gif image.");
        }
        height = 220;
        width = 220;
        isImageValid = await ValidateImage({
          event: blob,
          wid: height,
          hei: width,
        });
        // setImages({ ...images, gifImage: blob });
        if (isImageValid) {
          // setImages({ ...images, gifImage: blob });
          setImages({ ...images, gifImage: blob });
        } else {
          document.getElementById("gifImage").value = "";
        }
      }
      if (!isImageValid) {
        // return toast.error(
        //   `Please add a valid dimension ${name} image of width: ${width}px & Height: ${height}px`
        // );
      }
    } else {
      return toast.error(
        "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
      );
    }
  };

  const submitForm = async (data) => {
    const { createBanner, updatebanner } = BannerActions;
    data.social = {
      facebook: data.facebook || "",
      website: data.website || "",
      youtube: data.youtube || "",
      instagram: data.instagram || "",
      tiktok: data.tiktok || "",
      twitch: data.twitch || "",
      snapchat: data.snapchat || "",
      twitter: data.twitter || "",
    };

    for (const key in data.social) {
      if (Object.hasOwnProperty.call(data.social, key)) {
        const reg = new RegExp("^(http|https)://", "i");
        if (
          data.social[key].trim().length > 0 &&
          !data.social[key].match(reg)
        ) {
          data.social[key] = `https://${data.social[key]}`;
        }
        if (data[key]) delete data[key];
      }
    }

    if (!isEdit) {
      console.log("m hewre");
      dispatch(
        createBanner({
          ...data,
          startDate: "2021-12-15 13:05:54",
          endDate: "2021-12-15 13:05:54",
        })
      ).then((data) => {
        if (data) {
          toast.success(data?.data?.message);
          console.log(data, "ddd");
          history.goBack();
        }
      });
    } else {
      if (!data.image) data.image = banner.image;
      // if (!data.gifImage) data.image = banner.gifImage;
      data.id = id;
      console.log("data", data);
      dispatch(updatebanner(data)).then((data) => {
        if (data) {
          toast.success(data?.data?.message);
          console.log("data", data);
          history.goBack();
        }
      });
    }
  };

  const setImageFromApi = useCallback((data) => {
    if (Object.keys(data).length > 0) {
      setImages({ image: data.image });
    }
  }, []);

  useEffect(() => {
    const { getBannerById, saveBannerDetails } = BannerActions;

    if (id) {
      dispatch(getBannerById({ id }));
      setIsEdit(true);
    }

    return () => {
      dispatch(saveBannerDetails({ bannerDetails: {} }));
    };
  }, [dispatch, id]);

  useEffect(() => {
    setImageFromApi(banner);
  }, [banner, setImageFromApi]);
  const onFinish = async (values) => {
    console.log("Success:", values);
  };
  return (
    <MainLayout>
      <div className="createNft_area">
        <div className="new__creater">
          <div className="sub_title">
            <h3> {isEdit ? "Update" : "Add"} Banner</h3>
            <Button className="addButton" onClick={goBack}>
              Back
            </Button>
          </div>

          <div className="create-nft-form create-nft-form-new">
            <Form
              autoComplete="off"
              autoFocus="off"
              className="fv-form"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="upload_img">
                {images.image && (
                  <Image src={images.image} width="150" height="70" />
                )}
                <Form.Field className="uploadright">
                  {/* <div className="ant-upload-list ant-upload-list-picture"></div> */}
                  <p>Image, Video, Audio, or 3D Model</p>
                  <span>JPG, JPEG,PNG, GIF</span>
                  <br />
                  <span>Max width: 1336px & Max Height: 531px</span>
                  <div className="uploadbtn">
                    <Field
                      id="image"
                      component={FormField}
                      name="image"
                      type="file"
                      placeholder="upload a file"
                      onImageChange={onImageChange}
                      validate={[required()]}
                    />
                  </div>
                  {/* <p> Banner size (width:1920px, height: 726px )</p> */}
                </Form.Field>
              </div>
              <Row gutter={{ xs: 25, md: 30, xl: 40 }} className="field_row">
                <Col xl={8}>
                  <Form.Field>
                    <label className="">Banner title</label>
                    <Field
                      component={FormField}
                      name="title"
                      type="text"
                      maxLength={20}
                      placeholder="Title"
                      validate={[required()]}
                    />
                  </Form.Field>
                </Col>
                <Col xl={8}>
                  <Form.Field
                    className="cncl_buttons"
                    style={{ "margin-top": "47px" }}
                  >
                    <div className="new__creater__form__submit__data">
                      <button type="submit" className="ant-btn ant-btn-primary">
                        {" "}
                        {isEdit ? "Save Changes" : "Submit"}
                      </button>
                    </div>
                  </Form.Field>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state.banner.bannerDetails,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: "CreateBanner", enableReinitialize: true })
)(CreateBanner);
// export default reduxForm({
//   form: "CreateBanner", // a unique identifier for this form
//   initialValues: { title: "hello" },
// })(CreateBanner);
