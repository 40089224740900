import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { Button, Input, Radio, Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import { UserActions } from "../../../redux/_actions";

const LanguageSetting = () => {
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   getLangStatus();
  // }, []);

  const getLangStatus = () => {
    const { getLanguageSetting } = UserActions;
    dispatch(getLanguageSetting()).then((res) => {
      console.log(res);
      setStatus(res.data.data.status);
    });
  };

  const updateLanguageStatus = (e, checked) => {
    const { updateLanguageSetting } = UserActions;
    dispatch(updateLanguageSetting({ status: checked })).then((res) => {
      console.log(res);
      if (res) {
        getLangStatus();
      }
    });
  };

  return (
    <>
      <MainLayout>
        <div>
          <div className="pageHeading">

          <h4>Settings</h4>
          </div>
          <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Section</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Status</Table.HeaderCell>

                <Table.HeaderCell colSpan="5">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {/* <Table.Body>
              <Table.Row>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  Language Setting
                </Table.Cell>{" "}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  On
                </Table.Cell>{" "}
                {status && (
                  <Table.Cell collapsing colSpan="2" textAlign="left">
                    <Radio
                      toggle
                      onChange={(e, d) => updateLanguageStatus(e, d.checked)}
                      defaultChecked={+status === 0 ? false : true}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            </Table.Body> */}
          </Table>
        </div>
      </MainLayout>
    </>
  );
};

export default withRouter(LanguageSetting);
