import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { date } from "redux-form-validators";
import { Button, Form, Image } from "semantic-ui-react";
import MainLayout from "../../../../components/HOC/MainLayout";
import { toast } from "../../../../components/Toast/Toast";
import { CollectionActions, NftActions } from "../../../../redux/_actions";
import CreateNftForm from "./CreateNftForm";

const CreateNft = ({
  match: {
    params: { id },
  },
  history,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [updateNft, setUpdateNft] = useState({});
  const [creatorsData, setCreatorsData] = useState({});
  const [blockChainData, setBlockChainData] = useState([]);
  const [rfValue, setrfValue] = useState();
  const [raffleId, setrfId] = useState();

  const goBack = () => history.goBack();

  const getCollections = useCallback(
    async (data) => {
      const { getCollections } = CollectionActions;
      const res = await dispatch(getCollections(data));
      if (res) {
        let {
          data: {
            data: { rows },
          },
        } = res;
        rows = rows.map((row) => ({
          value: row["collectionId"],
          lable: row["name"],
        }));
        rows = await Promise.all(rows);
        setCollections(rows);
      }
    },
    [dispatch]
  );

  const getCreatorList = useCallback(
    async (data) => {
      const { getCreatorList } = CollectionActions;
      const res = await dispatch(getCreatorList(data));
      setCreatorsData(res.data.data);
    },
    [dispatch]
  );
  const getBlockChainList = useCallback(async () => {
    const { getBlockChainList } = NftActions;
    const res = await dispatch(getBlockChainList());
    let list = [];

    console.log("Data--------->", typeof res, res.data);
    res.data.data.map((item) => {
      item.blockChainName === "All"
        ? console.log("ALl")
        : list.push({
            key: item.id,
            value: item.id,
            lable: item.blockChainName,
          });
    });

    setBlockChainData(list);
  }, [dispatch]);

  useEffect(() => {
    getBlockChainList();
  }, [getBlockChainList]);

  useEffect(() => {
    getCreatorList();
  }, [getCreatorList]);

  const setRafflesId = (id) => {
    setrfId(id);
  };
  const submitForm = async (data, obj) => {
    data.isActive = await parseInt(data.isActive);

    if (data?.nftType === "FREE") {
      data.price = 0;
      data.minBidCost = 0;
    }
    if (data?.nftType === "SALE") {
      data.minBidCost = 0;
    }

    if (data?.nftType === "RAFFLE") {
      if (raffleId) {
        setrfValue(false);
        data.raffleId = raffleId;
      } else {
        setrfValue(true);
        return;
      }
    }

    if (!isEdit) {
      const { createNft } = NftActions;
      data.supply = 1;
      const res = await dispatch(createNft(data));
      if (res) {
        getNftData();
        toast.success("Nft has been added successfully..!!");
        history.goBack();
      }
    } else {
      const { updateNftStatus } = NftActions;
      data.id = data.nftId;
      const res = await dispatch(updateNftStatus(data)).then((_data) => {
        if (_data?.status === 200) {
          getNftData();
          toast.success("Nft has been updated successfully..!!");
        }
      });
      if (res) {
        toast.success("Nft has been updated successfully..!!");
        history.goBack();
      }
    }
  };

  useEffect(() => {
    getCollections({ page: 0, limit: 1000 });
    const { emptyNftFormReducer } = NftActions;
    if (id) {
      getNftData();
    }
    return () => {
      dispatch(emptyNftFormReducer());
    };
  }, [getCollections]);

  const getNftData = () => {
    const { getNftById } = NftActions;
    if (id) {
      dispatch(getNftById({ id })).then((mydata) => {
        setUpdateNft(mydata?.data?.data?.detail);
      });
      setIsEdit(true);
    }
  };

  return (
    <MainLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>{isEdit ? "Update" : "Add"} NFT</h3>
        <Button className="addButton" onClick={goBack}>
          Back
        </Button>
      </div>

      <div className="create-nft-form">
        <CreateNftForm
          onSubmit={submitForm}
          collections={collections}
          creatorsData={creatorsData}
          isEdit={isEdit}
          blockChainData={blockChainData}
          rfValue={rfValue}
          rafflesId={setRafflesId}
        />
      </div>
    </MainLayout>
  );
};

export default withRouter(CreateNft);
