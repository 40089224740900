import "./SideBar.scss";

import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import React, { useState } from "react";

import { Link } from "react-router-dom";

const Sidebar = () => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <div className="ToggleBtn" onClick={toggleClass}>
        <button className="hamburger__toggle d-block">
          <span className="hamburger__icon"></span>
        </button>
      </div>
      <ProSidebar className={isActive ? "navHide" : "nav_toggled"}>
        <Menu iconShape="square">
          {/* <Link to="/panel/dashboard">
            <MenuItem>Dashboard</MenuItem>
          </Link> */}
          <Link to="/panel/user">
            <MenuItem>Users</MenuItem>
          </Link>
          <Link to="/panel/banner">
            <MenuItem>Banners</MenuItem>
          </Link>

          <Link to="/panel/categories">
            <MenuItem>Categories</MenuItem>
          </Link>
          <Link to="/panel/campaign">
            <MenuItem>Campaigns</MenuItem>
          </Link>
          <Link to="/panel/nft-campaign">
            <MenuItem>Nft Campaign</MenuItem>
          </Link>
          <Link to="/panel/nfts">
            <MenuItem>Nft</MenuItem>
          </Link>
          <Link to="/panel/fee">
            <MenuItem>Fees</MenuItem>
          </Link>
          <Link to="/panel/tax_benefit">
            <MenuItem>Tax Benefit</MenuItem>
          </Link>
          <Link to="/panel/media_coverage">
            <MenuItem>Media Coverage</MenuItem>
          </Link>
          <Link to="/panel/quotes">
            <MenuItem>Quotes</MenuItem>
          </Link>
          {/* <Link to="/panel/faq">
            <MenuItem>FAQ's</MenuItem>
          </Link> */}
          <Link to="/panel/setting">
            <MenuItem>Settings</MenuItem>
          </Link>
        </Menu>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
