import reactImageSize from "react-image-size";

const ValidateImage = async ({ event, wid, hei }) => {
  const { width, height } = await reactImageSize(event);
  if (width === wid && height === hei) {
    return true;
  }
  return false;
};

export { ValidateImage };
