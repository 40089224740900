import { Button, Icon, Input, Table } from "semantic-ui-react";
import React, { useState } from "react";

import AddCampaignLimit from "./AddCampaignLimit";
import { TransactionFeeAction } from "../../../redux/_actions";
import { useDispatch } from "react-redux";

function CampaignLimitList({ feeData, getTransactionFeeList }) {
  const [feeInput, setFeeInput] = useState(false);
  const [feeValue, setFeeValue] = useState({ types: "", fees: "" , limit: "" , auto_withdraw: "" , minTarget:"" , maxTarget: "" });
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const setTransactionFees = async (data) => {
    let tranFee = null;
    if (isEdit === false) {
      const { setTransactionFee } = TransactionFeeAction;
      tranFee = await dispatch(setTransactionFee(data));
    } else {
      const { updateTransactionFee } = TransactionFeeAction;
      tranFee = await dispatch(updateTransactionFee(data));
      if (tranFee) {
        setFeeInput(false);
        getTransactionFeeList();
      }
    }
  };
  return (
    <>
      {open && (
        <AddCampaignLimit
          open={open}
          setOpen={() => setOpen(false)}
          setTransactionFees={setTransactionFees}
          data={feeValue}
        />
      )}
      {/* <div className="pageHeading">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
        </div>
      </div> */}
      <Table.Body>
        {" "}
        {feeData ? (
          <Table.Cell colSpan="4" textAlign="right"> 
                <Button
                  // style={{ marginLeft: "70px"}}
                  onClick={() => {
                    setFeeValue({
                      types: feeData.oneDayLimit,
                      fees: feeData.totalLimit,
                      limit: feeData.minimumWithdrawAmount,
                      auto_withdraw: feeData.autoWithdrawAmount,
                      minTarget:feeData?.campaignMinimumTargetLimit,
                      maxTarget: feeData?.campaignMaximumTargetLimit
                    });
                    setOpen(true);
                    setIsEdit(true);
                  }}>
                    View
                    </Button>
          </Table.Cell>
        ) : (
          <Table.Row>
            <Table.Cell collapsing colSpan="18" textAlign="center">
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "15px",
                }}
              >
                No Record Found.
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </>
  );
}

export default CampaignLimitList;
