import React, { useState } from "react";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";
import { fetch } from "../../../../Services/Fetch";
import Enviroments from "../../../../_constants/Enviroment";

import {
  Button,
  Icon,
  Pagination,
  Table,
  Image,
  Confirm,
} from "semantic-ui-react";
import UserDetails from "../../../../components/UserDetails/UserDetails";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import { BrandActions } from "../../../../redux/_actions";
import { useDispatch } from "react-redux";

const ListNftWithdraw = ({
  getArtist, 
  brands,
  history,
  approverejectuser,
  childFunc,
}) => {
  const { API_HOST } = Enviroments;
  const dispatch = useDispatch();
  const [approvemodel, setApprovemodel] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [rejectmodel, setRejectmodel] = useState(false);
  const [artistId, setArtistId] = useState({
    userId: "",
    nftId: "",
    parentnftId: "",
  });
  const [activeIndex, setActiveIndex] = useState(1);
  const [reason, setReason] = useState("");
  const [userDetalisData, setUserDetalisData] = useState([]);
  const [userCategoryDetalisData, setUserCategoryDetalisData] = useState([]);

  console.log("brands", brands);
  const artist = { data: [{ id: 1 }, { id: 2 }] }; // delete

  const userDetails = async (id) => {
    const { userList } = BrandActions;
    const data = await dispatch(userList(id));
    const details = data?.data?.data?.userDetails[0];
    const UserCategoryDetails = data?.data?.data?.UserCategoryDetails;
    details && setUserCategoryDetalisData(UserCategoryDetails);
    details && setUserDetalisData(details);
    details && setShowDetail(true);
  };
  const AddNewArtist = () => {
    history.push("/panel/artist/create-artist");
  };
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    setActiveIndex(page);
    getArtist({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };
  React.useEffect(() => {
    childFunc.current = chileFunction;
  }, []);

  const chileFunction = () => {
    setApprovemodel(false);
    setRejectmodel(false);
  };
  const rejectInput = () => {
    return (
      <textarea
        maxLength={200}
        rows="4"
        cols="50"
        className="rejecttext"
        value={reason}
        placeholder="Enter Reason"
        onChange={(e) => {
          setReason(e.target.value);
        }}
      />
    );
  };

  let rejectPayload = {
    nftId: artistId.nftId,
    parentnftId: artistId.parentnftId,
    comment: reason,
    status: 2,
    userId: artistId.userId,
  };
  let approvePayload = {
    nftId: artistId.nftId,
    parentnftId: artistId.parentnftId,
    status: 1,
    userId: artistId.userId,
  };
  return (
    <>
      <Confirm
        header="Approve Nft Withdraw Request"
        content="Are you sure you want to approve request?"
        open={approvemodel}
        confirmButton="Yes"
        cancelButton="No"
        onCancel={() => setApprovemodel(false)}
        onConfirm={() => {
          setApprovemodel(approvemodel);
          approverejectuser(approvePayload);
        }}
      />
      <Confirm
        header="Reject Nft Withdraw Request"
        content={rejectInput}
        open={rejectmodel}
        confirmButton="Yes"
        cancelButton="No"
        onCancel={() => setRejectmodel(false)}
        onConfirm={() => {
          setRejectmodel(rejectmodel);
          approverejectuser(rejectPayload);
        }}
      />

      <div className="pageHeading"
      >
        <h3>Nft Withdraw Requests</h3>

        {/* <Button className='addButton' onClick={AddNewArtist}>
          Add New Artist
        </Button> */}
      <p>List of all Nft Withdraw request to allow admin to Approve/Reject</p>
      </div>
      <Table celled striped style={{ overflow: 'auto', maxWidth: 'inherit', }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">#</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Nft's Image</Table.HeaderCell>

            <Table.HeaderCell colSpan="3">Nft Title</Table.HeaderCell>
            {/*<Table.HeaderCell colSpan="2">User Name</Table.HeaderCell>*/}
            <Table.HeaderCell colSpan="3">Nft Id</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">Nft Withdraw Id</Table.HeaderCell>
            {/*<Table.HeaderCell colSpan="2">Active</Table.HeaderCell>*/}
            <Table.HeaderCell colSpan="3">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {brands && brands?.data && brands?.data?.length > 0 ? (
            brands?.data?.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  {activeIndex * 10 - 10 + i + 1}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  <Image
                    src={`${row.logo}?tr=w-80,h-80`}
                    width="80"
                    height="80"
                  />
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {row.nftTitle}
                </Table.Cell>
                {/*<Table.Cell collapsing colSpan="2" textAlign="left">
                  {row.username ? row.username : "NA"}
                </Table.Cell>*/}
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {row.nftId}
                  {/* <ReactTooltip multiline={true} id={`${i}`}>
                    <span style={{ width: '60px', whiteSpace: 'pre-wrap' }}>
                      {row?.description}
                    </span>
                  </ReactTooltip> */}
                </Table.Cell>
                <Table.Cell collapsing colSpan="3" textAlign="left">
                  {row.nftWithdrawId}
                </Table.Cell>
                {/* <Table.Cell collapsing colSpan='2' textAlign='left'>
                  <ImageVideoModal
                    fileType={'Artis Logo'}
                    logo={row.profileImage}
                  />
                </Table.Cell> */}
                {/* <Table.Cell collapsing colSpan='2' textAlign='left'>
                  <ImageVideoModal
                    fileType={'Cover Image'}
                    logo={row.coverImage}
                  />
                </Table.Cell> */}
                {/*<Table.Cell collapsing colSpan="2" textAlign="left">
                  {row?.isActive == 1 ? "Yes" : "No"}
                </Table.Cell>*/}
                <Table.Cell collapsing colSpan="2" textAlign="left">
                  <Button
                    className="ui primary button"
                    color="green"
                    onClick={() => {
                      setArtistId({
                        userId: row?.userId,
                        nftId: row?.nftId,
                        parentnftId: row?.parentnftId || 0,
                      });
                      setApprovemodel(true);
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    className="ui red button"
                    onClick={() => {
                      setArtistId({
                        userId: row?.userId,
                        nftId: row?.nftId,
                        parentnftId: row?.parentnftId || 0,
                      });
                      setRejectmodel(true);
                    }}
                  >
                    Reject
                  </Button>
                  {/* <Button
                    className="ui primary button"
                    onClick={() => userDetails(row?.userId)}
                  >
                    User's Detail
                  </Button> */}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell collapsing colSpan="18" textAlign="center">
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  No Record Found.
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {brands && brands?.count && brands?.count > GlobalVariables.PAGE_LIMIT ? (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(parseInt(brands?.count) / GlobalVariables.PAGE_LIMIT) || 1
          }
        />
      ) : (
        ""
      )}
      {showDetail && (
        <UserDetails
          setOpen={setShowDetail}
          open={showDetail}
          userDetalisData={userDetalisData}
          userCategoryDetalisData={userCategoryDetalisData}
        />
      )}
    </>
  );
};

export default withRouter(ListNftWithdraw);
