import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  // Route,
  Switch,
  // HashRouter
} from "react-router-dom";
import PublicRoutes from "./PublicRoutes/PublicRoute";
import AuthGuard from "./Guards/AuthGuard";
import NoGuard from "./Guards/NoGuard";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import LoaderComponent from "../components/LoaderComponent/LoaderComponent.jsx";
// import { axiosRequest, axiosResponse } from "../components/Axios/Axios";

const Application = () => {
  const [active, setActive] = useState  (false);
  // useEffect(() => {
  //   axiosResponse(setActive);
  //   axiosRequest(setActive);
  // }, []);
  return (
    <React.Fragment>
      <LoaderComponent></LoaderComponent>
      <Router>
        <Switch>
          <AuthGuard path="/panel" component={PrivateRoutes} />
          <NoGuard path="/" component={PublicRoutes} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Application;
