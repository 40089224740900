import { fetch } from "./Fetch";
import Enviroments from "../_constants/Enviroment";

const { API_HOST } = Enviroments;

const login = (data, headers = {}) =>
  fetch("post", `${API_HOST}/admin/api/v1/login`, data, headers);

const getBrands = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/user/photoGrapher_list/${data.limit}/${data.page}`,
    {},
    headers,
    { filter: data.filter }
  );

const getNftWithdraw = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/nft/getWithdrawNftList/${data.limit}/${data.page}`,
    {},
    headers
  );

const createBrand = (data, headers) =>
  fetch("post", `${API_HOST}/brands/api/v1/create`, data, headers);
const getBrandById = (data, headers) =>
  fetch("get", `${API_HOST}/brands/api/v1/${data.id}`, {}, headers);

const updateBrand = (id, data, headers) =>
  fetch("put", `${API_HOST}/brands/api/v1/${id}`, data, headers);

const createNft = (data, headers) =>
  fetch("post", `${API_HOST}/nft/api/v1/addNft`, data, headers);


const createBanner = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/banner/add`, data, headers);

const getBanner = (data, headers) =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/banner/list/${data.limit}/${data.page}`,
    {},
    headers
  );
const getBannerById = (data, headers) =>
  fetch("get", `${API_HOST}/admin/api/v1/banner/${data.id}`, {}, headers);
const updatebanner = (data, headers) =>
  fetch("put", `${API_HOST}/admin/api/v1/banner/${data.id}`, data, headers);

const uploadImage = (data, headers) =>
  fetch("post", `${API_HOST}/admin/api/v1/upload/banner`, data, headers);

const uploadBrandArtistImage = (data, headers) =>
  fetch("post", `${API_HOST}/users/api/v1/upload`, data, headers);

const deleteBrandById = (data, headers) =>
  fetch("delete", `${API_HOST}/brands/api/v1/${data}`, {}, headers);

const approveDeclineuser = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/admin/api/v1/user/verfiyPhotographer`,
    data,
    headers
  );

const approveDeclineWithdraw = (data, headers) =>
  fetch(
    "post",
    `${API_HOST}/admin/api/v1/nft/approveRejectWithrawlNft`,
    data,
    headers
  );

const listUser = (data, headers = "") =>
  fetch(
    "get",
    `${API_HOST}/admin/api/v1/user/get_user_meta_details/${data}`,
    {},
    headers
  );

const BrandService = {
  login,
  getBrands,
  getNftWithdraw,
  uploadImage,
  createBrand,
  getBrandById,
  deleteBrandById,
  updateBrand,
  createNft,
  createBanner,
  getBanner,
  getBannerById,
  updatebanner,
  uploadBrandArtistImage,
  approveDeclineuser,
  approveDeclineWithdraw,
  listUser,
};

export default BrandService;
