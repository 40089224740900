import Types from "../_actions/_types";

const initialState = {
  category: {},
};

const category = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_CATEGORY_DETAILS: {
      
      return { ...state, category: payload };
    }
    default:
      return state;
  }
};

export default category;
