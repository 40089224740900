import axios from "axios";
import { LoadingActions } from "../../redux/_actions";
import Enviroments from "../../_constants/Enviroment";
import { toast } from "../../../src/components/Toast/Toast";
import { storeInstance } from "../..";
import { toasts } from "../Toast/Toasts";

const { API_HOST } = Enviroments;
axios.defaults.baseURL = API_HOST;
function formatUrl(url, params) {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${url}${params}`;
}
function handleError(error) {
  if (
    error?.response?.status &&
    (error?.response?.status === 403 || error?.response?.status === 401)
  ) {
    toasts.error("Please re-login, last login session expired.");
    window.location.reload();
    localStorage.clear();
  } else {
    error?.data?.message && toast.error(error.data.message);
    error?.response?.data?.message && toast.error(error.response.data.message);
  }
}

function handleSuccess(res) {
  if (res.status === 200) {
    res.message && toast.success(res.message);
    res?.data?.message && toast.success(res.data.message);
  }
  if (res.status === 403) {
    res.message && toast.warning(res.message);
  }
}

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      localStorage.getItem("authorizationToken");
    storeInstance.dispatch(LoadingActions.startLoading());
    return config;
  },
  (error) => {
    storeInstance.dispatch(LoadingActions.stopLoading());

    return error;
  }
);

// Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    storeInstance.dispatch(LoadingActions.stopLoading());
    return response;
  },
  (error) => {
    storeInstance.dispatch(LoadingActions.stopLoading());
    throw error;
  }
);
export const apiCallPost = (url, data, params = {}, toastOn) =>
  new Promise((resolve, reject) => {
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error);
        }
        reject(error);
      });
  });

export const apiCallGet = (url, params = {}, toastOn) => {
  return new Promise((resolve) => {
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res);
        }
        resolve(res?.data);
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error);
        }
        resolve(null);
      });
  });
};
export const apiCallPut = (url, data, params = {}, toastOn) =>
  new Promise((resolve) => {
    axios
      .put(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error);
        }
        resolve(null);
      });
  });

export const apiCallPatch = (url, data, params = {}, toastOn) =>
  new Promise((resolve) => {
    axios
      .patch(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error);
        }
        resolve(null);
      });
  });

export const apiCallDelete = (url, params = {}, toastOn) => {
  return new Promise((resolve) => {
    axios
      .delete(formatUrl(url, params))
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error);
        }
        resolve(null);
      });
  });
};
