import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { BrandActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import ListBrand from "./list/ListNftWithdraw";
import { toast } from "../../../components/Toast/Toast";

const NftWithdraw = () => {
  const dispatch = useDispatch();
  const [brands, setBrand] = useState({ count: 0, rows: [] });
  const childFunc = React.useRef(null);
  const getBrands = useCallback(
    async (data) => {
      const { getNftWithdrawRequest } = BrandActions;
      const res = await dispatch(getNftWithdrawRequest(data));
      if (res) {
        // const {
        //   data: { data }
        // } = res
        setBrand(res.data);
      }
    },
    [dispatch]
  );

  // useEffect(() => {
  //   const { PAGE_LIMIT } = GlobalVariables;
  //   getBrands({ id: 2, page: 1, limit: PAGE_LIMIT });
  // }, [getBrands]);

  const approverejectuser = (data) => {
    if (data && data.status === 0 && data.reason == "") {
      toast.info("Please enter a reason!");
      return false;
    }
    const { approveDeclineNftWithdraw } = BrandActions;
    dispatch(approveDeclineNftWithdraw(data)).then((data) => {
      if (data) {
        const { PAGE_LIMIT } = GlobalVariables;
        getBrands({ id: 2, page: 1, limit: PAGE_LIMIT });
        childFunc.current();
      }
    });
  };
  return (
    <>
      <MainLayout>
        <ListBrand
          getBrands={getBrands}
          brands={brands}
          approverejectuser={approverejectuser}
          childFunc={childFunc}
        />
      </MainLayout>
    </>
  );
};

export default withRouter(NftWithdraw);
