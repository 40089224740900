import React, { useState } from "react";
import "./LeftSideBlock.scss";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/cupchair.svg";
import collection from "../../Assets/image/collections.png";
import nft from "../../Assets/image/nft.png";
import banner from "../../Assets/image/banner.png";
import sale from "../../Assets/image/sale.png";
import photo from "../../Assets/image/photo.png";
import categories from "../../Assets/image/categories.png";
import withdraw from "../../Assets/image/withdraw.png";
import user from "../../Assets/image/user.png";
import { Menu } from "antd";
import headericon from "../../Assets/image/headericon.png";
//import { ReactComponent as Logo } from "../../Assets/image/fv_logo_white.sv";
import { DownOutlined } from '@ant-design/icons';

const LeftSideBlock = () => {
  // const [toggleSidebar, setToggleSidebar] = useState(true);
  const persist = useSelector((state) => state.persist);
  // const { isLeftbar } = persist;

  const data = [

    // {
    //   to: "/panel/dashboard",
    //   imageName: collection,
    //   name: "Dashboard",
    // },
    {
      to: "/panel/banner",
      imageName: banner,
      name: "Banners",
    },
    // {
    //   to: "/panel/collection",
    //   imageName: collection,
    //   name: "Collections",
    // },
    // {
    //   to: "/panel/nfts",
    //   imageName: user,
    //   name: "Users & KYCs",
    // },

    {
      to: "/panel/users",
      imageName: user,
      name: "Users",
    },
    // {
    //   to: "/panel/photographers",
    //   imageName: photo,
    //   name: "Creator's Request",
    // },
    // {
    //   to: "/panel/nfts",
    //   imageName: nft,
    //   name: "Listings & orders",
    // },
    // {
    //   to: "/panel/nft-reports",
    //   imageName: user,
    //   name: "Support",
    // },
    // {
    //   to: "/panel/createnftfrom",
    //   imageName: nft,
    //   name: "Create NFT",
    // },
    // {
    //   to: "/panel/createcollectionfrom",
    //   imageName: nft,
    //   name: "Create Collection",
    // },

    {
      to: "/panel/",
      imageName: categories,
      name: "Categories",
    },
     
    {
      to: "/panel/campaign",
      name: "Campaigns",
    },
    {
      to: "/panel/transactions",
      name: "Nft Campaign",
    },
    {
      to: "/panel/tax_benefit",
      name: "Tax Benefit",
    },

    {
      to: "/panel/media_coverage",
      name: "Media Coverage"
    },

    {
      to: "/panel/quotes",
      name: "Quotes"
    },
    {
      to: "/panel/faq",
      imageName: collection,
      name: "FAQ's",
    },
    {
      to: "/panel/fee",
      name: "Fees"
    },
    {
      to: "/panel/transactions",
      name: "Transactions"
    }

    // {
    //   to: "/panel/adminfee",
    //   imageName: banner,
    //   name: "Admin Commission",
    // },
    // {
    //   to: "/panel/transactionFee",
    //   imageName: banner,
    //   name: "Transaction Fee",
    // },
    // {
    //   to: "/panel/nftWithdrawRequest",
    //   imageName: banner,
    //   name: "Nft Withdraw Request",
    // },
    // {
    //   to: "/panel/sell-report",
    //   imageName: sale,
    //   name: "Sale Report",
    // },
    // {
    //   to: "/panel/withdraw-transaction",
    //   imageName: withdraw,
    //   name: "Withdraw Transaction",
    // },
    // {
    //   to: "/panel/types",
    //   imageName: sale,
    //   name: "Add Types",
    // },
    // {
    //   to: "/panel/language-setting",
    //   imageName: sale,
    //   name: "Settings",
    // },
  ];
  const headerManuData = data.map(({ to, imageName, name }) => {
    return {
      label: (
        <NavLink to={to} className="navLinkCustom">
          {name}
        </NavLink>
      ),
    };
  });

  return (
    <div className={`leftBar`}>
      <div className="sidebarNav">
        {/* <ul> */}
        <Menu theme="dark" defaultSelectedKeys={["1"]} items={headerManuData} />

        {/* prefixCls */}
        {/* <li>
            <NavLink to="/panel/types">
              <img src={sale} alt="sale image" />
              Add Types
            </NavLink>
          </li>
          <li>
            <NavLink to="/panel/language-setting">
              <img src={sale} alt="sale image" />
              Settings
            </NavLink>
          </li> */}
        {/* </ul> */}
      </div>
    </div>
  );
};

export default withRouter(LeftSideBlock);
