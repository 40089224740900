import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  apiCallGet,
  apiCallPost,
  apiCallPut,
} from "../../../components/Axios/Axios";
import MainLayout from "../../../components/HOC/MainLayout";
import { toasts } from "../../../components/Toast/Toasts";
import { BrandActions } from "../../../redux/_actions";
import { setUserDetails } from "../../../redux/_reducers/user";
import Enviroments from "../../../_constants/Enviroment";
import "./AddUserAdmin.scss";
import {
  CloudFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CreatorInfo, PersonalInfo } from "./ConstantInputFileds";
import GooglePlace from "./GooglePlace";
import { useHistory } from "react-router";
import moment from "moment";
import { getGeocodeByLatLong, uploadImageVideoFile } from "../../../_utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/_actions/loading.action";
import locationPin from "../../../images/LocationOnTwoTone.svg";
// const creatorFormData = async (values) => {
//   try {
//     const results = await apiCallPost(
//       "users/api/v1/photographer",
//       { ...values },
//       {},
//       "toastOn"
//     );
//     if (results) {
//       console.log(results, "results");
//     }
//   } catch (error) {
//     toasts.error();
//   }
// };

export function UploadIcon() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2148 0.879557C10.2148 0.423828 9.86979 0.0722652 9.41406 0.0722652L0.983072 0.0722656C0.520833 0.0722656 0.175781 0.423828 0.175781 0.879558C0.175781 1.3418 0.520833 1.69336 0.983072 1.69336L5.02604 1.69336C4.85677 1.72591 4.70052 1.81706 4.57682 1.94076L0.553385 5.87305C0.371093 6.04883 0.286458 6.25716 0.286458 6.47201C0.286458 6.95378 0.625 7.29232 1.08073 7.29232C1.32812 7.29232 1.52995 7.18815 1.6862 7.0319L2.91667 5.82096L4.4401 4.14128L4.35547 5.48893L4.35547 11.0684C4.35547 11.5827 4.69401 11.9277 5.19531 11.9277C5.6901 11.9277 6.02865 11.5827 6.02865 11.0684L6.02865 5.48893L5.95052 4.13477L7.46745 5.82096L8.69792 7.0319C8.85417 7.18815 9.05599 7.29232 9.30338 7.29232C9.75911 7.29232 10.0977 6.95378 10.0977 6.472C10.0977 6.25716 10.013 6.04883 9.83073 5.87305L5.8138 1.94076C5.6901 1.81706 5.53385 1.72591 5.35807 1.69336L9.41406 1.69336C9.86979 1.69336 10.2148 1.3418 10.2148 0.879557Z"
        fill="#141029"
      />
    </svg>
  );
}
const AddUserAdmin = ({ isEdit, userData }) => {
  const { API_HOST } = Enviroments;
  const history = useHistory();
  const dispatch = useDispatch();
  const [instagramData, setInstagramData] = useState(true);
  const [twitterData, setTwitterData] = useState(true);
  const [tiktokData, setTiktokData] = useState(true);
  const [onlyfansData, setOnlyfansData] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [radioValue, setradioValue] = useState(1);
  const [userName, setUserName] = useState("");
  const [datePiker, setDatePiker] = useState("");
  const [location, setLocation] = useState("");
  const [coverUplodeUrl, setCoverUplodeUrl] = useState("");
  const [profileUplodeUrl, setProfileUplodeUrl] = useState("");
  const [locationData, setLocationData] = useState("");
  const [defaultLocation, setDefaultLocation] = useState("");
  const [fileList, setFileList] = useState([]);
  const [userNameMessage, setUserNameMessage] = useState("");
  const dateFormat = "YYYY/MM/DD";

  const { id } = useParams();

  const creatorFormData = async (values, isEdit) => {
    try {
      if (isEdit) {
        const results = await apiCallPut(
          `/admin/api/v1/user/${id}`,
          { ...values },
          {},
          "toastOn"
        );
        if (results) {
          history.push("/panel/users");
        }
      } else {
        const results = await apiCallPost(
          "/admin/api/v1/user/createCreatorByAdmin",
          { ...values },
          {},
          "toastOn"
        );
        if (results) {
          history.push("/panel/users");
        }
      }
    } catch (error) {
      toasts.error();
    }
  };

  const creatorUserName = async (username) => {
    setUserNameMessage("");
    dispatch(startLoading());
    try {
      const res = await apiCallGet(
        `users/api/v1/username/${username}`,
        {},
        "toastOff"
      );
      {
        res && setUserNameMessage(res.message);
      }
      dispatch(stopLoading());
    } catch (error) {
      {
        error && setUserNameMessage(error.message);
      }
      dispatch(stopLoading());
    }
  };

  console.log(userName);
  const [form] = Form.useForm();
  const onChange = (checked, s) => {
    console.log(`switch to ${checked}`);
  };
  const validateMessages = {
    required: "${name} is required!",
    types: {
      email: "${name} is not a valid email!",
      name: "${name} mmmm",
      publicEthAddress: "${name} publicEthAddress",
    },
  };
  // const fileUploadChange = (info) => {
  //   if (info.file.status !== "uploading") {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (info.file.status === "done") {
  //     message.success(`${info.file.name} file uploaded successfully`);
  //     console.log(info.file);
  //     setImageUrl(info.file.response.message.location);
  //   } else if (info.file.status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //     console.log(info.file);
  //   }
  // };

  const profileUplode = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      console.log(info.file);
      setProfileUplodeUrl(info.file.response.message.location);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      console.log(info.file);
    }
  };
  const backGroundUplode = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      console.log(info.file);
      setCoverUplodeUrl(info.file.response.message.location);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      console.log(info.file);
    }
  };
  // const values = {
  //   // ...fieldsValue,
  //   name: fieldsValue["name"],
  //   dial_code: "",
  //   mobile_no: "",
  //   bio: "",
  //   profileImage: imageUrl,
  //   coverImage: "",
  //   countryName: "",
  //   countryId: "",
  //   comment: "",
  //   email: fieldsValue["email"],
  //   publicEthAddress: fieldsValue["publicEthAddress"] || "",
  //   mintWebsite: fieldsValue["mintWebsite"] || "",

  //   instagramId: fieldsValue["instagramId"] || "",
  //   isInstagramToggled: instagramData === true ? 1 : 0,

  //   twitterId: fieldsValue["twitterId"] || "",
  //   isTwitterToggled: twitterData === true ? 1 : 0,

  //   tiktokId: fieldsValue["tiktokId"] || "",
  //   isTikTokToggled: tiktokData === true ? 1 : 0,

  //   onlyfansId: fieldsValue["onlyfansId"] || "",
  //   isOnlyFansToggled: onlyfansData === true ? 1 : 0,
  // };

  const getImageUrl = (res, key) => {
    let url = res
      .filter((element) => element.key === key)
      .map((elem) => elem.url);
    console.log({ url });
    return url[0];
  };

  // const onFinish = async (fieldsValue) => {
  //   const values = {
  //     ...fieldsValue,
  //     website: fieldsValue["mintWebsite"] || "",
  //     username: userName || "",
  //     role_type: radioValue || 0,
  //     users_url: userName || "",
  //     date_of_birth:
  //       fieldsValue["dateOfBirth"] || userData?.date_of_birth || "",
  //     address: location.label || userData?.address || "",

  //     profileImage: getImageUrl(res, "profileUpload"),
  //     coverImage: getImageUrl(res, "backgroundUpload"),
  //     nftCollection: fieldsValue["nftCollection"] || [],

  //     instagramId: fieldsValue["instagramId"] || "",
  //     isInstagramToggled: instagramData === true ? 1 : 0,

  //     twitterId: fieldsValue["twitterId"] || "",
  //     isTwitterToggled: twitterData === true ? 1 : 0,

  //     tiktokId: fieldsValue["tiktokId"] || "",
  //     isTikTokToggled: tiktokData === true ? 1 : 0,

  //     facebookId: fieldsValue["facebookId"] || "",
  //     isFacebookToggled: tiktokData === true ? 1 : 0,

  //     onlyfansId: fieldsValue["onlyfansId"] || "",
  //     isOnlyFansToggled: onlyfansData === true ? 1 : 0,

  //     dial_code: "",
  //     countryId: "",
  //     comment: "",
  //     document_front: "",
  //     document_back: "",
  //     documentType: "",
  //   };
  //   if (fileList.length > 0) {
  //     await handleUpload(fileList).then((res) => {
  //       res.map((elem) => {
  //         values[elem.key] = elem.url;
  //       });
  //     });
  //   }
  // };

  const handleEditSubmission = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      profileImage: userData?.profileImage,
      coverImage: userData?.coverImage,
      website: fieldsValue["mintWebsite"],
      date_of_birth: fieldsValue["dateOfBirth"],
      role_type: radioValue || 0,
      isInstagramToggled: fieldsValue["isInstagramToggled"] === true ? 1 : 0,
      isTwitterToggled: fieldsValue["isTwitterToggled"] === true ? 1 : 0,
      isTikTokToggled: fieldsValue["isTikTokToggled"] === true ? 1 : 0,
      isFacebookToggled: fieldsValue["isFacebookToggled"] === true ? 1 : 0,
      isOnlyFansToggled: fieldsValue["isOnlyFansToggled"] === true ? 1 : 0,
    };
    delete data["dateOfBirth"];
    console.log(data);
    if (fileList.length > 0) {
      await handleUpload(fileList).then((res) => {
        res.map((elem) => {
          data[elem.key] = elem.url;
        });
      });
    }
    // console.log(data);
    creatorFormData(data, isEdit);
  };
  const onFinish = async (fieldsValue) => {
    if (isEdit) {
      handleEditSubmission(fieldsValue);
    } else {
      await handleUpload(fileList).then((res) => {
        const data = {
          ...fieldsValue,
          profileImage: getImageUrl(res, "profileImage"),
          coverImage: getImageUrl(res, "backgroundUpload"),
          blockChainId: "1",
          website: fieldsValue["mintWebsite"] || "",
          username: userName || "",
          role_type: radioValue || 0,
          users_url: userName || "",
          date_of_birth: fieldsValue["dateOfBirth"],
          nftCollection: fieldsValue["nftCollection"] || [],

          instagramId: fieldsValue["instagramId"] || "",
          isInstagramToggled: instagramData === true ? 1 : 0,

          twitterId: fieldsValue["twitterId"] || "",
          isTwitterToggled: twitterData === true ? 1 : 0,

          tiktokId: fieldsValue["tiktokId"] || "",
          isTikTokToggled: tiktokData === true ? 1 : 0,

          facebookId: fieldsValue["facebookId"] || "",
          isFacebookToggled: tiktokData === true ? 1 : 0,

          onlyfansId: fieldsValue["onlyfansId"] || "",
          isOnlyFansToggled: onlyfansData === true ? 1 : 0,

          dial_code: "",
          countryId: "",
          comment: "",
          document_front: "",
          document_back: "",
          documentType: "",
        };

        creatorFormData(data, isEdit);
      });
    }
  };

  useEffect(() => {
    const finder = setTimeout(() => {
      userName && creatorUserName(userName);
    }, [500]);
    return () => {
      clearTimeout(finder);
    };
  }, [userName]);
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const editUserInfo = {
    name: userData?.fullname,
    publicEthAddress: userData?.publicAddress,
    email: userData?.email,
    bio: userData?.bio,
    mintWebsite: userData?.website,
    username: userData?.username,
    usersURL: userData?.users_url,
    date_of_birth: userData?.date_of_birth,
    mobile_no: userData.mobile_no,
    address: userData?.address,
    country: userData?.countryName,
    city: userData?.city,
    state: userData?.state,
    postalCode: userData?.postalCode,
    profileImage: userData?.profileImage,
    coverImage: userData?.coverImage,
    nftCollection: userData?.nftCollection || "",

    instagramId: userData?.instagramId || "",
    isInstagramToggled: userData?.isInstagramToggled ? 1 : 0,

    twitterId: userData?.twitterId || "",
    isTwitterToggled: userData?.isTwitterToggled ? 1 : 0,

    tiktokId: userData?.tiktokId || "",
    isTikTokToggled: userData?.isTikTokToggled ? 1 : 0,

    onlyfansId: userData?.onlyfansId || "",
    isOnlyFansToggled: userData?.isOnlyFansToggled ? 1 : 0,

    facebookId: userData?.instagramId || "",
    isFacebookToggled: userData?.isInstagramToggled ? 1 : 0,
  };

  const profile = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    fileList,
  };

  const handleUpload = async () => {
    dispatch(startLoading());
    const d = fileList.map(
      (element, index) =>
        new Promise((resolve, reject) => {
          uploadImageVideoFile(`img${index}`, element.url)
            .then((res) => {
              if (res) {
                resolve({ type: element.type, url: res, key: element.key });
              } else {
                resolve(null);
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        })
    );
    let data = await Promise.all(d);
    dispatch(stopLoading());
    // console.log({data});

    return data;
    // data = data.filter((e) => e);
  };

  const getLocationDetails = () => {
    try {
      navigator.geolocation.getCurrentPosition(function (position) {
        getGeocodeByLatLong(
          position.coords.latitude,
          position.coords.longitude
        ).then((res) => {
          if (res) {
            dispatch(startLoading());
            setTimeout(() => {
              let fullAddress = "";
              res.forEach((address_component) => {
                if (
                  address_component.types[0] == "neighborhood" ||
                  address_component.types[0] == "route" ||
                  address_component.types[0] == "street_number"
                ) {
                  fullAddress = fullAddress.concat(
                    " " + address_component.long_name
                  );
                }
                if (address_component.types[0] == "locality") {
                  form.setFieldsValue({
                    city: address_component.long_name,
                  });
                }
                if (
                  address_component.types[0] == "administrative_area_level_1"
                ) {
                  form.setFieldsValue({
                    state: address_component.long_name,
                  });
                }
                if (address_component.types[0] == "country") {
                  form.setFieldsValue({
                    country: address_component.long_name,
                  });
                }
                if (address_component.types[0] == "postal_code") {
                  form.setFieldsValue({
                    postalCode: address_component.long_name,
                  });
                }
              });
              form.setFieldsValue({
                address: fullAddress,
              });
              dispatch(stopLoading());
            }, 1000);
          }
        });
      });
    } catch (error) {
      toasts.error("Something went wrong.");
      dispatch(stopLoading());
    }
  };
  var now = new Date();
  var dateString = moment(now).format("YYYY-MM-DD");
  return (
    <MainLayout>
      {(!isEdit || Object.keys(userData).length > 0) && (
        <div className="container explore_area">
          <div className="new__creater">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={isEdit ? editUserInfo : { remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={validateMessages}
              className="fv-form"
            >
              <div className="sub_title">
                <h2>{isEdit ? `Edit` : `Add`} User profile</h2>
                <p>Inputs Creator's Information Below</p>
              </div>
              <Row gutter={25}>
                <Col lg={12} sm={24} xs={24}>
                  <div className="new__creater__form">
                    <h4>Creators Information</h4>

                    {CreatorInfo.map(
                      ({
                        index,
                        name,
                        label,
                        rules,
                        placeholder,
                        disabled,
                      }) => (
                        <>
                          <Form.Item
                            name={name}
                            label={label}
                            rules={rules}
                            hasFeedback
                            key={index}
                            onChange={(e) =>
                              name === "username"
                                ? setUserName(e.target.value)
                                : ""
                            }
                          >
                            <Input
                              placeholder={placeholder}
                              disabled={disabled}
                            />
                            {/* disabled={userDetails?.username === "username" ? disabled:""} */}
                          </Form.Item>
                          {name === "username" && userName && (
                            <p className="userNameMessage">
                              {name === "username" ? userNameMessage : ""}
                            </p>
                          )}
                        </>
                      )
                    )}
                    <Form.Item
                      name={"usersURL"}
                      label={"Users URL"}
                      rules={[{ type: "string" }]}
                      hasFeedback
                      // defaultValue={userName}
                    >
                      {/* <Input placeholder={"/username"} disabled/> */}
                      <p className="userUrl">
                        Fan-Verse.io/{userName || userData?.username}
                      </p>
                    </Form.Item>
                    <h4>Personal Information</h4>
                    <Row gutter={20}>
                      <Col md={12} xs={24} xl={12}>
                        <Form.Item
                          name={"dateOfBirth"}
                          label={"Date of Birth"}
                          hasFeedback
                        >
                          <DatePicker
                            disabledDate={(d) => !d || d.isAfter(dateString)}
                            defaultValue={
                              userData?.date_of_birth &&
                              moment(
                                new Date(userData?.date_of_birth),
                                dateFormat
                              )
                            }
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24} xl={12}>
                        <Form.Item
                          name={"mobile_no"}
                          label={"Phone Number"}
                          rules={[{ type: "number", min: 0, max: 99 }]}
                          hasFeedback
                        >
                          {/* <Input placeholder={"Phone Number"}/> */}
                          <InputNumber
                            placeholder={"Phone Number"}
                            // minlength={6}
                            // maxLength={13}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col md={12} xs={24} xl={24}>
                        <GooglePlace
                          name="address"
                          label="Address"
                          className="withoutBg locationWrap"
                          id="street_address"
                          placeholder={`Address`}
                          defaultInputValue={userData?.address}
                          onChange={(e) => {
                            setLocationData(e.label);
                            setLocation(e);
                            const terms = e.value.terms;
                            if (terms.length > 0) {
                              form.setFieldsValue({
                                city: terms[terms.length - 3]?.value,
                              });
                              form.setFieldsValue({
                                country: terms[terms.length - 1]?.value,
                              });
                              form.setFieldsValue({
                                state: terms[terms.length - 2]?.value,
                              });
                            }
                          }}
                          value={location}
                        />
                      </Col> */}
                      {PersonalInfo.map(
                        ({
                          index,
                          name,
                          label,
                          rules,
                          placeholder,
                          column,
                          maxLength,
                          hasFeedback,
                        }) => (
                          <Col md={12} xs={24} xl={column} key={index}>
                            <Form.Item
                              name={name}
                              label={label}
                              rules={rules}
                              hasFeedback={hasFeedback}
                            >
                              <Input
                                placeholder={placeholder}
                                maxLength={maxLength}
                              />
                            </Form.Item>
                            {name === "address" && (
                              <span
                                className="fillAddress"
                                onClick={() => getLocationDetails()}
                              >
                                <img src={locationPin} alt="icon" />
                              </span>
                            )}
                          </Col>
                        )
                      )}
                    </Row>
                    {/* <Form.Item
                      name="name"
                      label="Creators Name"
                      rules={[{ types: "name", required: true }, { min: 5 }]}
                      hasFeedback
                    >
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                    <Form.Item
                      name="publicEthAddress"
                      label="Creators Public ETH Address"
                      rules={[{ type: "publicEthAddress" }]}
                      hasFeedback
                    >
                      <Input placeholder="Enter Public ETH Address" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Creators email address"
                      rules={[{ type: "email", required: true }]}
                      hasFeedback
                    >
                      <Input placeholder="Enter Email Address" />
                    </Form.Item>
                    <Form.Item
                      name="mintWebsite"
                      label="Mint Website"
                      rules={[{ type: "mintWebsite" }]}
                      hasFeedback
                    >
                      <Input placeholder="Email Website" />
                    </Form.Item>
                    <div className="inputWrap">
                      <Form.Item
                        name="instagramId"
                        label="Instagram @"
                        rules={[{ type: "instagramId" }]}
                        hasFeedback
                      >
                        <Input placeholder="@instagram" />
                      </Form.Item>
                      <Form.Item
                        name="isInstagramToggled"
                        className="inputWrap__switch"
                        valuePropName="checked"
                      >
                        <Switch
                          defaultChecked
                          onChange={() => setInstagramData(!instagramData)}
                        />
                      </Form.Item>
                    </div>
                    <div className="inputWrap">
                      <Form.Item
                        name="twitterId"
                        label="Twitter @"
                        rules={[{ type: "twitterId" }]}
                        hasFeedback
                      >
                        <Input placeholder="@twitter" />
                      </Form.Item>
                      <Form.Item
                        name="isTwitterToggled"
                        valuePropName="checked"
                        className="inputWrap__switch"
                      >
                        <Switch
                          defaultChecked
                          onChange={() => setTwitterData(!twitterData)}
                        />
                      </Form.Item>
                    </div>
                    <div className="inputWrap">
                      <Form.Item
                        name="tiktokId"
                        label="TikTok @"
                        rules={[{ type: "tiktokId" }]}
                        hasFeedback
                      >
                        <Input placeholder="@tikTok" />
                      </Form.Item>
                      <Form.Item
                        name="isTikTokToggled"
                        valuePropName="checked"
                        className="inputWrap__switch"
                      >
                        <Switch
                          defaultChecked
                          onChange={() => setTiktokData(!tiktokData)}
                        />
                      </Form.Item>
                    </div>
                    <div className="inputWrap">
                      <Form.Item
                        name="onlyfansId"
                        label="OnlyFans @"
                        rules={[{ type: "onlyfansId" }]}
                        hasFeedback
                      >
                        <Input placeholder="@onlyFans" />
                      </Form.Item>
                      <Form.Item
                        name="isOnlyFansToggled"
                        valuePropName="checked"
                        className="inputWrap__switch"
                      >
                        <Switch
                          defaultChecked
                          onChange={() => setOnlyfansData(!onlyfansData)}
                        />
                      </Form.Item>
                    </div> */}
                  </div>
                </Col>
                {/* <div className="cncl_buttons">
                  <div className="new__creater__form__submit__data">
                    <Button
                      type="secondary"
                      htmlType="cancel"
                      onClick={() => form.resetFields()}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </div> */}
                <Col lg={12} sm={24} xs={24}>
                  <div className="new__creater__form">
                    <Form.Item
                      name="upload"
                      label="Profile Picture"
                      className="upload_img"
                      valuePropName="fileList"
                      extra="Must be 500x500 & PNG, JPG, TIF format"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        name="logo"
                        maxCount={1}
                        defaultFileList={
                          Object.keys(userData).length > 0 && [
                            {
                              type: "profile",
                              thumbUrl: userData?.profileImage,
                            },
                          ]
                        }
                        {...profile}
                        beforeUpload={(file) => {
                          setFileList([
                            ...fileList,
                            { url: file, key: "profileImage" },
                          ]);
                          return false;
                        }}
                        listType="picture"
                      >
                        <Button icon={<UploadIcon />}>Upload picture</Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="backgroundupload"
                      label="Background Picture"
                      className="upload_img back_upload_img"
                      valuePropName="fileList"
                      extra="Must be 1200x400 & PNG, JPG, TIF format"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        name="logo"
                        maxCount={1}
                        defaultFileList={
                          Object.keys(userData).length > 0 && [
                            {
                              type: "coverImage",
                              thumbUrl: userData?.coverImage,
                            },
                          ]
                        }
                        {...profile}
                        beforeUpload={(file) => {
                          setFileList([
                            ...fileList,
                            { url: file, key: "coverImage" },
                          ]);
                          return false;
                        }}
                        listType="picture"
                      >
                        <Button icon={<UploadIcon />}>Upload picture</Button>
                      </Upload>
                    </Form.Item>
                    <div className="subheading_nft">
                      <h4>Other NFT Collections</h4>
                      <p>Add links to your other NFT Collections</p>
                    </div>

                    {/* <Form.Item
                      name="nftCollection"
                      label="Link"
                      rules={[
                        {
                          whitespace: true,
                          message:
                            "Please add URL Eg:-https://www.fan-verse.io/",
                          type: "url",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="www.website.com" />
                    </Form.Item>

                    <button type="button" className="borderbtn addlinkbtn">
                      <PlusOutlined /> Add link
                    </button> */}

                    <Form.List name="nftCollection">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? "Link" : ""}
                              className="extraLinksWrap"
                              hasFeedback
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                noStyle
                              >
                                <Input placeholder="www.website.com or https://www.website.com" />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add("")}
                              className="borderbtn addlinkbtn"
                              disabled={fields.length >= 5}
                            >
                              <PlusOutlined /> Add link
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    <h4>Social Media</h4>

                    <Row gutter={20}>
                      <Col xs={24} md={24}>
                        <div className="inputWrap">
                          <Form.Item
                            name="instagramId"
                            label="Instagram @"
                            rules={[
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.instagram.com or https://www.instagram.com/",
                                type: "url",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input placeholder="@instagram" />
                          </Form.Item>
                          <Form.Item
                            name="isInstagramToggled"
                            className="inputWrap__switch"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        {" "}
                        <div className="inputWrap">
                          <Form.Item
                            name="facebookId"
                            label="Facebook @"
                            rules={[
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.facebook.com or https://www.facebook.com/",
                                type: "url",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input placeholder="@Facebook" />
                          </Form.Item>
                          <Form.Item
                            name="isFacebookToggled"
                            valuePropName="checked"
                            className="inputWrap__switch"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="inputWrap">
                          <Form.Item
                            name="twitterId"
                            label="Twitter @"
                            rules={[
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.twitter.com or https://www.twitter.com/",
                                type: "url",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input placeholder="@twitter" />
                          </Form.Item>
                          <Form.Item
                            name="isTwitterToggled"
                            valuePropName="checked"
                            className="inputWrap__switch"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="inputWrap">
                          <Form.Item
                            name="tikTokId"
                            label="TikTok @"
                            rules={[
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.tiktok.com or https://www.tiktok.com",
                                type: "url",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input placeholder="@TikTok" />
                          </Form.Item>
                          <Form.Item
                            name="isTikTokToggled"
                            valuePropName="checked"
                            className="inputWrap__switch"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="inputWrap">
                          <Form.Item
                            name="onlyfansId"
                            label="OnlyFans @"
                            rules={[
                              {
                                whitespace: true,
                                message:
                                  "Please add URL Eg:- www.onlyfans.com or https://www.onlyfans.com/",
                                type: "url",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input placeholder="@onlyFans" />
                          </Form.Item>
                          <Form.Item
                            name="isOnlyFansToggled"
                            valuePropName="checked"
                            className="inputWrap__switch"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={24}>
                        <Radio.Group
                          onChange={(e) => setradioValue(e.target.value)}
                          value={radioValue}
                        >
                          <Radio value={1}>User</Radio>
                          <Radio value={2}>Creator</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    {/* <Form.Item
                    name="upload"
                    label="Profile Picture"
                    className="upload_img"
                    valuePropName="fileList"
                    extra="Must be 500x500 & PNG, JPG, TIF format"
                    getValueFromEvent={normFile}
                  >
                    <Upload

                      name="logo"
                      action={`${API_HOST}/users/api/v1/image`}
                      onChange={fileUploadChange}
                      listType="picture"
                    >
                      <Button
                        icon={
                          <svg
                            width="11"
                            height="12"
                            viewBox="0 0 11 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2148 0.879557C10.2148 0.423828 9.86979 0.0722652 9.41406 0.0722652L0.983072 0.0722656C0.520833 0.0722656 0.175781 0.423828 0.175781 0.879558C0.175781 1.3418 0.520833 1.69336 0.983072 1.69336L5.02604 1.69336C4.85677 1.72591 4.70052 1.81706 4.57682 1.94076L0.553385 5.87305C0.371093 6.04883 0.286458 6.25716 0.286458 6.47201C0.286458 6.95378 0.625 7.29232 1.08073 7.29232C1.32812 7.29232 1.52995 7.18815 1.6862 7.0319L2.91667 5.82096L4.4401 4.14128L4.35547 5.48893L4.35547 11.0684C4.35547 11.5827 4.69401 11.9277 5.19531 11.9277C5.6901 11.9277 6.02865 11.5827 6.02865 11.0684L6.02865 5.48893L5.95052 4.13477L7.46745 5.82096L8.69792 7.0319C8.85417 7.18815 9.05599 7.29232 9.30338 7.29232C9.75911 7.29232 10.0977 6.95378 10.0977 6.472C10.0977 6.25716 10.013 6.04883 9.83073 5.87305L5.8138 1.94076C5.6901 1.81706 5.53385 1.72591 5.35807 1.69336L9.41406 1.69336C9.86979 1.69336 10.2148 1.3418 10.2148 0.879557Z"
                              fill="#141029"
                            />
                          </svg>
                        }
                      >
                        Upload picture
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="backgroundupload"
                    label="Background Picture"
                    className="upload_img back_upload_img"
                    valuePropName="fileList"
                    extra="Must be 1200x400 & PNG, JPG, TIF format"
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      name="logo"
                      action={`${API_HOST}/users/api/v1/image`}
                      onChange={fileUploadChange}
                      listType="picture"
                    >
                      <Button
                        icon={
                          <svg
                            width="11"
                            height="12"
                            viewBox="0 0 11 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2148 0.879557C10.2148 0.423828 9.86979 0.0722652 9.41406 0.0722652L0.983072 0.0722656C0.520833 0.0722656 0.175781 0.423828 0.175781 0.879558C0.175781 1.3418 0.520833 1.69336 0.983072 1.69336L5.02604 1.69336C4.85677 1.72591 4.70052 1.81706 4.57682 1.94076L0.553385 5.87305C0.371093 6.04883 0.286458 6.25716 0.286458 6.47201C0.286458 6.95378 0.625 7.29232 1.08073 7.29232C1.32812 7.29232 1.52995 7.18815 1.6862 7.0319L2.91667 5.82096L4.4401 4.14128L4.35547 5.48893L4.35547 11.0684C4.35547 11.5827 4.69401 11.9277 5.19531 11.9277C5.6901 11.9277 6.02865 11.5827 6.02865 11.0684L6.02865 5.48893L5.95052 4.13477L7.46745 5.82096L8.69792 7.0319C8.85417 7.18815 9.05599 7.29232 9.30338 7.29232C9.75911 7.29232 10.0977 6.95378 10.0977 6.472C10.0977 6.25716 10.013 6.04883 9.83073 5.87305L5.8138 1.94076C5.6901 1.81706 5.53385 1.72591 5.35807 1.69336L9.41406 1.69336C9.86979 1.69336 10.2148 1.3418 10.2148 0.879557Z"
                              fill="#141029"
                            />
                          </svg>
                        }
                      >
                        Upload picture
                      </Button>
                    </Upload>
                  </Form.Item>
                  <div className="collection__frame">
                    <h3>Collections</h3>
                    <Form.Item
                      name="Public Address"
                      rules={[{ type: "Public Address" }]}
                      hasFeedback
                      className="mb-0"
                    >
                      <Input placeholder="Enter Creators Public Address" />
                    </Form.Item>
                    <div className="collection__frame__list">
                      <div className="collection__frame__list__svgpath collection__frame__list__view">
                        <PerfectScrollbar>
                        <MovingCircleIcon />
                        <div className="search_content">
                          <button>
                            <SearchIcon />
                          </button>
                          <p>
                            Scanning <br />
                            Blockchian
                          </p>
                        </div>
                      </PerfectScrollbar> 
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="cncl_buttons">
                    <div className="new__creater__form__submit__data">
                      {/* <Button
                    type="secondary"
                    htmlType="cancel"
                    onClick={() => form.resetFields()}
                  >
                    Cancel
                  </Button> */}
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default AddUserAdmin;
