import React from "react";
import AddUserAdmin from "./AddUserAdmin";
const AdminAddUser = () => {
  return (
    <>
    <AddUserAdmin isEdit={false} userData=''/>
    </>
  );
};

export default AdminAddUser;
