import { Button, Checkbox, Form, Image } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { numericality, required } from "redux-form-validators";

import { FormField } from "../../../../components/FormField";
import { ValidateImage } from "../../../../Services/Validation";
import { compose } from "redux";
import { toast } from "../../../../components/Toast/Toast";
import { Row } from "antd";
import Col from "antd/es/grid/col";

const validate = (values) => {
  //  if (values?.category_name?.length > 3) return setCategory(true);
  //  setCategory(false);
};
const CreateMediaForm = ({ handleSubmit, isEdit }) => {
  const [category_image, setImages] = useState({ image: "" });
  const [InputKey, setInputKey] = useState();
  const [errors, setErrors] = useState("");

  const setCategory = (value) => {
    console.log(value);
    if (value.trim() === "") return setErrors("");
    if (+value) return setErrors("Tax Benefit name must be a string");
    if (value?.length > 30)
      return setErrors("Tax Benefit name must be less than 30 characters");
    return setErrors("");
  };

  const numberOfTickets = [
    { key: "1", value: "100", lable: "100" },
    { key: "2", value: "500", lable: "500" },
    { key: "3", value: "1000", lable: "1000" },
  ];

  const raffleValue = useSelector((state) => state.media.media);

  useEffect(() => {
    setImages({ image: raffleValue?.image });
  }, [raffleValue]);

  function resetsFileInput() {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  }

  const onImageChange = async (event) => {
    const files = event.target.files;
    const name = event.target.name;
    let height;
    let width;
    let isImageValid = false;

    if (files.length === 0)
      return toast.error(
        "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
      );

    const file = files[0]; /** get file from files array */

    let ext = file.name.split(".").pop();
    ext = ext.toLowerCase();
    // let ext = file.name.split(".")[1]; /** get ext of image to validate */
    ext = ext.toLowerCase();
    if (
      ext === "jpg" ||
      ext === "gif" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "gif"
    ) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 100) {
        resetsFileInput();
        return toast.error("Image should be less than or equal to 100MB");
      } else {
        const blob = URL.createObjectURL(file);
        height = 330;
        width = 330;
        isImageValid = await ValidateImage({
          event: blob,
          wid: width,
          hei: height,
        });
        setImages({ ...category_image, image: blob });
      }
    } else {
      resetsFileInput();
      return toast.error(
        "Please upload a valid image format (.jpg, .jpeg, .png, .gif)"
      );
    }
  };
  //  const maxLength = (max) => (value) =>
  //    value && value.length > max
  //      ? `Must be ${max} characters or less`
  //      : undefined;
  //  const maxLength20 = maxLength(20);

  return (
    <div className="createNft_area">
      <div className="new__creater">
        <div className="create-nft-form create-nft-form-new">
          <Form
            className="fv-form"
            autoComplete="off"
            autoFocus="off"
            onSubmit={handleSubmit}
          >
            {/* <div>
        {isEdit ? (
          <>
            <div style={{ marginBottom: "5px" }}>
              <b>Category </b>
            </div>
            <Image src={category_image?.image} width="80" height="80" />
            <br />
          </>
        ) : (
          <>
            {category_image?.image?.length > 0 && (
              <Image src={category_image?.image} width="80" height="80" />
            )}
          </>
        )}
      </div> */}
            <div className="upload_img">
              {category_image.image && (
                <Image src={category_image?.image} width="150" height="70" />
              )}
              <Form.Field className="uploadright">
                <div className="ant-upload-list ant-upload-list-picture"></div>
                <p>Image, Video, Audio, or 3D Model</p>
                <span>
                  JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV. Max: 100 MB
                </span>
                <div className="uploadbtn">
                  <Field
                    id="image"
                    component={FormField}
                    name="image"
                    type="file"
                    placeholder="upload a file"
                    onImageChange={onImageChange}
                    validate={[required()]}
                  />
                </div>
                {/* <p> Banner size (width:1920px, height: 726px )</p> */}
              </Form.Field>
            </div>

            {/* <Form.Field>
              <label>Tax Title</label>
              <Field
                component={FormField}
                name="title"
                type="text"
                placeholder="Title"
                validate={[required()]}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Form.Field> */}
            <Row gutter={{ xs: 25, md: 30, xl: 40 }}>
              <Col xl={8}>
                <Form.Field>
                  <label> Url</label>
                  <Field
                    component={FormField}
                    name="url"
                    type="text"
                    placeholder=" Url"
                    //validate={[required()]}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </Form.Field>
              </Col>
              <Col xl={8}>
                <Form.Field className="loginBtn">
                  <div className="new__creater__form__submit__data">
                    <button
                      className="ant-btn ant-btn-primary"
                      // disabled={errors[0]}
                      style={{ "margin-top": "47px" }}
                    >
                      {isEdit ? "Save Changes" : "Submit"}
                    </button>
                  </div>
                </Form.Field>
              </Col>
            </Row>

          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state?.media.media,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "CreateMediaForm",
    enableReinitialize: true,
    warn: validate,
  })
)(CreateMediaForm);
