import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { ArtistActions, BrandActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import ArtistList from "./List/UsertList";
import "./user.scss";

const User = () => {
  const dispatch = useDispatch();
  const [artist, setArtist] = useState({ count: 10, rows: [] });

  const [requestFilter, setRequestFilter] = useState("ALL");

  const [activeTab, setActiveTab] = useState(1);
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    // setSearch(e.target.value);
    getArtist({
      page: 1,
      limit: GlobalVariables.PAGE_LIMIT,
      search: e.target.value,
    });
  };
  const getArtist = useCallback(
    async (data) => {
      console.log(data, "ters");
      const { getArtist } = ArtistActions;
      const res = await dispatch(getArtist(data));
      if (res) {
        setArtist(res.data.data);
        setSearch(data.search);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const { PAGE_LIMIT } = GlobalVariables;
    // getArtist({ id: 3, page: 1, limit: PAGE_LIMIT, search: search,role:'donor' });
  }, [getArtist]);

  const blockUser = (data) => {
    const { blockuserById } = ArtistActions;
    dispatch(blockuserById(data)).then((_data) => {
      if (_data) {
        const { PAGE_LIMIT } = GlobalVariables;
        getArtist({ id: 3, page: 1, limit: PAGE_LIMIT, search: search });
      }
    });
  };

  return (
    <>
      <MainLayout>
        <ArtistList
          getArtist={getArtist}
          artist={artist}
          blockUser={blockUser}
          setArtist={setArtist}
          setActiveTab={setActiveTab}
          handleSearch={handleSearch}
          // title="Donors"
          // name="Donors"
        />
      </MainLayout>
    </>
  );
};

export default withRouter(User);
