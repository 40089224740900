import React from "react";
import { Header, Modal, Image, Icon } from "semantic-ui-react";
import "../../../src/components/Modal/ImageVideo.scss"; //"../Modal/ImageVideoModal.scss"

function  ImageVideoModal({ fileType, logo, isLogo = false }) {
  const [open, setOpen] = React.useState(false);

  // console.log("LOGO---->",logo);
  // console.log("FILE TYPE---->",fileType);

  return (
    <Modal style={{ width: "760px", minHeight: "300px" }} closeIcon open={open} 
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        fileType === "video" ? (
          <div className="container">
            <video width="30" height="30"><source src={logo} type="video/mp4" /></video>
            <div className="btn"><Icon color="red" name="play circle outline" size="big" /></div>
          </div>
        ) : (
          // <div className="center-img">
            <Image src={isLogo ? `${logo}?tr=w-30,h-30` : `${logo}?tr=w-30,h-30`} width={isLogo ? "30" : "30"} height="30" referrerPolicy="no-referrer"/>
          // </div>
        )
      }
    >
      <Header content={fileType === undefined ? "image" : fileType} />

      <Modal.Content className="modalBody">
        {fileType === "video" ? (
          <video
            width="720px"
            height="420px"
            autoPlay={true}
            preLoad="auto"
            loop
            controls
            muted={false}
          >
            <source src={logo} type="video/mp4" />
          </video>
        ) : (
          <Image src={`${logo}`} size="large" centered="auto" />
        )}
      </Modal.Content>
    </Modal>
  );
}

export default ImageVideoModal;
