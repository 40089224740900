import React, { useState } from "react";
import "./Changepassword.scss";
import {
  Grid,
  Container,
  Form,
  Header,
  // Image,
  // Message,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import { PersistActions, UserActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
//import logo from "../../../Assets/image/fv_logo_white.png";
//import { ReactComponent as Logo } from "../../../Assets/image/fv_logo.svg";
import { apiCallPost } from "../../../components/Axios/Axios";
//import { saveLoginDetails } from "../../../redux/_actions/persist.action";
import lock from "../../../images/eyehide.svg";
import lockopen from "../../../images/eyeshow.svg";

const ChangePassword = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  // const submitForm = async (data) => {
  //   const { login } = UserActions;
  //   await dispatch(login(data));
  // };
  // Password Show Icon
  // Password Show Icon
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [newVisibility, setNewVisibility] = useState(false);
  const [oldVisibility, setOldVisibility] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);

  const oldEye = <img src={oldVisibility ? lockopen : lock} alt="icon" />;
  const newEye = <img src={newVisibility ? lockopen : lock} alt="icon" />;
  const confirmEye = (
    <img src={confirmVisibility ? lockopen : lock} alt="icon" />
  );
  function submitForm(value) {
    const newData = {
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
      confirmPassword: value.confirmPassword,
    };
    apiCallPost("api/v1/admin/auth/changePassword", newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            const { logout } = PersistActions;
            localStorage.setItem("authorizationToken", res.data.token);
            dispatch(logout({ user: res.data.id, token: res.data.token }));
          }
        }
      })
      .catch((err) => {});
  }

  const handleVisible = (fieldName) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [fieldName]: !prevVisibility[fieldName],
    }));
  };
  return (
    <Container fluid className="loginContainer">
      <Container className="main_loginForm">
        <Grid centered>
          {/* <Image src={logo} className="logoStyle" /> */}
          {/* <Logo className="fanverse_logo" /> */}
        </Grid>
        <div className="loginForm">
          <div className="loginformBox">
            <Header as="h2" content="Change Password" className="regTitle" />

            <Form
              autoComplete="off"
              autoFocus="off"
              onSubmit={handleSubmit(submitForm)}
            >
              <Form.Field>
                <label>Old Password</label>
                <Field
                  component={FormField}
                  name="oldPassword"
                  type={passwordVisibility["oldPassword"] ? "text" : "password"}
                  placeholder="Enter old password"
                  validate={[required()]}
                  autocomplete="off"
                />
                {/* <span
                  className="show-pw"
                  onClick={() => handleVisible("oldPassword")}
                >
                  <img
                    src={passwordVisibility["oldPassword"] ? lockopen : lock}
                    alt="icon"
                  />
                </span> */}
              </Form.Field>

              <Form.Field>
                <label>New Password</label>
                <Field
                  component={FormField}
                  // type="newPassword"
                  type={passwordVisibility["newPassword"] ? "text" : "password"}
                  name="newPassword"
                  placeholder="Enter new password"
                  validate={[required()]}
                  autoComplete="off"
                />
                {/* <span
                  className="show-pw"
                  onClick={() => handleVisible("newPassword")}
                >
                  <img
                    src={passwordVisibility["newPassword"] ? lockopen : lock}
                    alt="icon"
                  />
                </span> */}
              </Form.Field>

              <Form.Field>
                <label>Confirm Password</label>
                <Field
                  component={FormField}
                  // type="confirmPassword"
                  type={
                    passwordVisibility["confirmPassword"] ? "text" : "password"
                  }
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                  validate={[required()]}
                  autoComplete="off"
                />
                {/* <span
                  className="show-pw"
                  onClick={() => handleVisible("confirmPassword")}
                >
                  <img
                    src={
                      passwordVisibility["confirmPassword"] ? lockopen : lock
                    }
                    alt="icon"
                  />
                </span> */}
              </Form.Field>

              <Form.Field className="loginBtn">
                <button type="submit" primary="true">
                  {" "}
                  Submit{" "}
                </button>
              </Form.Field>
            </Form>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default withRouter(
  reduxForm({ form: "LoginForm", enableReinitialize: true })(ChangePassword)
);
