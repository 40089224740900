import { Button, Confirm, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { apiCallGet, apiCallPost } from "../../../components/Axios/Axios";

import GlobalVariables from "../../../_constants/GlobalVariables";
import moment from "moment";
// import { KycActions } from "../../../../redux/";
import { useDispatch } from "react-redux";

export default function ViewCampaign({ UserDetails, myKycActions }) {
  const [open, setOpen] = useState(false);
  const [openMain, setOpenMain] = useState(false);
  const [action, setAction] = useState({});
  //   const [userData, setUserData] = useState({});

  //const dispatch = useDispatch();

  // function updateUser(newData) {
  //   setOpen(false);
  //   console.log("TAG", action);

  //   apiCallPost(`/api/v1/admin/campaign/update`, newData, {}, "toastOn")
  //     .then(async (res) => {
  //       if (res) {
  //         if (!res.error) {
  //           setOpenMain(false);
  //           myKycActions({ page: 0, limit: GlobalVariables.PAGE_LIMIT });
  //         }
  //       }
  //     })
  //     .catch((err) => {});
  // }

  //   function getUser() {
  //     apiCallGet(
  //       `api/v1/admin/user/findUserById/${UserDetails.id}`,

  //       {},
  //       "toastOn"
  //     )
  //       .then(async (res) => {
  //         if (res) {
  //           if (!res.error) {
  //             setUserData(res.data);
  //           }
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  useEffect(() => {
    console.log("TAGrr", openMain);
    // getUser()
  }, [openMain]);

  // console.log("data", UserDetails);

  const modalElements = (
    <div className="user_details">
      <ul>
        <li>
          <div>
            <b>Category :</b>{" "}
          </div>
          <p>
            {UserDetails.campaign_category || UserDetails.category
              ? UserDetails.campaign_category || UserDetails.category
              : "N/A"}
          </p>
          {/* {UserDetails.last_name ? UserDetails.last_name : "N/A"} */}
        </li>
        {UserDetails.campaign_type && (
          <li>
            <div>
              <b>Type :</b>{" "}
            </div>
            <p>
              {UserDetails.campaign_type ? UserDetails.campaign_type : "N/A"}
            </p>
            {/* {UserDetails.last_name ? UserDetails.last_name : "N/A"} */}
          </li>
        )}
        <li>
          <div>
            {" "}
            <b>Created At : </b>{" "}
          </div>
          <p>
            {moment(UserDetails?.createdAt).format("MMMM Do YYYY") || "N/A"}
          </p>
        </li>
        {/* <li style={{ color: "white" }}>
          <div>
            <b>Wallet Id :</b>
          </div>{" "}
          {UserDetails?.wallet_id ? UserDetails?.wallet_id : "N/A"}
          </li>*/}
        <li>
          <div>
            <b>Fundraiser Id :</b>
          </div>{" "}
          {UserDetails?.fundraiser_id ||
          UserDetails?.fundraiserId ||
          UserDetails?.creator
            ? UserDetails?.fundraiserId ||
              UserDetails?.fundraiser_id ||
              UserDetails?.creator
            : "N/A"}
        </li>
        {UserDetails?.local_currency && (
          <li>
            <div>
              <b>Currency :</b>
            </div>{" "}
            <p>
              {UserDetails?.local_currency
                ? UserDetails?.local_currency
                : "N/A"}
            </p>
          </li>
        )}

        {/* <li style={{color: 'white'}}>
          <div>
            <b>Document:</b>
          </div>{" "}
          {UserDetails.documentUrl && (
            <a href={userData.documentUrl} target="_blank">
              <img className="documentImage" src={UserDetails.documentUrl ? UserDetails.documentUrl : "N/A"} />
            </a>
          )}
        </li> */}
        {/* <li style={{color: 'white'}}>
          <div>
            <b>Date:</b>
          </div>{" "}
          {moment(userData.date_birth).format("MMMM Do YYYY, h:mm A")}
        </li> */}
        {UserDetails.sl_facebook ? (
          <li>
            <div>
              <b>Facebook :</b>
            </div>{" "}
            <p>{UserDetails.sl_facebook ? UserDetails.sl_facebook : "N/A"}</p>
          </li>
        ) : (
          ""
        )}
        {UserDetails.sl_twitter ? (
          <li>
            <div>
              <b>Twitter :</b>
            </div>{" "}
            <p> {UserDetails?.sl_twitter ? UserDetails?.sl_twitter : "N/A"}</p>
          </li>
        ) : (
          ""
        )}
        {UserDetails.sl_facebook ? (
          <li>
            <div>
              <b>Instagram :</b>
            </div>{" "}
            <p>
              {" "}
              {UserDetails?.sl_instagram ? UserDetails?.sl_instagram : "N/A"}
            </p>
          </li>
        ) : (
          ""
        )}
        {UserDetails?.sl_linked_in ? (
          <li>
            <div>
              <b>Linkedin :</b>
            </div>{" "}
            <p>
              {UserDetails?.sl_linked_in ? UserDetails?.sl_linked_in : "N/A"}
            </p>
          </li>
        ) : (
          ""
        )}
        <li className="description">
          <div>
            <b>Description : </b>
          </div>{" "}
          <p>{UserDetails?.description ? UserDetails?.description : "N/A"}</p>
        </li>
        {UserDetails && UserDetails.kycStatus === "FAILED" && (
          <li>
            <div>
              <b>Remark :</b>
            </div>{" "}
            <p>{UserDetails.remark ? UserDetails.remark : "N/A"}</p>
          </li>
        )}
        <li>
          {/* <Button.Group style={{ marginTop: 20 }}>
            <Button
              positive
              onClick={() => {
                setOpen(true);
                setAction({
                  id: UserDetails.id,
                  fundraiser_id:
                    UserDetails.fundraiser_id || UserDetails.fundraiserId,
                  is_active:
                    UserDetails?.is_active === 1 || UserDetails?.isActive === 1
                      ? 0
                      : 1,
                  remarks: UserDetails?.is_active == 1 ? "Inactive" : "Active",
                });
              }}
            >
              {UserDetails.is_active == 1 ? "Inactive" : "Active"}
            </Button>
            <span style={{ marginLeft: "20px" }}></span>
          </Button.Group> */}
          {UserDetails?.is_suspended == 0 && (
            <Button.Group style={{ marginTop: 20 }}>
              <Button
                negative
                onClick={() => {
                  setOpen(true);
                  setAction({
                    id: UserDetails.id,
                    fundraiser_id: UserDetails.fundraiser_id,
                    is_suspended: UserDetails.is_suspended == 1 ? 0 : 1,
                    remarks:
                      UserDetails.is_suspended == 1 ? "Publish" : "Suspend",
                  });
                }}
              >
                {UserDetails.is_suspended == 1 ? "Publish" : "Suspend"}
              </Button>
              <span style={{ marginLeft: "20px" }}></span>
            </Button.Group>
          )}
        </li>
      </ul>
    </div>
  );

  return (
    <div style={{ marginLeft: "30px" }}>
      {/* <Confirm
        content={`Are you sure? You want to ${action.remarks} this Campaign!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          // updateUser(action);
        }}
      /> */}
      <Modal
        onClose={() => setOpenMain(false)}
        onOpen={() => setOpenMain(true)}
        open={openMain}
        trigger={<Button>View</Button>}
        header="Campaign Detail "
        content={modalElements}
        actions={[{ key: "done", content: "Done", primary: true }]}
      />
    </div>
  );
}
