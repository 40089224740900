import React, { useState, useEffect } from "react";
import { Button, Modal, Confirm } from "semantic-ui-react";
// import { KycActions } from "../../../../redux/";
import { useDispatch } from "react-redux";
import moment from "moment";
import { apiCallGet, apiCallPost } from "../../../components/Axios/Axios";
import GlobalVariables from "../../../_constants/GlobalVariables";
import { calculateBal } from "../../../Assets/data/utils";

export default function ViewWithdraw({ UserWithdraw, myKycActions }) {
  const [open, setOpen] = useState(false);
  const [openMain, setOpenMain] = useState(false);
  const [action, setAction] = useState({});
  //   const [userData, setUserData] = useState({});

  //const dispatch = useDispatch();

  function updateUser(newData) {
    setOpen(false);
    console.log("TAG", action);

    apiCallPost(`/api/v1/admin/campaign/update`, newData, {}, "toastOn")
      .then(async (res) => {
        if (res) {
          if (!res.error) {
            setOpenMain(false);
            myKycActions({ page: 0, limit: GlobalVariables.PAGE_LIMIT });
          }
        }
      })
      .catch((err) => {});
  }

  //   function getUser() {
  //     apiCallGet(
  //       `api/v1/admin/user/findUserById/${UserDetails.id}`,

  //       {},
  //       "toastOn"
  //     )
  //       .then(async (res) => {
  //         if (res) {
  //           if (!res.error) {
  //             setUserData(res.data);
  //           }
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  useEffect(() => {
    console.log("TAGrr", openMain);
    // getUser()
  }, [openMain]);

  //console.log("data", getUser())

  const modalElements = (
    <div className="user_details">
      <ul>
        <li style={{ color: "white" }}>
          <div>
            <b>txid :</b>{" "}
          </div>
          {UserWithdraw.tx_id ? UserWithdraw.tx_id : "N/A"}
          {/* {UserDetails.last_name ? UserDetails.last_name : "N/A"} */}
        </li>
        <li style={{ color: "white" }}>
          <div>
            {" "}
            <b>From :</b>{" "}
          </div>
          {UserWithdraw.from_address ? UserWithdraw.from_address : "N/A"}
          {/* {moment(UserWithdraw?.createdAt).format("MMMM Do YYYY") || "N/A"} */}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>To :</b>
          </div>{" "}
          {UserWithdraw?.to_address ? UserWithdraw?.to_address : "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Amount :</b>
          </div>{" "}
          {UserWithdraw?.amount ? calculateBal(UserWithdraw.amount): "N/A"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Status :</b>
          </div>{" "}
          {UserWithdraw.status == 0
            ? "PENDING"
            : UserWithdraw.status == 2
            ? "CONFIRMED"
            : UserWithdraw.status == 1
            ? "UNCONFIRMED"
            : "PROCESSING"}
        </li>
        <li style={{ color: "white" }}>
          <div>
            <b>Created At:</b>
          </div>{" "}
          {moment(UserWithdraw?.created_at).format("MMMM Do YYYY") || "N/A"}
        </li>
        <li>
          {/* <Button.Group style={{ marginTop: 20 }}>
            <Button
              positive
              onClick={() => {
                setOpen(true);
                setAction({
                  id: UserWithdraw.id,
                  fundraiser_id: UserWithdraw.fundraiser_id,
                  is_verified: UserWithdraw.is_verified == 1 ? 0 : 1,
                  remarks:
                    UserWithdraw.is_verified == 1 ? "Inactive" : "Active",
                });
              }}
            >
              {UserWithdraw.is_verified == 1 ? "Inactive" : "Active"}
            </Button>
            <span style={{ marginLeft: "20px" }}></span>
          </Button.Group> */}
          {/* {UserWithdraw?.is_deleted == 0 && (
            <Button.Group style={{ marginTop: 20 }}>
              <Button
                negative
                onClick={() => {
                  setOpen(true);
                  setAction({
                    id: UserWithdraw.id,
                    fundraiser_id: UserWithdraw.fundraiser_id,
                    is_deleted: UserWithdraw.is_deleted == 1 ? 0 : 1,
                    remarks:
                      UserWithdraw.is_deleted == 1 ? "Publish" : "Suspend",
                  });
                }}
              >
                {UserWithdraw.is_deleted == 1 ? "Publish" : "Suspend"}
              </Button>
              <span style={{ marginLeft: "20px" }}></span>
            </Button.Group>
          )} */}
        </li>
      </ul>
    </div>
  );

  return (
    <div style={{ marginLeft: "30px" }}>
      <Confirm
        content={`Are you sure? You want to ${action.remarks} this Campaign!`}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          updateUser(action);
        }}
      />
      <Modal
        onClose={() => setOpenMain(false)}
        onOpen={() => setOpenMain(true)}
        open={openMain}
        trigger={<Button>View</Button>}
        header="Withdraw Detail "
        content={modalElements}
        actions={[{ key: "done", content: "Done", primary: true }]}
      />
    </div>
  );
}
