import React from "react";
import { Col, DatePicker, Select } from "antd";

const ExploreFilters = ({
  setSelectedSort,
  setSelectedType,
  noPicker,
  noTypeFilter,
  setYearFilter,
}) => {
  const { Option } = Select;

  const onChange = (value) => {
    setSelectedSort(value);
    console.log(`selected ${value}`);
  };
  const onChangeType = (value) => {
    setSelectedType(value);
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Col className="sortingBar ExploreFilters">
        <Select
          className="customSelect"
          // showSearch
          style={{ width: 236 }}
          placeholder="Most Recent"
          optionFilterProp="children"
          onChange={onChange}
          // onSearch={onSearch}
          filterOption={(input, option) => 
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="recent">Most Recent</Option>
          <Option value="highttolow">Price: High to Low</Option>
          <Option value="lowtohigh">Price: Low to High</Option>
        </Select>

        {noPicker && (
          <DatePicker
            picker="year"
            placeholder="All Years"
            bordered={false}
            onChange={(e, date) => setYearFilter(date)}
          />
        )}
        {noTypeFilter && (
          <Select
            className="customSelect"
            // showSearch
            style={{ width: 236 }}
            placeholder="Collection"
            optionFilterProp="children"
            onChange={onChangeType}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="AUCTION">Creator</Option>
            <Option value="SALE">Admin</Option>
          </Select>
        )}
      </Col>
    </>
  );
};

export default ExploreFilters;
