import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { Button, Form } from "semantic-ui-react";
import { FormField } from "../../../../components/FormField";
import { apiCallPost } from "../../../../components/Axios/Axios";
import MainLayout from "../../../../components/HOC/MainLayout";
import { useSelector, useDispatch, connect } from "react-redux";
import { compose } from "redux";
import { getFaqDataById } from "../../../../redux/_actions/faq.action";
import { GetFaqAllData } from "../../../../redux/_actions";
import { Row } from "antd";
import Col from "antd/es/grid/col";

const AddFaq = ({
  match: {
    params: { id },
  },
  handleSubmit,
  history,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const faq = useSelector((state) => state?.faq?.faq);
  const goBack = () => {
    history.goBack();
  };

  const submitForm = (values) => {
    // let results = {};
    if (isEdit) {
      const data = {
        id,
        question: values.question,
        answer: values.answer,
        isActive: 1,
        isDeleted: 0,
      };
      apiCallPost("/api/v1/admin/faq/update", data, {}, "toastOn")
        .then((results) => {
          if (results) {
            // toast.success(results?.data?.message);
            history.goBack();
          }
        })
        .catch((error) => {
          console.log("TAG", error);
        });
    } else {
      const data = {
        question: values.question,
        answer: values.answer,
      };
      apiCallPost("/api/v1/admin/faq/add", data, {}, "toastOn")
        .then((results) => {
          if (results) {
            // toast.success(results?.data?.message);
            history.goBack();
          }
        })
        .catch((error) => {
          console.log("TAG", error);
        });
    }
  };
  useEffect(() => {
    const { emptyRafflesFormReducer } = GetFaqAllData;
    if (id) {
      dispatch(getFaqDataById(id));
      setIsEdit(true);
    }
    return () => {
      dispatch(emptyRafflesFormReducer());
    };
  }, []);

  return (
    <MainLayout>
      <div className="sub_title mb-30">
        <h3>{isEdit ? "Update" : "Add"} Faq's</h3>
        <Button className="addButton" onClick={goBack}>
          Back
        </Button>
      </div>
      <div className="createNft_area mb-30">
        <div className="new__creater">
          <div className="create-nft-form create-nft-form-new p-0">
            <Form
              className="fv-form"
              autoComplete="off"
              autoFocus="off"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="inner_card">
                <Form.Field>
                  <label>Question</label>
                  <Field
                    component={FormField}
                    name="question"
                    type="text"
                    validate={[required()]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Answer</label>
                  <Field
                    component={FormField}
                    name="answer"
                    type="text"
                    validate={[required()]}
                  />
                </Form.Field>
              </div>
              <Row>
                <Col xs={12} md={12} xl={12}>
                  <Form.Field className="loginBtn">
                    <div className="new__creater__form__submit__data">
                      <button
                        className="ant-btn ant-btn-primary"
                      //  disabled={errors[0]}
                      >
                        {isEdit ? "Save Changes" : "Submit"}
                      </button>
                    </div>
                  </Form.Field>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state?.faq?.faq,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: "CreateFaq", enableReinitialize: true })
)(AddFaq);

// export default withRouter(
//     reduxForm({ form: "AddFaq", enableReinitialize: true })(AddFaq)
// );
