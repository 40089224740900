import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import KycService from "../../Services/kyc.service";
import Types from "./_types";

export const saveArtist = (payload) => ({
  type: Types.SAVE_ARTIST,
  payload,
});

/**
 * @function getKyc
 * @param {{ page, limit }} data
 * @returns /getArtist/limit/page
 */
export const getKyc = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await KycService.getKyc(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const changeKycStatus = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const statusData = {
      userId: data?.userId,
      remark: data?.remark ? data?.remark : "",
      kycStatus: data?.kycStatus ? data?.kycStatus : "PENDING",
    };
    const res = await KycService.changeKycStatus(statusData, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
