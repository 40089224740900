const START_LOADING = "[LOADING] START_LOADING";
const STOP_LOADING = "[LOADING] STOP_LOADING";

const SAVE_LOGIN_DETAILS = "[AUTH] SAVE_LOGIN_DETAILS";
const RESET_PERSIST_ON_LOGOUT = "[AUTH] RESET_PERSIST_ON_LOGOUT";
const SAVE_COLLECTION = "[COLLECTION] SAVE_COLLECTION";
const SAVE_BANNER_DETAILS = "[BANNER] SAVE_BANNER_DETAILS";
const SAVE_FAQ_DETAILS = "[FAQ] SAVE_FAQ_DETAILS";
const SAVE_BRAND = "[BRAND] SAVE_BRAND";
const SAVE_ARTIST = "[ARTIST] SAVE_ARTIST";
const SAVE_NFT_DETAILS = "[NFT] SAVE_NFT_DETAILS";
const SAVE_CATEGORY_DETAILS = "[CATEGORY] SAVE_CATEGORY_DETAILS";
const SAVE_ADDTYPE_DETAILS = "[ADDTYPE] SAVE_ADDTYPE_DETAILS";
const SAVE_TAX_DETAILS = "[TAX] SAVE_TAX_DETAILS";
const SAVE_MEDIA = "[MEDIA] SAVE_MEDIA";
const SAVE_QUOTES = "[QUOTES] SAVE_QUOTES";

const Types = {
  START_LOADING,
  STOP_LOADING,
  SAVE_LOGIN_DETAILS,
  RESET_PERSIST_ON_LOGOUT,
  SAVE_COLLECTION,
  SAVE_BANNER_DETAILS,
  SAVE_TAX_DETAILS,
  SAVE_FAQ_DETAILS,
  SAVE_BRAND,
  SAVE_ARTIST,
  SAVE_NFT_DETAILS,
  SAVE_CATEGORY_DETAILS,
  SAVE_ADDTYPE_DETAILS,
  SAVE_MEDIA,
  SAVE_QUOTES,
};

export default Types;
